<div class="bouwapp-header">
  <div class="bouwapp-header__text">
    <h1>{{ 'projects.detail.menu.phases' | translate }}</h1>
  </div>
  <div class="bouwapp-header__buttons">
    <a
      [routerLink]="['..', 'events', 'new']"
      type="button"
      class="secondary"
      appAccess
      role="manager"
    >
      <span class="icon" [inlineSVG]="'/assets/icons/add--white.svg'"></span>
      <span>{{ 'project.detail.events.new' | translate }}</span>
    </a>
    <a [routerLink]="['new']" class="primary" appAccess role="manager">
      <span class="icon" [inlineSVG]="'/assets/icons/add--white.svg'"></span>
      <span>{{ 'project.detail.phases.new' | translate }}</span>
    </a>
  </div>
</div>
<div class="bouwapp-phases" *ngIf="planningItems">
  <div class="bouwapp-phases__start">
    <span inlineSVG="/assets/img/icons/rocket.svg"></span>
    <span
      >{{
        (projectStarted
          ? 'project.detail.phases.started'
          : 'project.detail.phases.starts'
        ) | translate
      }}
      {{ project.startDate | dateType: project.startType : 'long' }}</span
    >
    <a [routerLink]="['..', 'general']" fragment="planning">{{
      'project.detail.phases.list.edit' | translate
    }}</a>
  </div>

  <div
    class="bouwapp-phases__item past"
    *ngIf="!showPastPhases && showPastButton && planningItems.length > 0"
  >
    <button (click)="showPastPhases = true">
      <span inlineSVG="/assets/img/icons/arrow-up.svg"></span>
      <span>{{ 'project.detail.phases.past' | translate }}</span>
    </button>
  </div>

  <div class="bouwapp-phases__item empty" *ngIf="!planningItems.length">
    <p>{{ 'project.detail.phases.list.empty' | translate }}</p>
  </div>

  <div
    *ngFor="let item of planningItems"
    [hidden]="!showPastPhases && isPast(item)"
    class="bouwapp-phases__item"
    [ngClass]="
      item.type == 'phase' && {
        first: (item['index'] + 1) % 3 == 1,
        second: (item['index'] + 1) % 3 == 2,
        third: (item['index'] + 1) % 3 == 0,
      }
    "
  >
    <ng-container *ngIf="item.type === 'phase'; else event">
      <ng-template
        [ngTemplateOutlet]="phaseTemplate"
        [ngTemplateOutletContext]="{ $implicit: item, phase: item }"
      ></ng-template>
    </ng-container>
    <ng-template #event>
      <ng-template
        [ngTemplateOutlet]="eventTemplate"
        [ngTemplateOutletContext]="{ $implicit: item, event: item }"
      ></ng-template>
    </ng-template>
  </div>

  <div class="bouwapp-phases__end" *ngIf="project.endDate">
    <span inlineSVG="/assets/img/icons/flag-planning.svg"></span>
    <span
      >{{
        (projectEnded
          ? 'project.detail.phases.ended'
          : 'project.detail.phases.ends'
        ) | translate
      }}
      {{ project.endDate | dateType: project.endType : 'long' }}</span
    >
    <a [routerLink]="['..', 'general']" fragment="planning">{{
      'project.detail.phases.list.edit' | translate
    }}</a>
  </div>
</div>

<ng-template #phaseTemplate let-phase>
  <div class="phase">
    <div class="left">
      <span *ngIf="isCurrent(phase)" class="current">
        <span inlineSVG="/assets/img/icons/flag-planning.svg"></span>
        <span>{{ 'project.detail.phases.current' | translate }}</span>
      </span>
      <h3>
        {{ phase.name }}
      </h3>
      <span>
        {{ phase.startDate | dateType: phase.startType : 'long' }} -
        {{ phase.endDate | dateType: phase.endType : 'long' }}
      </span>
      <div [innerHTML]="phase.text"></div>
    </div>
    <div class="right">
      <a (click)="confirmDeletePhase.confirmDelete(phase)">{{
        'project.detail.phases.list.delete' | translate
      }}</a>
      <a [routerLink]="[phase.id, 'edit']">{{
        'project.detail.phases.list.edit' | translate
      }}</a>
    </div>
  </div>
  <div class="events">
    <ng-template
      *ngFor="let event of phase.events"
      [ngTemplateOutlet]="eventTemplate"
      [ngTemplateOutletContext]="{ $implicit: event, event: event }"
    ></ng-template>
  </div>
</ng-template>

<ng-template #eventTemplate let-event>
  <div class="event">
    <div class="event__content">
      <div class="left">
        <h3>{{ event.title }}</h3>
        <span>
          {{
            (hasStarted(event)
              ? 'project.detail.events.started'
              : 'project.detail.events.starts'
            ) | translate
          }}
          {{ event.startDate | dateType: 'EVENT' }}
        </span>
        <span *ngIf="event.endDate">
          {{
            (hasEnded(event)
              ? 'project.detail.events.ended'
              : 'project.detail.events.ends'
            ) | translate
          }}
          {{ event.endDate | dateType: 'EVENT' }}
        </span>
        <div>
          <span *ngIf="isUpcomingEvent(event)">
            {{ 'project.detail.events.starts_in' | translate }}
            {{ event.startDate | countdown }}
          </span>
          <ng-container *ngIf="isCurrentEvent(event)">
            <span *ngIf="getEndDateDiff(event) < 1">
              {{ 'project.detail.events.less_than_hour' | translate }}
            </span>
            <span *ngIf="getEndDateDiff(event) >= 1">
              {{ event.endDate | countdown }}
              {{ 'project.detail.events.remaining' | translate }}
            </span>
          </ng-container>
        </div>
      </div>
      <div class="right">
        <a (click)="confirmDeleteEvent.confirmDelete(event)">{{
          'project.detail.phases.list.delete' | translate
        }}</a>
        <a [routerLink]="['..', 'events', event.id, 'edit']">{{
          'project.detail.phases.list.edit' | translate
        }}</a>
        <span *ngIf="isUpcomingEvent(event)" class="upcoming">
          {{ 'project.detail.events.upcoming' | translate }}
        </span>
        <span *ngIf="isCurrentEvent(event)" class="current">
          {{
            (getEndDateDiff(event) < 3
              ? 'project.detail.events.ends_soon'
              : 'project.detail.events.happening_now'
            ) | translate
          }}
        </span>
      </div>
    </div>
  </div>
</ng-template>

<app-confirm-delete
  #confirmDeletePhase
  (confirmed)="deletePhase($event)"
></app-confirm-delete>
<app-confirm-delete
  #confirmDeleteEvent
  (confirmed)="deleteEvent($event)"
></app-confirm-delete>
