@if (show) {
  <section class="sticky-submit">
    <article class="sticky-submit__wrapper">
      <span *ngIf="showDefaultText()">
        <h3>
          {{ title() | translate }}
        </h3>
        <div>
          {{ 'sticky-submit.description' | translate }}
        </div>
      </span>
      <div class="sticky-submit__buttons">
        <ng-content></ng-content>
      </div>
    </article>
  </section>
}
