import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { CustomerPickerComponent } from 'src/app/components/customer-picker.component';
import { DataFilterComponent } from 'src/app/components/data-filter.component';
import { LoaderComponent } from 'src/app/components/loader.component';
import { CustomFilterType } from 'src/app/enums/custom-filter-type';
import { ProjectStatus } from 'src/app/enums/project-status';
import { CustomFilter } from 'src/app/interfaces/custom-filter';
import { CustomFilterItem } from 'src/app/interfaces/custom-filter-item';
import { Customer } from 'src/app/interfaces/customer';
import { LogEntry } from 'src/app/interfaces/log-entry';
import { Project } from 'src/app/interfaces/project';
import { ProjectUser } from 'src/app/interfaces/project-user';
import { Sort } from 'src/app/interfaces/sort';
import { CustomerService } from 'src/app/services/customer.service';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { ProjectUserService } from 'src/app/services/project-user.service';

@Component({
  selector: 'app-default-organisation-log',
  templateUrl: 'log.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    LoaderComponent,
    InfiniteScrollDirective,
    DataFilterComponent,
    CommonModule,
    CustomerPickerComponent,
  ],
})
export class LogComponent {
  loading: boolean = false;
  logEntries: LogEntry[] = [];
  totalLogEntries: number = 80;

  public sortItems: Sort[] = [
    {
      field: 'createdAt',
      direction: 'desc',
      label: 'organisation.log.sort.date.descending',
    },
    {
      field: 'createdAt',
      direction: 'asc',
      label: 'organisation.log.sort.date.ascending',
    },
  ];
  page: number = 1;
  perPage: number = 25;
  private formResult: any;
  protected projects: Project[];
  protected projectIds: number[];
  protected users: ProjectUser[];
  protected userIds: number[];
  protected categories: any[];
  public customer: Customer;
  public ready: boolean = false;
  public customFilters: CustomFilter[];

  constructor(
    private projectDataBusService: ProjectDataBusService,
    private customerService: CustomerService,
    private projectUserService: ProjectUserService,
  ) {
    this.projectDataBusService.shortListObservable.subscribe((shortList) => {
      this.projects = shortList?.filter(
        (project) => project.status === ProjectStatus.PUBLISHED,
      );
      this.customFilters = this.transformToFilters();
    });
  }

  public async loadCustomer(id: number): Promise<void> {
    this.ready = false;

    this.customer = await this.customerService.fetch(id);
    this.categories = (
      (await this.customerService.getLogCategories(this.customer)) as any
    ).entityTypes;

    this.users = await this.projectUserService.fetchListForCustomer(
      this.customer,
    );
    this.customFilters = this.transformToFilters();
    this.ready = true;
    this.updateFilter({});
  }

  public async loadLogEntries(): Promise<void> {
    this.loading = true;
    const response = await this.customerService.getLog(
      this.customer,
      this.formResult,
      this.page,
      this.perPage,
      this.userIds,
      this.projectIds,
    );

    if (this.page > 1) {
      this.logEntries = this.logEntries.concat(response['hydra:member']);
    } else {
      this.logEntries = response['hydra:member'];
    }
    this.totalLogEntries = response['hydra:totalItems'];
    this.page++;
    this.loading = false;
  }

  updateFilter(data: any): void {
    this.page = 1;
    const formResult: any = {};
    formResult.sort = data.sort ?? this.sortItems[0];

    const dateFilters = data.customFilters?.find(
      (f) =>
        f.title === 'organisation.log.filter.date' &&
        f.type === CustomFilterType.DATE,
    );

    formResult.startDate =
      dateFilters?.filters.find((f) => f.key === 'startDate')?.value || '';
    formResult.endDate =
      dateFilters?.filters.find((f) => f.key === 'endDate')?.value || '';

    formResult.categories =
      data.customFilters
        ?.find(
          (f) =>
            f.title === 'organisation.log.filter.categories' &&
            f.type === CustomFilterType.CHECKBOX,
        )
        ?.filters.filter((filter) => filter.value)
        .map((filter) => filter.key) || [];

    this.userIds = data.customFilters
      ?.find(
        (f) =>
          f.title === 'organisation.log.filter.users' &&
          f.type === CustomFilterType.SELECT,
      )
      ?.filters.filter((filter) => filter.value)
      .map((filter) => filter.value || [])
      .flat();

    this.projectIds = data.customFilters
      ?.find(
        (f) =>
          f.title === 'organisation.log.filter.projects' &&
          f.type === CustomFilterType.CHECKBOX,
      )
      ?.filters.filter((filter) => filter.value)
      .map((filter) => parseInt(filter.key) || []);

    this.formResult = formResult;
    this.loadLogEntries();
  }

  transformToFilters(): CustomFilter[] {
    const filters: CustomFilter[] = [];

    if (this.users) {
      filters.push({
        title: 'organisation.log.filter.users',
        type: CustomFilterType.SELECT,
        filters: [
          {
            label: 'organisation.log.filter.users.placeholder',
            value: false,
            key: '',
            data: this.users.map((user) => ({
              value: user.id,
              label: `${user.firstName} ${user.lastName}`,
            })),
          },
        ],
      });
    }

    filters.push({
      title: 'organisation.log.filter.date',
      type: CustomFilterType.DATE,
      filters: [
        {
          label: 'organisation.log.filter.date.start',
          value: '',
          key: 'startDate',
        },
        {
          label: 'organisation.log.filter.date.end',
          value: '',
          key: 'endDate',
        },
      ],
    });

    if (this.categories) {
      const categoryFilters: CustomFilterItem[] = Object.entries(
        this.categories,
      ).map(([key, value]) => ({
        label: key,
        value: false,
        key: value.type,
      }));

      filters.push({
        title: 'organisation.log.filter.categories',
        type: CustomFilterType.CHECKBOX,
        filters: categoryFilters,
      });
    }

    if (this.projects) {
      const projectFilters: CustomFilterItem[] = this.projects.map(
        (project) => ({
          label: project.name,
          value: false,
          key: project.id.toString(),
        }),
      );

      filters.push({
        title: 'organisation.log.filter.projects',
        type: CustomFilterType.CHECKBOX,
        filters: projectFilters,
      });
    }

    return filters;
  }

  public changeCustomer(customer: Customer) {
    this.customer = customer;
    this.loadCustomer(customer.id);
  }
}
