<form [formGroup]="form" class="form" (ngSubmit)="savePreferedMethod()">
  <app-form-group [form]="form" errorPath="method">
    <label for="method">{{
      'two.factor.method.placeholder' | translate
    }}</label>
    <select id="method" formControlName="method">
      @if (standalone() === true) {
        <option [ngValue]="null">
          {{ 'two.factor.method.select.placeholder' | translate }}
        </option>
      }
      @for (item of methods; track item.value) {
        @if (item.value === null && standalone() === true) {
        } @else {
          <option [ngValue]="item.value">
            {{ 'two.factor.method.' + item.name | translate }}
          </option>
        }
      }
    </select>
  </app-form-group>
  @if (standalone()) {
    <button
      class="button button--m button--flat bg--orange"
      [appLoading]="loading"
    >
      {{ 'two.factor.method.save' | translate }}
    </button>
  }
</form>
