<div [formGroup]="generalForm">
  <div class="bouwapp-group__row two">
    <div class="bouwapp-group__item cover">
      <app-form-group [form]="generalForm" errorPath="coverImage">
        <label>{{ 'projects.detail.general.cover.title' | translate }}</label>
        <p>
          {{ 'projects.detail.general.cover.format.info' | translate }}
        </p>
        <div
          class="project-coverImage"
          [class.hasImage]="getCurrentCoverImage() ? true : false"
          [style.backgroundImage]="getCurrentCoverImage()"
        >
          <div class="project-coverImage__button">
            <app-input-file
              #coverInput
              [withEditor]="true"
              formControlName="coverImage"
              (previewUpdated)="updateCoverImageThumbnail($event)"
              [withDragDrop]="false"
              type="project-cover-image"
              [uploadImages]="true"
              (startEditing)="startEditingCoverImage($event)"
              (stopEditing)="stopEditingCoverImage()"
              (startUploading)="inputFileStartUploading()"
              (stopUploading)="inputFileStopUploading($event)"
            >
              <div *ngIf="!getCurrentCoverImage()">
                <span inlineSVG="/assets/img/icons/add.svg"></span>
                {{ 'projects.detail.update.media.media.add' | translate }}
              </div>
              <div *ngIf="getCurrentCoverImage()">
                <span inlineSVG="/assets/img/icons/edit.svg"></span>
                {{ 'projects.detail.update.media.media.edit' | translate }}
              </div>
            </app-input-file>
          </div>
        </div>
      </app-form-group>
    </div>
    <div class="bouwapp-group__item logo">
      <app-form-group [form]="generalForm" errorPath="logo">
        <label>{{ 'projects.detail.general.logo.title' | translate }}</label>
        <p>{{ 'projects.detail.general.logo.format.info' | translate }}</p>
        <div
          class="project-logo"
          [class.hasImage]="getCurrentLogo() ? true : false"
          [style.backgroundImage]="getCurrentLogo()"
        >
          <app-input-file
            #logoInput
            [withEditor]="true"
            formControlName="logo"
            (previewUpdated)="updateLogoThumbnail($event)"
            [withDragDrop]="false"
            type="project-logo"
            [uploadImages]="true"
            (startEditing)="startEditingLogo($event)"
            (stopEditing)="stopEditingLogo()"
            (startUploading)="inputFileStartUploading()"
            (stopUploading)="inputFileStopUploading($event)"
          >
            <div>
              <span
                *ngIf="!getCurrentLogo()"
                inlineSVG="/assets/img/icons/add.svg"
              ></span>
              <span
                *ngIf="getCurrentLogo()"
                inlineSVG="/assets/img/icons/edit.svg"
              ></span>
            </div>
          </app-input-file>
        </div>
      </app-form-group>
    </div>
  </div>
  <div class="bouwapp-group__row two">
    <div class="bouwapp-group__item" *ngIf="!isConcept">
      <app-form-group [form]="generalForm">
        <label for="status">
          {{ 'projects.detail.general.project.status.title' | translate }}
          <span class="status" [ngClass]="[getCurrentStatusClass()]"></span>
        </label>
        <select
          id="status"
          [attr.title]="'projects.detail.general.status.title' | translate"
          class="select select--m status"
          formControlName="status"
          appAccess
          role="manager"
          [project]="project"
          property="disabled"
        >
          <option
            *ngFor="let status of getProjectStatusValues()"
            [value]="status"
          >
            {{
              'projects.detail.general.status.' + status.toLowerCase()
                | translate
            }}
          </option>
        </select>
      </app-form-group>
      <span
        *ngIf="generalForm.get('status').value === ProjectStatus.PRIVATE"
        style="display: block; font-size: 1.4rem; margin-top: 1.2rem"
        >{{ 'projects.detail.general.status.private.info' | translate }}</span
      >
    </div>
    <div class="bouwapp-group__item">
      <app-form-group [form]="generalForm" errorPath="language">
        <label for="language">
          {{ 'projects.detail.general.project.language.title' | translate }}
        </label>

        <select
          id="language"
          [attr.title]="
            'projects.detail.general.project.language.title' | translate
          "
          class="select select--m"
          formControlName="language"
          appAccess
          role="manager"
          [project]="project"
          property="disabled"
        >
          <option [value]="null" disabled selected hidden>
            {{
              'projects.detail.general.project.language.placeholder' | translate
            }}
          </option>
          <option *ngFor="let lang of languages" [value]="lang.value">
            {{
              'projects.detail.general.project.language.' + lang.name
                | translate
            }}
          </option>
        </select>
      </app-form-group>
    </div>
  </div>
  <div class="bouwapp-group__row two">
    <div class="bouwapp-group__item">
      <app-form-group [form]="generalForm" errorPath="name">
        <label for="name">{{
          'projects.detail.general.project.name.title' | translate
        }}</label>
        <input
          id="name"
          type="text"
          [placeholder]="
            'projects.detail.general.title.placeholder' | translate
          "
          formControlName="name"
          maxLength="50"
          appAccess
          role="manager"
          [project]="project"
          property="disabled"
        />
      </app-form-group>
    </div>
    <div class="bouwapp-group__item">
      <app-form-group [form]="generalForm" errorPath="participants">
        <label for="participants">{{
          'projects.detail.general.project.owner.title' | translate
        }}</label>
        <input
          id="participants"
          type="text"
          formControlName="participants"
          maxLength="75"
          appAccess
          role="manager"
          [project]="project"
          property="disabled"
        />
      </app-form-group>
    </div>
  </div>

  <div
    class="bouwapp-group__row two"
    [ngClass]="{ 'standalone-planning': !isConcept }"
    id="planning"
    *ngIf="!isConcept"
  >
    <app-detail-general-planning
      [dateRangeValid]="dateRangeValid()"
      [generalForm]="generalForm"
      [withoutText]="true"
    ></app-detail-general-planning>
  </div>

  <div class="bouwapp-group__row">
    <div class="bouwapp-group__item">
      <app-form-group [form]="generalForm" errorPath="description">
        <label>{{
          'projects.detail.general.project.description.title' | translate
        }}</label>
        <app-quill
          [formGroup]="generalForm"
          controlName="description"
          [project]="!canEdit ? undefined : project"
          [language]="project.language ?? platform.language"
          [readOnly]="!canEdit"
          placeholder="{{
            'projects.detail.general.description.placeholder' | translate
          }}"
        ></app-quill>
      </app-form-group>
    </div>
  </div>
</div>

<app-image-cropbox
  *ngIf="showCoverImageEditor"
  [imageFormElement]="generalForm.get('coverInput')"
  [imageInput]="coverInput"
  [imageChangedEvent]="imageChangedEvent"
  [aspectRatio]="8 / 3"
  [startUploadingEvent]="startUploading"
  [stopUploadingEvent]="stopUploading"
  (editorClosed)="stopEditingCoverImage()"
></app-image-cropbox>

<app-image-cropbox
  *ngIf="showLogoEditor"
  [imageFormElement]="generalForm.get('logo')"
  [imageInput]="logoInput"
  [imageChangedEvent]="imageChangedEvent"
  [aspectRatio]="1"
  [startUploadingEvent]="startUploading"
  [stopUploadingEvent]="stopUploading"
  (editorClosed)="stopEditingLogo()"
></app-image-cropbox>
