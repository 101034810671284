<div [ngClass]="{ container: getIsCustomer() }">
  <div>
    <div class="bouwapp-header">
      <div class="bouwapp-header__text">
        <h1>{{ 'projects.detail.update.list.title' | translate }}</h1>
      </div>
      <div class="bouwapp-header__buttons">
        <a
          (click)="newUpdate()"
          class="secondary"
          appAccess
          role="manager"
          [project]="project"
        >
          <span
            class="icon"
            [inlineSVG]="'/assets/icons/add--white.svg'"
          ></span>
          <span>{{ 'projects.detail.update.list.create' | translate }}</span></a
        >
      </div>
    </div>

    <app-data-filter
      (filterChanged)="updateFilter($event)"
      [customFilters]="transformToFilters()"
      [searchLabel]="'projects.detail.update.list.search'"
    >
      <div
        class="updates__list"
        infiniteScroll
        [scrollWindow]="true"
        [infiniteScrollContainer]="'div.app__content'"
        [fromRoot]="true"
        (scrolled)="loadUpdates()"
      >
        <p *ngIf="updates && updates.length">
          {{
            'projects.detail.update.list.result.title'
              | translate: { updates: totalItems }
          }}
        </p>

        <app-default-updates-tile
          [isCustomer]="isCustomer"
          *ngFor="let update of updates"
          [update]="update"
          [confirmDelete]="confirmDelete"
        ></app-default-updates-tile>

        <app-loader *ngIf="loading"></app-loader>

        <div class="no-results" *ngIf="updates && updates.length === 0">
          {{ 'projects.detail.update.list.empty' | translate }}
        </div>
      </div>
    </app-data-filter>
  </div>
</div>

<app-confirm-delete
  #confirmDelete
  (confirmed)="deleteConfirmed($event)"
></app-confirm-delete>
<app-update-picker
  [projects]="projects"
  [customers]="customers"
></app-update-picker>
