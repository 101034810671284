<div
  [hidden]="loading() || !projectUsers()?.length"
  class="projects-settings__users__wrapper__content"
>
  <ul class="head">
    <li>
      <span>
        {{ 'project.detail.settings.users.table.name' | translate }}
      </span>
      <span>
        @if (project()) {
          {{ 'project.list.filter.role.title' | translate }}
        } @else {
          {{ 'project.detail.settings.users.table.role' | translate }}
        }
      </span>
      <span>
        {{ 'project.detail.settings.users.table.last_event' | translate }}
      </span>
      <span></span>
    </li>
  </ul>

  <ul class="body">
    <li *ngFor="let user of projectUsers()">
      <span
        class="user-profile"
        [attr.aria-label]="
          'project.detail.settings.users.table.name' | translate
        "
      >
        <span>
          <app-avatar [projectUser]="user"></app-avatar>
        </span>
        <span>
          <span>
            {{ user.firstName }}
            {{ user.lastName }}
          </span>
          <span>
            {{ user.user.email }}
          </span>
          <span *ngIf="user.phoneNumber">+{{ user.phoneNumber }}</span>
        </span>
      </span>
      <span
        class="user-role"
        [attr.aria-label]="
          'project.detail.settings.users.table.role' | translate
        "
      >
        <ng-container
          *ngFor="let permission of user.sortedPermissions | keyvalue"
        >
          <span
            *ngIf="permission.value.length"
            [hidden]="
              user.sortedPermissions.account_admin.length &&
              permission.key != 'account_admin'
            "
            [class]="permission.key"
          >
            <span>
              <span
                [inlineSVG]="'/assets/img/icons/' + permission.key + '.svg'"
              ></span>
              <span>
                {{
                  'project.detail.settings.users.table.type.' + permission.key
                    | translate
                }}
              </span>
            </span>
            <span class="number" *ngIf="editAdmins()"
              >{{
                permission.key === 'account_admin'
                  ? ('project.detail.settings.users.table.type.account_admin.all'
                    | translate)
                  : getNumberOfProjects(permission.key, permission.value)
              }}
              {{
                permission.key === 'account_admin' ||
                getNumberOfProjects(permission.key, permission.value) != 1
                  ? ('project.detail.settings.users.table.project.plural'
                    | translate)
                  : ('project.detail.settings.users.table.project' | translate)
              }}</span
            >
          </span>
        </ng-container>
      </span>
      <span
        class="last-event"
        [attr.data-th]="
          'project.detail.settings.users.table.last_event' | translate
        "
      >
        <span *ngIf="user.lastLogEntry">
          {{ user.lastLogEntry.executedAt | date: 'mediumDate' }}
          <span>{{ user.lastLogEntry.executedAt | date: 'shortTime' }}</span>
        </span>
        <span *ngIf="user.lastLogEntry">
          {{ user.lastLogEntry.entityType | translate }}
          {{ user.lastLogEntry.action | translate }}
        </span>
        <span *ngIf="!user.lastLogEntry">-</span>
      </span>
      <span class="actions" *ngIf="projectUser">
        <ng-container *ngIf="user.id !== projectUser.id && hasPermission(user)">
          <a (click)="triggerDelete(user)" class="tile-action tile-delete">
            {{
              'project.detail.settings.users.table.actions.delete' | translate
            }}
          </a>
          <a (click)="triggerEdit(user)" class="tile-action tile-edit">
            {{ 'project.detail.settings.users.table.actions.edit' | translate }}
          </a>
        </ng-container>
      </span>
    </li>
  </ul>
</div>

<div class="list" [hidden]="!isEmpty() || loading()">
  <div class="no-results">
    {{ 'project.detail.settings.users.table.empty' | translate }}
  </div>
</div>
