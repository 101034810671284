<footer class="footer">
  <div>
    <p>
      <span>{{ 'footer.company.name' | translate }}</span> •
      <span>{{ 'footer.company.street' | translate }}</span> •
      <span>{{ 'footer.company.zip_code' | translate }}</span> •
      <span>{{ 'footer.company.city' | translate }}</span>
    </p>
    <p>
      <span>{{ 'footer.company.chamber_of_commerce' | translate }}</span> •
      <span>{{ 'footer.company.vat_number' | translate }}</span> •
      <span>{{ 'footer.company.bank_account' | translate }}</span> •
      <span>
        <a
          target="_blank"
          href="{{ 'footer.privacy_statement.url' | translate }}"
          >{{ 'footer.privacy_statement.desc' | translate }}</a
        ></span
      >
    </p>
  </div>
</footer>
