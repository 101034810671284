<div [formGroup]="generalForm()" class="form-group">
  @if (!withoutText) {
    <p>
      {{ 'projects.detail.general.planning.subtitle' | translate }}
    </p>
  }
  <div class="form-split">
    <div>
      <div class="form-date">
        <p>{{ 'projects.detail.general.planning.start' | translate }}</p>
        <app-form-group [form]="generalForm()" errorPath="startDate">
          <app-date-picker-v2
            formControlName="startDate"
            [typeControl]="generalForm().get('startType')"
          >
          </app-date-picker-v2>
        </app-form-group>
      </div>
    </div>
    <div>
      <div class="form-date">
        <p>{{ 'projects.detail.general.planning.end' | translate }}</p>
        <app-form-group [form]="generalForm()" errorPath="endDate">
          <app-date-picker-v2
            formControlName="endDate"
            [typeControl]="generalForm().get('endType')"
          >
          </app-date-picker-v2>
        </app-form-group>
      </div>
    </div>
  </div>

  <div *ngIf="!dateRangeValid()" class="form-date__notice">
    {{ 'projects.detail.announcement.date.range.invalid' | translate }}
  </div>
</div>
