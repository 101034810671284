<app-modal
  [title]="'project.picker.switch-project' | translate"
  class="project-picker"
  [svg]="'/assets/img/icons/chevron-right-left.svg'"
>
  <div slot="header" *ngIf="project">
    {{ 'project.picker.current' | translate }}
    <span class="project-name">{{ project.name }}</span>
  </div>

  <div>
    <div class="bouwapp-toolbar">
      <input
        type="search"
        id="modal-search"
        [(ngModel)]="searchTerm"
        class="search ng-pristine ng-valid ng-touched"
        ng-reflect-name="search"
        [placeholder]="'project.picker.search' | translate"
      />
    </div>
    <ul class="project__list">
      <li *ngFor="let project of filteredItems()" class="project__item">
        <div class="project__item__header">
          <p>{{ project.name }}</p>
          <p>
            {{ project.customer.name }} · {{ project.amountOfFollowers }}
            {{ 'project.picker.followers' | translate }}
          </p>
        </div>
        <div class="project__item__button">
          <button
            class="button button--m button--outlined bg--orange--hover ng-star-inserted"
          >
            <span (click)="openProject(project.slug)">{{
              'project.picker.open' | translate
            }}</span>
            <span
              class="icon"
              [inlineSVG]="'/assets/img/icons/arrow-right.svg'"
            ></span>
          </button>
        </div>
      </li>
    </ul>
  </div>
</app-modal>
