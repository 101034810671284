import { Component, ElementRef, viewChild, input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { QRCodeType } from 'src/app/enums/qr-code-type';
import { ModalComponent } from '../modal.component';

@Component({
  selector: 'app-qr-modal',
  templateUrl: './qr-modal.component.html',
  standalone: true,
  imports: [QRCodeModule, TranslateModule, ModalComponent],
})
export class QrModalComponent {
  readonly link = input<string>('');
  readonly title = input<string>('');

  readonly customLink = viewChild<ElementRef>('customLink');
  readonly modal = viewChild<ModalComponent>('modal');

  public qrCodeType: QRCodeType = QRCodeType.CANVAS;
  public qrCodeDownloadLink: SafeUrl = '';
  public qrText: string = '';

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  getFinalLink(): string {
    const url = new URL(this.link());
    url.searchParams.set('utm_source', 'qr');
    return url.toString();
  }

  open() {
    this.modal().open();
  }

  download(type: string) {
    this.qrCodeType = type === 'png' ? QRCodeType.IMAGE : QRCodeType.SVG;
    setTimeout(() => this.customLink().nativeElement.click(), 100);
  }

  getDownloadLink() {
    return this.qrCodeDownloadLink;
  }
}
