import { Component, input, output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { ProjectUser } from 'src/app/interfaces/project-user';
import { ProjectUserService } from 'src/app/services/project-user.service';
import { ErrorService } from '../../services/error.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormGroupComponent } from '../form-group.component';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';
import { PlatformService } from 'src/app/services/platform.service';
import { User } from 'src/app/interfaces/user';

@Component({
  selector: 'app-create-user',
  templateUrl: 'create-user.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormChangeDetectorDirective,
    FormGroupComponent,
    TranslateModule,
  ],
})
export class CreateUserComponent {
  public form: FormGroup;

  public readonly userEmail = input<string>();
  public readonly user = input<User>();
  public readonly loading = output<boolean>();
  public readonly userCreated = output<ProjectUser>();

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private projectUserService: ProjectUserService,
  ) {
    this.form = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      phoneNumber: [null, []],
    });
  }

  public async createProjectUser() {
    this.errorService.markFormGroupTouchedAndDirty(this.form);

    if (!this.form.valid) {
      return;
    }
    this.loading.emit(true);
    try {
      const data: ProjectUser = {
        user: this.user()
          ? this.user().id
          : {
              email: this.userEmail().toLowerCase(),
              platform: PlatformService.platform.id,
            },
        ...this.form.value,
      } as ProjectUser;
      const projectUser = await this.projectUserService.create(data);
      this.userCreated.emit(projectUser);
    } catch (error) {
      this.errorService.parseErrorsToForm(this.form, error.error, {
        'user.email': 'email',
      });
      console.error(error);
    } finally {
      this.loading.emit(false);
    }
  }
}
