import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Project } from 'src/app/interfaces/project';
import { TranslateModule } from '@ngx-translate/core';
import { AccessDirective } from '../../../../directives/access.directive';
import { FormGroupComponent } from '../../../../components/form-group.component';

@Component({
  selector: 'app-detail-general-wfs',
  templateUrl: './detail-general-wfs.component.html',
  styles: [],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormGroupComponent,
    AccessDirective,
    TranslateModule,
  ],
})
export class DetailGeneralWfsComponent implements OnInit {
  @Input() generalForm: FormGroup;
  @Input() project: Project;

  constructor() {}

  ngOnInit(): void {}
}
