<div class="bouwapp-header">
  <div class="bouwapp-header__text smart-reporting__themes__text">
    <div class="bouwapp-header__text__title">
      <h1>{{ 'smart_reporting.themes.title' | translate }}</h1>
      <app-customer-picker
        [withProjectPermissions]="true"
        (selectedCustomer)="changeCustomer($event)"
      ></app-customer-picker>
    </div>
    <p>{{ 'smart_reporting.themes.introduction' | translate }}</p>
  </div>
  <div class="bouwapp-header__buttons">
    <button class="primary" (click)="formModal.open()">
      <span class="icon" [inlineSVG]="'/assets/icons/add--white.svg'"></span>
      <span>{{ 'smart_reporting.themes.form.create.title' | translate }}</span>
    </button>
  </div>
</div>
<div class="smart-reporting__list">
  <ul class="head">
    <li>
      <span>
        {{ 'smart_reporting.themes.table.title' | translate }}
      </span>
      <span>
        {{ 'smart_reporting.themes.table.created_at' | translate }}
      </span>
      <span>
        {{ 'smart_reporting.themes.table.updated_at' | translate }}
      </span>
      <span>
        {{ 'smart_reporting.themes.table.response_time_days' | translate }}
      </span>
    </li>
  </ul>

  <ul class="body">
    <li *ngFor="let theme of themes">
      <span>{{ theme.title }}</span>

      <span class="datetime">
        <span class="date">{{ theme.createdAt | date : 'mediumDate' }}</span>
        <span class="time">{{ theme.createdAt | date : 'shortTime' }}</span>
      </span>
      <span
        [attr.aria-label]="
          'smart_reporting.themes.table.updated_at' | translate
        "
        class="datetime"
      >
        <span class="date">{{ theme.updatedAt | date : 'mediumDate' }}</span>
        <span class="time">{{ theme.updatedAt | date : 'shortTime' }}</span>
      </span>
      <span
        class="actions-column"
        [attr.aria-label]="
          'smart_reporting.themes.table.response_time_days' | translate
        "
      >
        <div>
          {{ theme.responseTimeDays }}
          <span class="actions">
            <a (click)="confirmDelete.confirmDelete(theme)">{{
              'smart_reporting.delete' | translate
            }}</a>
            <a (click)="openEdit(theme)">{{
              'smart_reporting.edit' | translate
            }}</a>
          </span>
        </div>
      </span>
    </li>
  </ul>
</div>

<app-modal
  [title]="'smart_reporting.themes.form.' + this.mode + '.title' | translate"
  #formModal
  (onClose)="reset()"
  class="smart-reporting__themes__modal"
>
  <p>
    {{
      'smart_reporting.themes.form.' + this.mode + '.description' | translate
    }}
  </p>

  <div class="alert alert-danger" *ngIf="formError">
    {{ 'smart_reporting.themes.form.error' | translate }}
  </div>

  <form [formGroup]="form" appFormChangeDetector (submit)="submit($event)">
    <app-form-group [form]="form" errorPath="title">
      <label for="title">{{
        'smart_reporting.themes.form.title' | translate
      }}</label>
      <input
        type="text"
        class="input input-lg"
        id="title"
        formControlName="title"
      />
    </app-form-group>
    <app-form-group [form]="form" errorPath="responseTimeDays">
      <label for="responseTimeDays">{{
        'smart_reporting.themes.form.response_time_days' | translate
      }}</label>
      <input
        type="number"
        min="0"
        step="1"
        class="input-lg"
        id="responseTimeDays"
        formControlName="responseTimeDays"
      />
    </app-form-group>
    <br />

    <div class="buttons">
      <button type="submit" class="btn btn-primary" [appLoading]="formLoading">
        {{ 'smart_reporting.themes.form.submit' | translate }}
      </button>
    </div>
  </form>
</app-modal>

<app-confirm-delete
  #confirmDelete
  (confirmed)="deleteTheme($event)"
></app-confirm-delete>
