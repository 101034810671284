import { Component, ElementRef, OnInit, viewChild } from '@angular/core';
import { ModalComponent } from '../../../components/modal.component';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { Project } from '../../../interfaces/project';
import { ProjectDataBusService } from '../../../services/project-data-bus.service';
import { ErrorService } from '../../../services/error.service';
import { Livestream } from '../../../interfaces/livestream';
import { LivestreamService } from '../../../services/livestream.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import Hls from 'hls.js';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDeleteComponent } from '../../../components/confirm-delete.component';
import { LoadingDirective } from '../../../directives/loading.directive';
import { FormGroupComponent } from '../../../components/form-group.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { AccessDirective } from '../../../directives/access.directive';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';
import { LoaderComponent } from 'src/app/components/loader.component';

@Component({
  selector: 'app-default-projects-detail-livestreams',
  templateUrl: './detail-livestreams.component.html',
  standalone: true,
  imports: [
    AccessDirective,
    FormChangeDetectorDirective,
    NgFor,
    InlineSVGModule,
    NgIf,
    ModalComponent,
    ReactiveFormsModule,
    DatePipe,
    FormGroupComponent,
    LoadingDirective,
    ConfirmDeleteComponent,
    TranslateModule,
    LoaderComponent,
  ],
})
export class DetailLivestreamsComponent implements OnInit {
  readonly editModal = viewChild<ModalComponent>('edit');
  readonly video = viewChild<ElementRef>('video');

  livestreamForm: FormGroup;
  projectLivestreams: Livestream[] = [];
  public formLoading = false;
  public targetSaved = false;
  public editingLivestream: Livestream | null = null;

  public showLivestream: boolean = false;
  public project: Project;

  public currentLiveStream: Livestream;
  public currentIndex = 0;

  public showError: boolean = false;
  public loading: boolean = false;
  public iframeUrl: SafeResourceUrl;
  public dataLoading: boolean = true;

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private projectDataBusService: ProjectDataBusService,
    private livestreamService: LivestreamService,
  ) {
    this.projectDataBusService.projectObservable.subscribe(
      (project) => (this.project = project),
    );
    this.createForm();
  }

  openCreate() {
    this.editModal().open();
  }

  openEdit(livestream: Livestream) {
    this.editingLivestream = livestream;
    this.livestreamForm.patchValue(livestream);
    this.editModal().open();
  }

  async ngOnInit(): Promise<void> {
    this.livestreamService.updated$.subscribe((item) => this.load());

    this.livestreamForm.get('url').valueChanges.subscribe(() => {
      this.showLivestream = false;
    });
  }

  start() {
    const url = this.livestreamForm.get('url').value;
    const videoElement = this.video().nativeElement;

    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(url);

      hls.attachMedia(videoElement);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoElement.play();
        this.loading = false;
      });
      hls.on(Hls.Events.ERROR, () => {
        this.showError = true;
      });
    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      videoElement.src = url;

      videoElement.addEventListener('loadedmetadata', () => {
        videoElement.play();
        this.loading = false;
      });
    }
  }

  previewLivestream() {
    if (this.showLivestream) {
      return;
    }
    this.showLivestream = true;
    this.showError = false;

    setTimeout(() => {
      if (this.showLivestream) {
        this.loading = true;
        this.start();
      }
    }, 100);
  }

  reset() {
    this.livestreamForm.reset();
    this.showLivestream = false;

    const videoElement: HTMLVideoElement = this.video()?.nativeElement;
    if (videoElement) {
      videoElement.pause();
      videoElement.currentTime = 0;
      videoElement.src = '';
    }
    this.showError = false;
    this.editingLivestream = null;
  }

  async saveLivestream() {
    this.errorService.markFormGroupTouchedAndDirty(this.livestreamForm);

    if (!this.livestreamForm.valid) {
      return;
    }

    this.formLoading = true;

    try {
      const data = this.livestreamForm.value as Livestream;
      data.url = this.livestreamService.convertToEmbedUrl(data.url);
      if (this.livestreamService.isYoutube(data.url)) {
        data.iframe = true;
      } else {
        data.iframe = false;
      }

      if (this.editingLivestream != null) {
        data.id = this.editingLivestream.id;

        await this.livestreamService.update(data);

        this.editingLivestream.name = data.name;
      } else {
        await this.livestreamService.create(data, this.project);
      }

      this.targetSaved = true;
      this.editingLivestream = null;
      this.editModal().close();
    } catch (error) {
      this.errorService.parseErrorsToForm(this.livestreamForm, error.error);
    } finally {
      this.formLoading = false;
    }
  }

  async deleteLivestream(livestream: Livestream) {
    await this.livestreamService.delete(livestream);
  }

  public isYoutube(url: string) {
    return this.livestreamService.isYoutube(url);
  }

  private createForm() {
    this.livestreamForm = this.fb.group({
      name: ['', Validators.required],
      url: ['', Validators.required],
    });
  }

  private load() {
    this.dataLoading = true;
    this.livestreamService.list(this.project).subscribe((items) => {
      this.projectLivestreams = items;
      this.dataLoading = false;
      // this.projectLivestreams.map(
      //   (livestream) => (livestream.createdBy = 'Marc Jacobs')
      // );
    });
  }
}
