<section class="smart-reporting">
  <div class="bouwapp-header">
    <div class="bouwapp-header__text">
      <h1>{{ 'smart_reporting.notices.title' | translate }}</h1>
    </div>

    <div class="bouwapp-header__buttons">
      <a
        type="button"
        class="tertiary"
        href="javascript:void(0)"
        (click)="exportCsv()"
      >
        <span>
          {{ 'smart_reporting.notices.export' | translate }}
        </span>
      </a>
      <a
        type="button"
        appAccess
        role="manager"
        [project]="noticesTable?.project"
        routerLink="create"
        class="secondary"
        *ngIf="noticesTable?.project"
      >
        <span class="icon" [inlineSVG]="'/assets/icons/add--white.svg'"></span>
        <span>{{ 'smart_reporting.notices.create' | translate }}</span>
      </a>
    </div>
  </div>
  <app-smart-reporting-notices-table></app-smart-reporting-notices-table>
</section>
