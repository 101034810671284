import {
  ChangeDetectorRef,
  Component,
  OnInit,
  viewChild,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TicketService } from '../../../services/ticket.service';
import { Ticket } from '../../../interfaces/ticket';
import { Project } from '../../../interfaces/project';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TicketListComponent } from '../../../components/ticket-list.component';
import { LoaderComponent } from '../../../components/loader.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FormGroupComponent } from 'src/app/components/form-group.component';
import { DatePickerComponent } from 'src/app/components/date-picker.component';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { ProjectStatus } from 'src/app/enums/project-status';
import { Sort } from 'src/app/interfaces/sort';
import { AccessDirective } from 'src/app/directives/access.directive';
import { ModalComponent } from 'src/app/components/modal.component';
import { ErrorService } from 'src/app/services/error.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { LoadingDirective } from 'src/app/directives/loading.directive';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { DetailGeneralContactComponent } from '../projects/detail-general/detail-general-contact.component';
import { ProjectService } from 'src/app/services/project.service';
import { DetailContactComponent } from '../projects/detail-contact.component';
import { CustomFilterType } from 'src/app/enums/custom-filter-type';
import { CustomFilterItem } from 'src/app/interfaces/custom-filter-item';
import { CustomFilter } from 'src/app/interfaces/custom-filter';
import { DataFilterComponent } from 'src/app/components/data-filter.component';

@Component({
  selector: 'app-default-tickets-all',
  templateUrl: 'all.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    InfiniteScrollDirective,
    AccessDirective,
    ModalComponent,
    DetailGeneralContactComponent,
    ReactiveFormsModule,
    DatePickerComponent,
    InlineSVGModule,
    FormGroupComponent,
    LoaderComponent,
    TicketListComponent,
    DetailContactComponent,
    DataFilterComponent,
    TranslateModule,
    LoadingDirective,
  ],
})
export class AllComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal: ModalComponent;

  ticketForm: FormGroup;
  ticketTab: string;
  tickets: Ticket[] = [];
  loading: boolean = false;
  public isCustomer: boolean = false;
  public createTicket: boolean = false;

  protected project: Project;
  protected onProjectPage: boolean = false;

  protected projects: Project[];
  protected projectIds: number[];

  public sortItems: Sort[] = [
    {
      field: 'recent',
      direction: 'desc',
      label: 'project.detail.tickets.sort.direction.updated.descending',
    },
    {
      field: 'recent',
      direction: 'asc',
      label: 'project.detail.tickets.sort.direction.updated.ascending',
    },
  ];

  page: number = 1;
  statusCount;
  totalTickets = 0;
  private formResult;
  perPage: number = 25;

  private readonly STATUSES = ['OPEN', 'PENDING', 'CLOSED'];

  ticketToOpen: Ticket;
  public readonly ticketList =
    viewChild<TicketListComponent>(TicketListComponent);

  constructor(
    protected formBuilder: FormBuilder,
    protected ticketService: TicketService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected projectService: ProjectService,
    protected userDataService: UserDataService,
    protected errorService: ErrorService,
    protected projectDataBusService: ProjectDataBusService,
    private cdr: ChangeDetectorRef,
    deviceDetectorService: DeviceDetectorService,
  ) {
    this.createForms();
    this.activatedRoute.params.subscribe((params) => {
      if (params.id != null) {
        this.loadTicket(+params.id);
      }
    });

    this.ticketToOpen =
      this.router.getCurrentNavigation()?.extras?.state?.ticket;

    this.activatedRoute.parent.params.subscribe((params) => {
      if (!params['slug']) {
        this.onProjectPage = false;
      } else {
        this.onProjectPage = true;
      }

      this.projectDataBusService.shortListObservable.subscribe((shortList) => {
        if (this.onProjectPage) {
          return;
        }
        this.projects = shortList?.filter(
          (project) => project.status === ProjectStatus.PUBLISHED,
        );
        this.project = null;
      });
      this.projectDataBusService.projectObservable.subscribe((project) => {
        if (!project || !this.onProjectPage) {
          return;
        }
        this.project = project;
        this.projectIds = [this.project.id];
        this.projects = [this.project];
      });
    });

    if (deviceDetectorService.isDesktop()) {
      this.perPage *= 2;
    }
  }
  transformToFilters(): CustomFilter[] {
    const filters: CustomFilter[] = [];

    const statusFilters: CustomFilterItem[] = this.getStatuses().map(
      (status, index) => ({
        label: 'conversation.status.title.' + status.toLowerCase(),
        value: status !== 'CLOSED' ? true : false,
        key: status,
        count: this.statusCount ? this.statusCount[status] : undefined,
      }),
    );

    filters.push({
      title: 'project.list.filter.status.title',
      type: CustomFilterType.CHECKBOX,
      filters: statusFilters,
    });

    filters.push({
      title: 'project.list.filter.date.title',
      type: CustomFilterType.DATE,
      filters: [
        {
          label: 'project.list.filter.date.startdate',
          value: '',
          key: 'startDate',
        },
        {
          label: 'project.list.filter.date.enddate',
          value: '',
          key: 'endDate',
        },
      ],
    });

    if (this.projects && !this.project) {
      const projectFilters: CustomFilterItem[] = this.projects.map(
        (project) => ({
          label: project.name,
          value: false,
          key: project.id.toString(),
        }),
      );

      filters.push({
        title: 'project.list.projects.title',
        type: CustomFilterType.CHECKBOX,
        filters: projectFilters,
      });
    }

    return filters;
  }

  getStatuses() {
    return this.STATUSES;
  }

  downloadExport() {
    this.ticketService
      .downloadCsv()
      .then((result) => {
        const blob = new Blob([result], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async ngOnInit() {
    this.activatedRoute.parent.params.subscribe((params) => {
      if (!params['slug']) {
        this.isCustomer = true;
      }
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.tab) this.ticketTab = params.tab;
    });
  }

  public async getStatusCount() {
    if (this.onProjectPage) {
      this.projectIds = [this.project.id];
    }
    this.statusCount = await this.ticketService.getStatusCount(
      this.formResult,
      this.projectIds,
    );
  }

  public getIsCustomer() {
    return this.isCustomer;
  }

  async saveTicket(): Promise<void> {
    this.errorService.markFormGroupTouchedAndDirty(this.ticketForm);
    this.loading = true;

    if (this.ticketForm.valid) {
      const data = this.ticketForm.getRawValue();
      const projectUser = await this.userDataService.retrieveProjectUser();
      data.project = '/api' + TicketService.base + '/' + this.project.slug;
      data.name = data.name || projectUser.firstName;
      data.messages = [];
      data.messages.push({
        content: data.content,
      });
      this.ticketService
        .createTicket(data)
        .then((ticket: Ticket) => {
          this.tickets.push(ticket);
          this.ticketForm.reset();
          this.modal.close();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  /**
   * Create form for filters and set listeners
   */
  protected createForms() {
    this.ticketForm = this.formBuilder.group({
      subject: ['', Validators.required],
      content: ['', Validators.required],
      name: [],
    });
  }

  updateFilter(data: any) {
    this.page = 1;
    const formResult: any = {};
    formResult.search = data.search ?? '';
    formResult.sort = data.sort ?? this.sortItems[0];
    formResult.statuses =
      data.customFilters
        ?.find(
          (f) =>
            f.title === 'project.list.filter.status.title' &&
            f.type === CustomFilterType.CHECKBOX,
        )
        ?.filters.filter((filter) => filter.value)
        .map((filter) => filter.key) || [];

    const dateFilters = data.customFilters?.find(
      (f) =>
        f.title === 'project.list.filter.date.title' &&
        f.type === CustomFilterType.DATE,
    );

    formResult.startDate =
      dateFilters?.filters.find((f) => f.key === 'startDate')?.value || '';
    formResult.endDate =
      dateFilters?.filters.find((f) => f.key === 'endDate')?.value || '';

    this.projectIds =
      data.customFilters
        ?.find(
          (f) =>
            f.title === 'project.list.projects.title' &&
            f.type === CustomFilterType.CHECKBOX,
        )
        ?.filters.filter((filter) => filter.value)
        .map((filter) => parseInt(filter.key)) || [];

    this.formResult = formResult;
    this.loadTickets();
  }

  protected navigateToContact() {
    this.router.navigate(['/projects', this.project.slug, 'contact']);
  }

  /**
   * Load all tickets
   * @param project
   * @returns {Promise<void>}
   */
  protected async loadTickets(): Promise<void> {
    this.loading = true;
    this.cdr.detectChanges();
    let statuses = this.formResult.statuses;

    const response: any = await this.ticketService.list(
      this.project,
      this.formResult,
      statuses,
      this.page,
      this.perPage,
      this.projectIds,
    );
    if (this.page > 1) {
      this.tickets = this.tickets.concat(response['hydra:member']);
    } else {
      this.tickets = response['hydra:member'];
    }
    this.totalTickets = response['hydra:totalItems'];
    this.page++;
    this.loading = false;

    if (this.ticketToOpen) {
      this.ticketList().openTicket(this.ticketToOpen, null);
    }

    this.getStatusCount();
  }

  /**
   * @param id
   */
  protected async loadTicket(id: number) {
    const ticket = await this.ticketService.detail(id);
    ticket.open = true;

    this.ticketService.openTicket(ticket);
    this.router.navigateByUrl(
      this.router.url.replace(/tickets\/([0-9]+)/i, 'tickets'),
    );
  }
}
