<div class="bouwapp-header standalone">
  <div class="bouwapp-header__text">
    <h1>
      <ng-container *ngIf="create">
        {{ 'project.list.new_project' | translate }}
      </ng-container>
      <ng-container *ngIf="!create">
        {{ 'projects.detail.settings.title.prefix' | translate }}
        -
        {{ 'projects.detail.general.title' | translate }}
      </ng-container>
    </h1>
  </div>
  <div class="bouwapp-header__buttons" *ngIf="!isConcept">
    <button
      class="primary"
      (click)="isConcept ? saveConcept(true) : save()"
      [appLoading]="loading || uploading"
    >
      {{ 'project.detail.settings.notifications.save' | translate }}
    </button>
  </div>
</div>

@if (!create) {
  <app-sticky-submit [title]="'projects.detail.general.title'">
    <button
      class="button button--m margin primary"
      (click)="isConcept ? saveConcept(true) : save()"
      [appLoading]="loading || uploading"
    >
      {{ 'project.detail.settings.notifications.save' | translate }}
    </button>
  </app-sticky-submit>
}

<section class="general projects-detail">
  <form
    #formRef="ngForm"
    ngForm
    appFormChangeDetector
    [formGroup]="generalForm"
    appAccess
    role="manager"
    [project]="project"
    property="readonly"
  >
    <app-success-message [hidden]="!generalFormSaved" [onScreen]="true">
      {{ 'projects.detail.general.success' | translate }}
    </app-success-message>
    <app-error-message [hidden]="!hasError" [onScreen]="true">
      {{ 'projects.detail.projects.cannot-save' | translate }}
    </app-error-message>

    <app-two-columns-layout
      [full]="!isConcept"
      [stickyContainerSelector]="'.general.projects-detail'"
    >
      <ng-container left>
        <!-- 01 General -->
        <div class="bouwapp-group">
          <div class="bouwapp-group__header">
            <h2>
              {{ 'projects.detail.general.information' | translate }}
            </h2>
          </div>

          <app-detail-general-info
            (startUploading)="startUploading()"
            (stopUploading)="stopUploading($event)"
            [isConcept]="isConcept"
            [project]="project"
            [generalForm]="generalForm"
            [dateRangeValid]="dateRangeValid"
          ></app-detail-general-info>
        </div>

        <!-- 02 Planning -->
        <div class="bouwapp-group" [hidden]="!isConcept">
          <div class="bouwapp-group__header">
            <h2>
              {{ 'projects.detail.general.planning.header' | translate }}
            </h2>
          </div>

          <div class="bouwapp-box__self">
            <app-detail-general-planning
              [dateRangeValid]="dateRangeValid"
              [generalForm]="generalForm"
            ></app-detail-general-planning>
          </div>
        </div>

        <!-- 03 Location -->
        <div *ngIf="project" class="bouwapp-group">
          <div class="bouwapp-group__header">
            <h2>{{ 'projects.detail.general.location.title' | translate }}</h2>
          </div>
          <div class="bouwapp-box__self">
            <app-detail-general-location
              [project]="project"
              [generalForm]="generalForm"
            ></app-detail-general-location>
          </div>
        </div>

        <ng-container *ngIf="isConcept">
          <!-- 04 Contact -->
          <div class="bouwapp-group">
            <div class="bouwapp-group__header">
              <h2>{{ 'project.detail.contact.title' | translate }}</h2>
            </div>
            <div class="bouwapp-box__self">
              <app-detail-general-contact
                [language]="generalForm.get('language').value"
                [withSave]="false"
              ></app-detail-general-contact>
            </div>
          </div>

          <!-- 05 Targets -->
          <div class="bouwapp-group">
            <div class="bouwapp-group__header">
              <h2>{{ 'project.detail.targets.title' | translate }}</h2>
            </div>
            <div class="bouwapp-box__self">
              <app-detail-general-targets
                [step]="currentIndex"
              ></app-detail-general-targets>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!isConcept">
          <!-- 06 WFS -->
          <div
            class="bouwapp-group"
            *ngIf="
              (!isConcept && project.enabledModules?.includes(Module.WFS)) ||
              (isConcept &&
                project.customer.enabledModules?.includes(Module.WFS))
            "
          >
            <div class="bouwapp-group__header">
              <h2>{{ 'projects.detail.general.wfs.title' | translate }}</h2>
            </div>

            <app-detail-general-wfs
              [generalForm]="generalForm"
              [project]="project"
            ></app-detail-general-wfs>
          </div>
        </ng-container>
      </ng-container>

      <ng-container right>
        <div class="bouwapp-box">
          <div class="bouwapp-box__title">
            <h2>{{ 'projects.detail.concept.save_publish' | translate }}</h2>
          </div>
          <div class="bouwapp-box__self">
            <div class="two-columns__buttons">
              <div class="publication status">
                <span inlineSVG="/assets/img/icons/pin.svg"></span>
                {{ 'projects.detail.update.edit.status' | translate }}
                <span
                  [class.concept]="
                    project?.status === 'UNPUBLISHED' || !project
                  "
                >
                  <strong>{{
                    generalForm.get('status').value | translate
                  }}</strong>
                </span>
              </div>
              <div class="publication notification">
                <span inlineSVG="/assets/img/icons/globe.svg"></span>
                <span
                  >{{
                    'projects.detail.general.project.language.title' | translate
                  }}
                  <span>{{
                    'projects.detail.general.project.language.' +
                      getTranslationKey(generalForm.get('language').value)
                      | translate
                  }}</span>
                </span>
              </div>
            </div>
          </div>
          <div class="bouwapp-box__footer">
            <button
              [appLoading]="loading"
              class="button button--m button--black-white"
              (click)="isConcept ? saveConcept(true) : save()"
              type="submit"
            >
              {{ 'projects.detail.concept.save_unpublished' | translate }}
            </button>
            <button
              [appLoading]="loading"
              *ngIf="project?.status !== 'PUBLISHED'"
              (click)="publish()"
              class="button button--m button--flat bg--orange"
            >
              {{ 'projects.detail.concept.save_publish' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
    </app-two-columns-layout>
  </form>
</section>

<app-modal
  [title]="'projects.detail.concept.preview.title' | translate"
  #previewModal
  class="project-previewModal"
>
  <p class="modal-text">
    {{ 'projects.detail.concept.preview.description' | translate }}
  </p>
  <input
    #codeToCopy
    type="text"
    class="input input--m"
    readonly
    [value]="previewUrl"
    [placeholder]="'projects.detail.concept.preview.save' | translate"
  />
  <div class="flex buttons">
    <button
      [disabled]="!project.id"
      (click)="openPreview()"
      class="button button--m button--flat bg--orange"
    >
      {{ 'projects.detail.concept.preview.title' | translate }}
    </button>
    <button
      [disabled]="!project.id"
      [ngxClipboard]="codeToCopy"
      (cbOnSuccess)="textCopied()"
      class="button button--m button--flat bg--grey"
      [ngClass]="{ isCopied: copied }"
    >
      <span
        [inlineSVG]="'/assets/img/icons/check.svg'"
        [hidden]="!copied"
      ></span>
      {{ 'projects.detail.concept.preview.copy' | translate }}
    </button>
  </div>
</app-modal>
