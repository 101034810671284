<div class="bouwapp-box__subheader" [class.border]="targets.length">
  <p [innerHTML]="'project.detail.targets.project.description' | translate"></p>
  <button
    (click)="openCreate()"
    class="button--black-white"
    appAccess
    role="manager"
    [project]="project"
  >
    <span inlineSVG="/assets/img/icons/plus.svg"></span>
    <p>
      {{ 'project.detail.targets.project.create' | translate }}
    </p>
  </button>
</div>

<app-loader *ngIf="!targets.length"></app-loader>

<section class="targets" *ngIf="targets.length">
  <div class="targets__group">
    <ul
      [hidden]="!targets"
      [dragula]="Sortable.TARGETS"
      [(dragulaModel)]="targets"
      (dragulaModelChange)="updateTargetSortOrder($event)"
    >
      <li *ngFor="let target of targets" class="isCustom">
        <span
          [inlineSVG]="'/assets/img/icons/handle.svg'"
          class="draggable"
          *ngIf="canEdit"
        ></span>
        <p>
          {{ target.title }}
          <ng-container>
            <span
              class="targets__group__edit"
              (click)="openEdit(target)"
              *ngIf="!target.isGlobal"
            >
              {{ 'ticket.edit' | translate }}
            </span>
          </ng-container>
        </p>
        <div appAccess role="manager" [project]="project">
          <div
            [inlineSVG]="'/assets/img/icons/trash.svg'"
            (click)="confirmDelete.confirmDelete(target)"
            class="tile-action tile-delete"
          ></div>
        </div>
      </li>
    </ul>
  </div>
</section>

<section class="projects targets create">
  <app-modal
    [title]="
      (editingTarget
        ? 'project.detail.targets.edit.edit'
        : 'project.detail.targets.edit.create'
      ) | translate
    "
    #edit
    (onClose)="reset()"
  >
    <div class="modal-text">
      {{ 'project.detail.targets.edit.text' | translate }}
    </div>

    <form [formGroup]="targetForm" appFormChangeDetector>
      <app-form-group errorPath="title" [form]="targetForm">
        <label>{{ 'project.detail.targets.edit.title' | translate }}</label>
        <input type="text" class="input input--m" formControlName="title" />
      </app-form-group>

      <div class="flex buttons">
        <button
          type="button"
          class="button button--l button--flat bg--orange"
          (click)="saveTarget()"
          [appLoading]="formLoading"
        >
          {{ 'project.detail.targets.edit.save' | translate }}
        </button>
      </div>
    </form>
  </app-modal>
</section>

<app-success-message [hidden]="!targetSaved" [onScreen]="true">
  {{ 'project.detail.targets.edit.success' | translate }}
</app-success-message>

<app-confirm-delete
  #confirmDelete
  (confirmed)="deleteTarget($event)"
  [message]="'project.detail.targets.remove.warning' | translate"
></app-confirm-delete>
