import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf, NgFor } from '@angular/common';
import { ChartData, ChartOptions } from 'chart.js';

@Component({
  selector: 'app-statistic',
  templateUrl: 'statistic.component.html',
  standalone: true,
  imports: [NgIf, NgFor, BaseChartDirective, InlineSVGModule, TranslateModule],
})
export class StatisticComponent implements OnInit {
  @Input() type: string;
  @Input() data: ChartData;
  @Input() key: string;
  @Input() hasLabels: boolean = false;
  @Input() hasIconLabels: boolean = false;
  @Input() stacked: boolean = false;
  @Output() openModal = new EventEmitter<string>();
  options: ChartOptions;
  labels: any[];
  empty: boolean;
  defaultColors = ['#f5b049', '#3373ea', '#50c878', '#a800f2', '#fc5447'];

  constructor(private translateService: TranslateService) {}

  public ngOnInit() {
    if (this.data) {
      this.options = this.generateOptions();
      this.generateLabels();

      if (this.stacked) {
        if (!this.data.datasets[0].backgroundColor)
          this.data.datasets[0].backgroundColor = this.defaultColors;
        this.empty = this.data.datasets.every((dataset) =>
          dataset.data.every((value) => Number(value) === 0),
        );
      } else {
        this.empty = Object.values(this.data.datasets[0].data).every(
          (value) => Number(value) === 0,
        );
      }
    } else {
      this.empty = true;
    }
  }

  generateLabels() {
    if (this.stacked) {
      this.data.datasets.map(
        (dataset) =>
          (dataset.label = this.translateService.instant(
            `projects.detail.update.stats.${this.key}.${dataset.label}`,
          )),
      );
    } else {
      this.labels = this.data.labels.map((label, index) => {
        const value = this.hasLabels
          ? label
          : this.translateService.instant(
              `projects.detail.update.stats.${this.key}.${label}`,
            );

        this.data.labels[index] = value;

        return {
          key: label,
          value: value,
        };
      });
    }
  }

  generateOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: this.type !== 'bar',
      scales: {
        x: {
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          ticks: {
            color: '#212121',
            font: {
              family: 'Mukta',
              size: 12,
              weight: 600,
            },
            display: this.type === 'bar',
          },
          stacked: this.stacked,
        },
        y: {
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          ticks: {
            stepSize: 1,
            color: '#212121',
            font: {
              family: 'Mukta',
              size: 14,
              weight: 600,
            },
            display: this.type === 'bar',
          },
          stacked: this.stacked,
          beginAtZero: true,
        },
      },
      plugins: {
        tooltip: {
          backgroundColor: '#ffffff',
          titleColor: '#212121',
          bodyColor: '#212121',
          bodyFont: { weight: 'normal', family: 'Mukta' },
          titleFont: { weight: 'bold', family: 'Mukta' },
          cornerRadius: 5,
          borderColor: '#e9e9e9',
          borderWidth: 1,
          padding: 8,
          boxPadding: 8,
        },
        legend: {
          display: true,
          position: 'bottom',
          align: 'start',
          labels: {
            font: {
              family: 'Mukta',
              size: 14,
              weight: 600,
            },
            color: '#212121',
            boxWidth: 20,
            boxHeight: 20,
            padding: 20,
            borderRadius: 5,
            usePointStyle: true,
            pointStyleWidth: 20,
          },
          onHover: (event) => {
            const target = event.native.target as HTMLElement;
            if (target) target.style.cursor = 'pointer';
          },
          onLeave: (event) => {
            const target = event.native.target as HTMLElement;
            if (target) target.style.cursor = 'auto';
          },
        },
      },
    };
  }

  showModal() {
    this.openModal.emit(this.key);
  }
}
