<div class="targets">
  <div class="bouwapp-header standalone">
    <div class="bouwapp-header__text">
      <h1>
        {{ 'projects.detail.settings.title.prefix' | translate }}
        -
        {{ 'project.detail.targets.title' | translate }}
      </h1>
    </div>
  </div>

  <div class="bouwapp-box full">
    <div class="bouwapp-box__title">
      <h2>
        {{ 'project.detail.targets.title' | translate }}
      </h2>
    </div>
    <div class="bouwapp-box__self">
      <app-detail-general-targets></app-detail-general-targets>
    </div>
  </div>
</div>
