<h3>
  {{ 'projects.detail.update.stats.' + key | translate }}
  <span (click)="showModal()" inlineSVG="/assets/img/icons/info.svg"></span>
</h3>
<div class="chart__inner">
  <canvas
    baseChart
    [data]="data"
    [options]="options"
    [type]="type"
    [legend]="stacked"
  ></canvas>
  <div class="empty" *ngIf="empty">
    {{ 'projects.detail.update.stats.empty' | translate }}
  </div>
  <div class="legend" *ngIf="!stacked">
    <div *ngFor="let label of labels; let i = index">
      <span>
        <span
          *ngIf="hasIconLabels"
          [inlineSVG]="'/assets/img/icons/' + label.key + '.svg'"
        ></span>
        <span
          *ngIf="!hasIconLabels"
          [style.backgroundColor]="data.datasets[0].backgroundColor[i]"
        ></span>
        <span>
          {{ label.value }}
        </span>
      </span>
      <span>{{ data.datasets[0].data[i] }}</span>
    </div>
  </div>
</div>
