import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { FileUrlPipe } from 'src/app/pipes/file-url.pipe';

@Component({
  selector: 'app-gain-access',
  templateUrl: './gain-access.component.html',
  standalone: true,
  imports: [FileUrlPipe, InlineSVGModule, TranslateModule],
})
export class GainAccessComponent {}
