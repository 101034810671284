<section class="feedback-not-active">
  <article>
    <h1>{{ 'projects.detail.feedback.gain-access.header' | translate }}</h1>
    <p>
      {{ 'projects.detail.feedback.gain-access.content1' | translate }}
    </p>
    <p>
      {{ 'projects.detail.feedback.gain-access.content2' | translate }}
    </p>
    <a
      href="mailto:info@sitepodium.com"
      class="button button--m button--flat bg--orange"
      ><div>
        {{ 'projects.detail.feedback.gain-access.contact' | translate }}
        <span inlineSVG="/assets/img/icons/arrow-right.svg"></span></div
    ></a>
  </article>
  <article>
    <div>
      <img src="{{ '/assets/img/icons/bar-graph.png' | fileUrl }}" />
      <img src="{{ '/assets/img/icons/graph.png' | fileUrl }}" />
    </div>
    <div>
      <img src="{{ '/assets/img/icons/survey-info.png' | fileUrl }}" />
    </div>
  </article>
</section>
