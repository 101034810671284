<div class="container">
  <div class="bouwapp-header">
    <div class="bouwapp-header__text">
      <h1>{{ 'project.list.title' | translate }}</h1>
    </div>
    <div class="bouwapp-header__buttons">
      <a
        (click)="openModal()"
        type="button"
        class="secondary"
        appAccess
        role="manager"
      >
        <span class="icon" [inlineSVG]="'/assets/icons/add--white.svg'"></span>
        <span>{{ 'project.list.new_update' | translate }}</span>
      </a>
      <a
        (click)="createNewProject()"
        class="primary"
        appAccess
        role="projectAdmin"
      >
        <span class="icon" [inlineSVG]="'/assets/icons/add--white.svg'"></span>
        <span>{{ 'project.list.new_project' | translate }}</span>
      </a>
    </div>
  </div>
</div>
<div class="projects projects--list">
  <div class="container">
    <div class="projects__overview flex-row">
      <app-data-filter
        (filterChanged)="updateFilter($event)"
        [sortItems]="sortItems"
        [customFilters]="transformToFilters()"
        [searchLabel]="'project.list.search'"
        [sortLabel]="'project.list.sort'"
      >
        <div class="projects__overview__items">
          <p *ngIf="projects && projects.length">
            {{
              'project.list.result.title' | translate: { projects: totalItems }
            }}
          </p>

          <ul
            [hidden]="!projects"
            infiniteScroll
            (scrolled)="loadProjects()"
            [infiniteScrollContainer]="'div.app__content'"
            [infiniteScrollDisabled]="projects?.length == totalItems"
            [fromRoot]="true"
          >
            <li
              *ngFor="let project of projects"
              (click)="openProject(project)"
              class="project"
            >
              <div class="project__top">
                <div class="project__image">
                  <div
                    class="project__image__self"
                    *ngIf="project.coverImageThumbnails"
                    [style.backgroundImage]="getCoverImageByProject(project)"
                  ></div>
                  <div
                    class="project__image__self noImage"
                    *ngIf="!project.coverImageThumbnails"
                  ></div>
                  <div class="project__badge" *ngIf="project.isParentProject">
                    {{ 'project.list.item.type.parent' | translate }}
                  </div>
                  <div
                    class="project__badge child"
                    *ngIf="project.hasParentProject && !project.isParentProject"
                  >
                    {{ 'project.list.item.type.child' | translate }}
                  </div>
                </div>
                <div class="project__heading">
                  <div>
                    <span [ngClass]="[getStatusClass(project)]">
                      {{ project.status | translate }}
                    </span>
                    <span>{{ project.participants }}</span>
                  </div>
                  <p>
                    {{ project.name }}
                  </p>
                  <div class="followers">
                    <span>
                      <span inlineSVG="/assets/img/icons/person.svg"></span>
                      <span>{{ project.amountOfFollowers }}</span>
                    </span>
                    <span>
                      ({{
                        'project.list.item.followers.gained'
                          | translate: { gained: project.lastProjectStars }
                      }})
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="project__parent"
                *ngIf="project.hasParentProject && !project.isParentProject"
                [innerHTML]="
                  'project.list.item.projects.text'
                    | translate: { name: project.parentProject.name }
                "
              ></div>
            </li>
          </ul>

          <app-loader *ngIf="loading"></app-loader>

          <div class="no-results" *ngIf="projects && projects.length === 0">
            {{ 'project.list.empty' | translate }}
          </div>
        </div>
      </app-data-filter>
    </div>
  </div>
  <app-customer-picker
    [withProjectPermissions]="true"
    [withSwitcher]="false"
    #customerPicker
  ></app-customer-picker>
  <app-update-picker
    [customers]="customers"
    [projects]="projectShortListForUpdates"
  ></app-update-picker>
  <app-modal #successModal class="success-modal">
    <section class="project-success">
      <span [inlineSVG]="'/assets/img/icons/confetti.svg'"></span>
      <h5>
        {{ 'projects.detail.concept.success.title' | translate }}
      </h5>
      <p
        [innerHTML]="
          'projects.detail.concept.success.description'
            | translate: { projectName: project?.name }
        "
      ></p>
      <div class="flex buttons">
        <button
          class="button button--m button--outlined bg--grey--hover"
          [routerLink]="['/projects', project?.slug, 'general']"
        >
          {{ 'projects.detail.concept.success.link.settings' | translate }}
        </button>
        <button
          class="button button--m button--flat bg--blue"
          [routerLink]="['/projects', project?.slug, 'updates']"
        >
          {{ 'projects.detail.concept.success.link.updates' | translate }}
        </button>
      </div>
    </section>
  </app-modal>
</div>
