import { Injectable } from '@angular/core';
import { Customer } from '../interfaces/customer';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LogEntry } from '../interfaces/log-entry';
import { firstValueFrom, Subscription } from 'rxjs';
import { CollectionResponse } from '../interfaces/collection-response';

@Injectable()
export class CustomerService {
  static base: string = '/customers';
  private currentRequest: Subscription = null;

  constructor(private http: HttpClient) {}

  public fetchBySlug(slug: string) {
    return this.http
      .get<Customer>(environment.apiUrlV3 + CustomerService.base + '/' + slug)
      .toPromise();
  }

  /**
   * @param {number} id
   * @returns {Promise<Customer>}
   */
  public fetch(id: number): Promise<Customer> {
    return this.http
      .get<Customer>(environment.apiUrl + CustomerService.base + '/' + id)
      .toPromise();
  }

  /**
   * @param customer
   * @returns {Promise<User>}
   */
  public update(customer: Customer): Promise<Customer> {
    return this.http
      .put<Customer>(
        environment.apiUrl + CustomerService.base + '/' + customer.id,
        customer,
      )
      .toPromise();
  }

  /**
   * @param customer
   * @param {string} sort
   * @param {string|null} search
   * @returns {Promise<LogEntry[]>}
   */
  public getLog(
    customer: Customer,
    filterForm: any,
    page: number,
    limit: number = 100,
    userIds?: number[],
    projectIds?: number[],
  ): Promise<CollectionResponse<LogEntry>> {
    return new Promise((resolve, reject) => {
      let params: HttpParams = new HttpParams();

      if (filterForm?.sort?.field) {
        params = params.set(
          'order[' + filterForm.sort.field + ']',
          filterForm.sort.direction,
        );
      }

      if (filterForm?.categories != null && filterForm.categories.length > 0) {
        filterForm.categories.forEach(
          (s, i) => (params = params.set('entityType[' + i + ']', s)),
        );
      }

      if (userIds != null && userIds.length > 0) {
        userIds.forEach((s, i) => (params = params.set('user[' + i + ']', s)));
      }

      if (projectIds != null && projectIds.length > 0) {
        projectIds.forEach(
          (s, i) => (params = params.set('project[' + i + ']', s)),
        );
      }

      params = params.set('page', String(page));
      params = params.set('perPage', String(limit));

      if (filterForm.startDate !== '') {
        params = params.set('startDate', filterForm.startDate);
      }

      if (filterForm.endDate !== '') {
        params = params.set('endDate', filterForm.endDate);
      }

      if (this.currentRequest != null) {
        this.currentRequest.unsubscribe();
      }

      const observable = this.http.get<CollectionResponse<LogEntry>>(
        environment.apiUrlV3 +
          CustomerService.base +
          '/' +
          customer.id +
          '/logs',
        { params },
      );

      this.currentRequest = observable.subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  getLogCategories(customer: Customer): Promise<any> {
    return firstValueFrom(
      this.http.get<Customer>(
        environment.apiUrl +
          CustomerService.base +
          '/' +
          customer.id +
          '/logs/count',
      ),
    );
  }
}
