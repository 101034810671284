<div class="chart" [ngClass]="{'satisfaction-distribution-wrapper': type == 'satisfaction-distribution'}">
  <div class="chart-top">
    <span class="chart-title">{{ title }}</span>
    <a [hidden]="!csvExport" (click)="exportCsv()" class="chart-export">{{
      'chart.export' | translate
    }}</a>
  </div>

  <div *ngIf="choices" [formGroup]="choiceForm">
    <div *ngFor="let choice of choices" class="chart__option__items">
      <div
        class="chart__option__item"
        *ngFor="let value of choice.values | keyvalue"
      >
        <div class="radio">
          <input
            type="radio"
            id="{{ choice.key + value.value }}"
            [name]="choice.key"
            [value]="value.value"
            [formControlName]="choice.key"
          />
          <label for="{{ choice.key + value.value }}">{{ value.key }}</label>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="type != 'satisfaction-distribution'">
    <div class="chart__holder">
      <canvas
        baseChart
        [datasets]="chartData"
        [type]="getType()"
        [labels]="chartLabels"
        [options]="options"
      ></canvas>

      <ng-container *ngFor="let point of responsePoints">
        <div
          *ngIf="point"
          class="chart__point"
          [style.left]="point.x + 'px'"
          [style.bottom]="point.y + 'px'"
        >
          <div
            inlineSVG="/assets/img/icons/speech-bubble.svg"
            class="inline-svg__item chart__point__speech"
            (click)="openPoint(point)"
          ></div>

          <div class="chart__point__responses" [hidden]="!point.open">
            <div class="chart__point__responses__scroll">
              <div
                *ngFor="let response of point.responses"
                class="chart__point__response"
              >
                <strong>{{ response.theme }}:</strong> {{ response.score
                }}<br />
                {{ response.content }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <form [formGroup]="dataSetForm">
      <ul formArrayName="dataSets">
        <li *ngIf="dataSets.length > 2">
          <div class="checkbox">
            <input
              [checked]="allChecked"
              id="dataSet-{{ dashedTitle }}-all"
              type="checkbox"
              (change)="toggleAll($event)"
            />
            <label for="dataSet-{{ dashedTitle }}-all">{{
              'chart.toggle-all' | translate
            }}</label>
          </div>
        </li>
        <li
          *ngFor="let set of dataSets; let i = index"
          [formGroupName]="i"
          [class.chart-dataset-prior]="isDashed(i)"
        >
          <div class="checkbox" *ngIf="toggle">
            <input
              id="dataSet-{{ dashedTitle + '-' + i }}"
              type="checkbox"
              formControlName="enabled"
            />
            <label for="dataSet-{{ dashedTitle + '-' + i }}">{{
              set.label
            }}</label>
          </div>
          <span
            class="status"
            [style.background-color]="createDataSetColor(i).borderColor"
            [class.status-dashed]="isDashed(i)"
          ></span>
        </li>
      </ul>
    </form>
  </div>

  <div *ngIf="type == 'satisfaction-distribution'">
    <app-satisfaction-distribution
      [data]="dataSets"
    ></app-satisfaction-distribution>
  </div>
</div>
