<app-data-filter
  (filterChanged)="updateFilter($event)"
  [sortItems]="sortItems"
  [customFilters]="transformToFilters()"
  [searchLabel]="'smart_reporting.notices.search'"
  [sortLabel]="'project.list.sort'"
>
  <div class="smart-reporting__content">
    <div class="smart-reporting__wrapper">
      <p *ngIf="notices && notices.length">
        {{
          'smart_reporting.notices.result.title'
            | translate: { total: totalItems }
        }}
      </p>

      <div
        *ngIf="notices.length"
        class="smart-reporting__list notices"
        infiniteScroll
        (scrolled)="loadNotices()"
        [infiniteScrollContainer]="'div.app__content'"
        [infiniteScrollDisabled]="notices.length == totalItems"
        [fromRoot]="true"
      >
        <ul class="head">
          <li [class.isCustomer]="!project">
            <span>
              {{ 'smart_reporting.notices.table.status' | translate }}
            </span>
            <span>
              {{ 'smart_reporting.notices.table.date' | translate }}
            </span>
            <span *ngIf="!project">
              {{ 'smart_reporting.notices.table.project' | translate }}
            </span>
            <span>
              {{ 'smart_reporting.notices.table.theme' | translate }}
            </span>
            <span>
              {{ 'smart_reporting.notices.table.name' | translate }}
            </span>
            <span>
              {{ 'smart_reporting.notices.table.followers' | translate }}
            </span>
            <span>
              {{ 'smart_reporting.notices.table.response_date' | translate }}
            </span>
          </li>
        </ul>

        <ul class="body">
          <li
            *ngFor="let notice of notices"
            [class.isCustomer]="!project"
            routerLink="{{ notice.id }}"
          >
            <span>
              <span
                class="label"
                [class.label-primary]="
                  SmartReportingNoticeStatus.OPEN === notice.status
                "
                [class.label-success]="
                  SmartReportingNoticeStatus.CLOSED === notice.status
                "
                [class.label-info]="
                  SmartReportingNoticeStatus.IN_PROGRESS === notice.status
                "
                [class.label-secondary]="
                  SmartReportingNoticeStatus.DELETED === notice.status
                "
                >{{
                  'smart_reporting.notices.table.status.' + notice.status
                    | translate
                }}</span
              >
            </span>
            <span class="datetime">
              <span class="date">{{
                notice.createdAt | date: 'mediumDate'
              }}</span>
              <span class="time">{{
                notice.createdAt | date: 'shortTime'
              }}</span>
            </span>
            <span
              [attr.aria-label]="
                'smart_reporting.notices.table.project' | translate
              "
              *ngIf="!project"
            >
              {{ notice.project.name }}
            </span>
            <span
              [attr.aria-label]="
                'smart_reporting.notices.table.theme' | translate
              "
            >
              {{ notice.theme.title }}
            </span>
            <span
              [attr.aria-label]="
                'smart_reporting.notices.table.name' | translate
              "
            >
              {{ notice.name }}
            </span>
            <span
              [attr.aria-label]="
                'smart_reporting.notices.table.followers' | translate
              "
              >{{ notice.amountOfSubscribers }}</span
            >
            <span
              class="datetime"
              [attr.aria-label]="
                'smart_reporting.notices.table.response_date' | translate
              "
            >
              <span class="date">{{
                notice.lastResponseDate | date: 'mediumDate'
              }}</span>
              <span class="time">{{
                notice.lastResponseDate | date: 'shortTime'
              }}</span>
            </span>
          </li>
        </ul>
      </div>
      <div class="list" [hidden]="loading || notices">
        <div class="no-results">
          {{ 'ticket_list.table.no_results' | translate }}
        </div>
      </div>
      <app-loader *ngIf="loading"></app-loader>
    </div>
  </div>
</app-data-filter>
