import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { Target } from '../interfaces/target';
import { TargetService } from '../services/target.service';
import { Subscription } from 'rxjs';
import { Project } from '../interfaces/project';
import { ThemeService } from '../services/theme.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'app-target-picker',
  templateUrl: './target-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TargetPickerComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, NgFor, TranslateModule],
})
export class TargetPickerComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input() public project: Project;
  @Input() public multiple: boolean = false;
  @Input() public label: boolean = true;
  @Input() private update: boolean = false;

  private dataSubscriptions: Subscription[] = [];
  private formSubscription: Subscription;

  public targets: Target[];
  public selectedTargets: Target[] = [];
  public control: AbstractControl;
  public multipleControls: { [key: number]: AbstractControl } = {};
  version: number;
  @Output() targetsLoaded: EventEmitter<Target[]> = new EventEmitter<
    Target[]
  >();

  constructor(
    private targetService: TargetService,
    private fb: FormBuilder,
    private translateService: TranslateService,
  ) {
    this.createControl();
  }

  public ngOnInit() {
    this.dataSubscriptions.push(
      this.targetService.listAll(this.project).subscribe(async (data) => {
        if (this.update) {
          const updateTarget: Target = {
            id: -1,
            devicesCount: 0,
            isGlobal: false,
            slug: '',
            title: this.translateService.instant(
              'projects.detail.update.edit.targets.area-followers',
            ),
            project: null,
            '@id': '-1',
          };
          data.push(updateTarget);
        }

        this.targets = data;

        this.createControls();

        this.targetsLoaded.emit(this.targets);
      }),
    );
  }

  public async setAreaFollowersCount(devicesCount: number) {
    if (this.targets) {
      const areaTarget = this.targets.find((value) => value.id === -1);
      areaTarget.devicesCount = devicesCount;
      this.targetsLoaded.emit(
        this.selectedTargets?.length ? this.selectedTargets : this.targets,
      );
    }
  }

  public ngOnDestroy() {
    this.dataSubscriptions.forEach((subscription) =>
      subscription.unsubscribe(),
    );
  }

  propagateChange = (_: any) => {};
  propagateTouch = (_: any) => {};

  writeValue(value: Target | Target[]): void {
    if (value != null) {
      this.setValue(value);
    }

    this.createControls();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  private setValue(value: Target | Target[]): void {
    if (this.multiple) {
      this.selectedTargets = value as Target[];
    } else {
      this.control.patchValue((value as Target).id);
    }
  }

  private emitValue(target: Target | Target[] | null) {
    this.propagateChange(target);
    this.propagateTouch(target);
  }

  private createControl(): void {
    this.control = this.fb.control('');
    this.formSubscription = this.control.valueChanges.subscribe(
      (value: string) => {
        if (value === '' || value == null) {
          this.emitValue(null);
        } else {
          const target = this.targets.find(
            (item: Target) => item.id === parseInt(value),
          );

          this.emitValue(target);
        }
      },
    );
  }

  public isChecked(target: Target): boolean {
    return this.selectedTargets.some((item) => target.id === item.id);
  }

  private createControls() {
    if (!this.targets) {
      return;
    }

    this.targets.forEach((item) => {
      this.multipleControls[item.id] = this.fb.control(this.isChecked(item));
      this.multipleControls[item.id].valueChanges.subscribe((checked) => {
        if (!checked) {
          this.selectedTargets = this.selectedTargets.filter(
            (selectedItem) => selectedItem.id !== item.id,
          );
        } else {
          this.selectedTargets.push(item);
        }

        this.emitValue(this.selectedTargets);
      });
    });
  }
}
