import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingDirective } from 'src/app/directives/loading.directive';

@Component({
  selector: 'app-sticky-submit',
  templateUrl: './sticky-submit.component.html',
  standalone: true,
  imports: [TranslateModule, LoadingDirective, NgIf],
})
export class StickySubmitComponent implements OnInit, OnDestroy {
  private scrollListener: EventListener;
  public show = false;
  public readonly title = input<string>('');
  public readonly showDefaultText = input<boolean>(true);
  constructor() {}

  ngOnInit() {
    this.scrollListener = (event: any) =>
      (this.show = event.target.scrollTop >= 100);

    document
      .querySelector('.app__content')
      .addEventListener('scroll', this.scrollListener);
  }

  ngOnDestroy(): void {
    document
      .querySelector('.app__content')
      .removeEventListener('scroll', this.scrollListener);
  }
}
