import { Component, ElementRef, OnInit, viewChild } from '@angular/core';
import { Project } from '../../../interfaces/project';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
  ReactiveFormsModule,
  FormArray,
} from '@angular/forms';
import { ErrorService } from '../../../services/error.service';
import { ProjectDataBusService } from '../../../services/project-data-bus.service';
import { ProjectDocument } from '../../../interfaces/project-document';
import { ProjectDocumentService } from '../../../services/project-document.service';
import { ModalComponent } from '../../../components/modal.component';
import { Attachment } from '../../../interfaces/attachment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DocumentCategoryService } from '../../../services/document-category.service';
import { DocumentCategory } from '../../../interfaces/document-category';
import { AttachmentHelper } from '../../../shared/attachment-helper';
import { DragulaOptions, DragulaService, DragulaModule } from 'ng2-dragula';
import { debounceTime, Subscription } from 'rxjs';
import { ProjectService } from 'src/app/services/project.service';
import { Sortable } from '../../../enums/sortable';
import { InputFilePreviewComponent } from 'src/app/components/input-file-preview.component';
import { ActivatedRoute } from '@angular/router';
import { AccessService } from 'src/app/services/access.service';
import { SecurityVoter } from 'src/app/security/security-voter';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmDeleteComponent } from '../../../components/confirm-delete.component';
import { QuillComponent } from '../../../components/quill.component';
import { LoadingDirective } from '../../../directives/loading.directive';
import { InputFilePreviewComponent as InputFilePreviewComponent_1 } from '../../../components/input-file-preview.component';
import { InputFileComponent } from '../../../components/input-file.component';
import { FormGroupComponent } from '../../../components/form-group.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { AccessDirective } from '../../../directives/access.directive';
import { LoaderComponent } from '../../../components/loader.component';
import { NgIf, NgFor, NgTemplateOutlet } from '@angular/common';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';
import { SuccessMessageComponent } from 'src/app/components/success-message.component';

@Component({
  selector: 'app-default-projects-detail-documents',
  templateUrl: './detail-documents.component.html',
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    AccessDirective,
    DragulaModule,
    FormChangeDetectorDirective,
    NgFor,
    InlineSVGModule,
    ModalComponent,
    ReactiveFormsModule,
    FormGroupComponent,
    InputFileComponent,
    InputFilePreviewComponent_1,
    LoadingDirective,
    QuillComponent,
    ConfirmDeleteComponent,
    TranslateModule,
    NgTemplateOutlet,
    SuccessMessageComponent,
  ],
})
export class DetailDocumentsComponent implements OnInit {
  public readonly MAX_DOCUMENTS = 10;
  public readonly documentModal = viewChild<ModalComponent>('editDocument');
  public readonly categoryModal = viewChild<ModalComponent>('editCategory');
  public readonly inputPreview =
    viewChild<InputFilePreviewComponent>('preview');

  private formChangesSubscription: Subscription;
  private isInitialized: boolean = false;
  attachmentPreview: Attachment = null;
  documentForm: FormGroup;
  categoryForm: FormGroup;
  sectionsForm: FormGroup; //v2
  editingDocument: ProjectDocument;
  editingCategory: DocumentCategory;
  formLoading: boolean;
  documentSaved: boolean;
  categorySaved: boolean;
  documentThumbnail: string;
  sortParentOptions: DragulaOptions;
  Sortable = Sortable;
  videoPreview: string;
  canEdit: boolean = false;
  projectObservableSubscription: Subscription;
  projectDocumentServiceSubscription: Subscription;
  documentCategoryServiceSubscription: Subscription;
  public project: Project;
  public documentCategories: DocumentCategory[] = [];
  public documents: ProjectDocument[][] = [];
  public showCreateButton = false;
  public loading = false;
  public uploading = false;
  public formSaved: boolean = false;

  public defaultSections = [
    'resident-letters',
    'news',
    'detour',
    'routemap',
    'images',
    'planning',
  ];

  constructor(
    private fb: FormBuilder,
    private errorService: ErrorService,
    private projectDataBusService: ProjectDataBusService,
    private projectDocumentService: ProjectDocumentService,
    private projectService: ProjectService,
    private documentCategoryService: DocumentCategoryService,
    private sanitizer: DomSanitizer,
    private dragulaService: DragulaService,
    private element: ElementRef,
    private route: ActivatedRoute,
    private accessService: AccessService,
    private translateService: TranslateService,
  ) {
    this.projectObservableSubscription =
      this.projectDataBusService.projectObservable.subscribe((project) => {
        this.project = project;

        if (this.documentCategoryServiceSubscription)
          this.documentCategoryServiceSubscription.unsubscribe();
        if (this.projectDocumentServiceSubscription)
          this.projectDocumentServiceSubscription.unsubscribe();

        this.route.params.subscribe((params) => {
          if (project.slug === params?.slug) {
            this.subscribeToDocumentChanges();
          }
        });

        this.route.parent?.params.subscribe((params) => {
          if (project.slug === params.slug) {
            this.subscribeToDocumentChanges();
          }
        });
      });
    this.createForms();

    this.dragulaService.createGroup(Sortable.CATEGORIES, {
      moves: (el, container, handle) => {
        return (
          handle.classList.contains('draggable-parent') ||
          handle.parentElement.classList.contains('draggable-parent') ||
          handle.parentElement.parentElement.classList.contains(
            'draggable-parent',
          )
        );
      },
      mirrorContainer: document.getElementsByTagName(
        'app-default-projects-detail-general',
      )[0],
    });

    this.dragulaService.createGroup(Sortable.DOCUMENTS, {
      moves: (el, container, handle) => {
        return (
          handle.classList.contains('draggable-child') ||
          handle.parentElement.classList.contains('draggable-child') ||
          handle.parentElement.parentElement.classList.contains(
            'draggable-child',
          )
        );
      },
      accepts: (el, target, source) => {
        return (
          source.getAttribute('category-id') ==
          target.getAttribute('category-id')
        );
      },
      mirrorContainer: this.element.nativeElement,
    });
  }

  private subscribeToDocumentChanges() {
    this.projectDocumentServiceSubscription =
      this.projectDocumentService.updated$.subscribe((item) => this.load());
    this.documentCategoryServiceSubscription =
      this.documentCategoryService.updated$.subscribe((item) => this.load());
  }

  async updateCategorySortOrder(event) {
    let data = {};
    event.forEach((category, index) => {
      data[category.value.id] = index;
    });
    this.projectService.updateDocumentCategorySortOrder(
      this.project.slug,
      data,
    );
  }

  updateDocumentSortOrder(event, documentCategory: DocumentCategory) {
    let data = {};
    event.forEach((document, index) => {
      data[document.id] = index;
    });
    this.documentCategoryService.updateProjectDocumentSortOrder(
      documentCategory,
      data,
    );
  }

  async ngOnInit() {
    if (this.project.create !== true) {
      this.accessService.accessControlList.subscribe(
        (acl) =>
          (this.canEdit = SecurityVoter.canEditProject(acl, this.project)),
      );
    } else {
      this.accessService.accessControlList.subscribe(
        (acl) =>
          (this.canEdit =
            SecurityVoter.hasCreateRole(acl) ||
            SecurityVoter.hasCustomers(acl)),
      );
    }
    this.listenToFormChanges();
  }

  ngOnDestroy() {
    this.dragulaService.destroy(Sortable.CATEGORIES);
    this.dragulaService.destroy(Sortable.DOCUMENTS);
    this.projectObservableSubscription.unsubscribe();
    if (this.projectDocumentServiceSubscription)
      this.projectDocumentServiceSubscription.unsubscribe();
    if (this.documentCategoryServiceSubscription)
      this.documentCategoryServiceSubscription.unsubscribe();

    if (this.formChangesSubscription) {
      this.formChangesSubscription.unsubscribe();
    }
  }

  openDocumentCreate(category: DocumentCategory) {
    this.editingCategory = category;
    this.documentModal().open();
  }

  openDocumentEdit(projectDocument: ProjectDocument) {
    this.editingDocument = projectDocument;
    this.documentForm.patchValue(projectDocument);
    this.documentModal().open();
  }

  openCategoryCreate() {
    this.categoryModal().open();
  }

  openCategoryEdit(category: DocumentCategory) {
    this.editingCategory = category;
    this.categoryForm.patchValue(category);
    this.categoryModal().open();
  }

  resetDocument() {
    this.documentForm.reset();
    this.editingDocument = null;
  }

  resetCategory() {
    this.categoryForm.reset();
    this.editingCategory = null;
  }

  updateVideo(event) {
    if (event.type == 'youtube') {
      this.inputPreview().setVideoPreview(null);
      this.documentForm.get('videoId').patchValue(event.url);
      this.documentForm.get('fileName').reset();
      this.documentForm.get('filePath').reset();
    } else {
      this.videoPreview = URL.createObjectURL(event.file.rawFile);
      this.inputPreview().setVideoPreview(
        this.sanitizer.bypassSecurityTrustUrl(this.videoPreview),
      );
      this.documentForm.get('fileName').patchValue(event.file.name);
      this.documentForm.get('filePath').patchValue(event.url);
      this.documentForm.get('videoId').reset();
    }
  }

  updatePreview(event) {
    this.documentForm.get('fileName').reset();
    this.documentForm.get('videoId').reset();
    this.inputPreview().update(event);
  }

  clearVideo() {
    this.inputPreview().setVideoPreview(null);
    this.documentForm.get('fileName').reset();
    this.documentForm.get('videoId').reset();
  }

  private createForms() {
    this.documentForm = this.fb.group(
      {
        title: ['', Validators.required],
        fileName: [null],
        filePath: [null],
        filePathThumbnails: [{}],
        videoId: [null],
      },
      { validators: this.atLeastOneRequiredValidator(['filePath', 'videoId']) },
    );
    this.categoryForm = this.fb.group({
      title: ['', Validators.required],
      content: [null],
    });

    //v2
    this.sectionsForm = this.fb.group({
      sections: this.fb.array([]),
    });
  }

  get sections(): FormArray {
    return this.sectionsForm.get('sections') as FormArray;
  }

  getDocuments(categoryIndex: number): FormArray {
    return this.sections.at(categoryIndex).get('documents') as FormArray;
  }

  async addCategory(defaultSection?: string) {
    const title = defaultSection
      ? this.translateService.instant(
          'project.detail.sections.default.' + defaultSection,
        )
      : this.translateService.instant('project.detail.sections.default.empty', {
          index: this.sections.length + 1,
        });
    const data: DocumentCategory = {
      project: this.project,
      title: title,
    };

    this.addSectionGroup(data);
    const index = this.sections.length - 1;
    setTimeout(() => {
      const element = document.querySelectorAll('.project-category')[index];
      element.scrollIntoView({ behavior: 'smooth' });
    }, 100);

    try {
      const result = await this.documentCategoryService.create(
        this.project,
        data,
      );
      this.sections.at(index).patchValue(result);
    } catch (error) {
      this.sections.removeAt(index);
    }
  }

  addSectionGroup(sectionData: DocumentCategory) {
    const sectionGroup = this.fb.group({
      id: [sectionData.id],
      title: [sectionData.title, Validators.required],
      content: [sectionData.content],
    });
    this.sections.push(sectionGroup);
  }

  async deleteProjectDocument(projectDocument: ProjectDocument) {
    await this.projectDocumentService.delete(projectDocument);
  }

  async deleteCategory(category: DocumentCategory) {
    try {
      await this.documentCategoryService.delete(category);
      const index = this.sections.value.findIndex(
        (_category) => _category.id == category.id,
      );

      if (index > -1) {
        this.sections.removeAt(index);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async saveDocument() {
    if (this.uploading) {
      return;
    }

    this.errorService.markFormGroupTouchedAndDirty(this.documentForm);
    if (!this.documentForm.valid) {
      return;
    }

    this.formLoading = true;
    try {
      const data = this.documentForm.value as ProjectDocument;

      if (this.editingDocument != null) {
        await this.projectDocumentService.update(this.editingDocument.id, data);
        this.editingDocument.title = data.title;
      } else {
        data.category = this.editingCategory;
        await this.projectDocumentService.create(data);
      }
      this.documentSaved = true;
      this.editingDocument = null;
      this.editingCategory = null;
      this.documentModal().close();
    } catch (error) {
      this.errorService.parseErrorsToForm(this.documentForm, error.error);
    } finally {
      this.formLoading = false;
    }
  }

  listenToFormChanges(): void {
    this.formChangesSubscription = this.sectionsForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe((changes) => {
        if (this.isInitialized) {
          this.saveChanges();
        }
      });
  }

  async saveChanges() {
    this.errorService.markFormGroupTouchedAndDirty(this.sectionsForm);
    if (!this.sectionsForm.valid) {
      return;
    }
    this.formLoading = true;
    try {
      const data = this.sections.value.filter((item) => item.id > 0);
      if (data.length > 0) {
        await this.documentCategoryService.updateBatch(data);
      }
    } catch (error) {
      this.errorService.parseErrorsToForm(this.categoryForm, error.error);
    } finally {
      this.formLoading = false;
      this.formSaved = true;
      setTimeout(() => {
        this.formSaved = false;
      }, 2000);
    }
  }

  async saveCategory() {
    this.errorService.markFormGroupTouchedAndDirty(this.categoryForm);

    if (!this.categoryForm.valid) {
      return;
    }

    this.formLoading = true;
    try {
      const data = this.categoryForm.value as DocumentCategory;

      if (this.editingCategory != null) {
        data.id = this.editingCategory.id;
        this.editingCategory.title = data.title;
        await this.documentCategoryService.update(data);
      } else {
        data.project = this.project;
        await this.documentCategoryService.create(this.project, data);
      }

      this.categorySaved = true;
      this.editingCategory = null;
      this.categoryModal().close();
    } catch (error) {
      this.errorService.parseErrorsToForm(this.categoryForm, error.error);
    } finally {
      this.formLoading = false;
    }
  }

  isVideo(attachment: Attachment) {
    return (
      /\.mp4$/i.test(attachment.filePath) ||
      /\blob:$/i.test(attachment.filePath) ||
      /\.mov$/i.test(attachment.filePath)
    );
  }

  /**
   * @param attachment
   * @returns {SafeStyle|string}
   */
  getAttachmentImage(attachment: Attachment): SafeResourceUrl | string {
    let url;

    if (attachment.videoId) {
      url = AttachmentHelper.getVideoImageForId(attachment.videoId);
    } else {
      url =
        attachment?.thumbnailPathThumbnails?.small ??
        attachment?.filePathThumbnails?.small;
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  private async load() {
    if (this.loading) {
      return false;
    }
    this.loading = true;
    this.documentCategories = await this.documentCategoryService
      .fetchListForProject(this.project)
      .toPromise();
    if (this.sectionsForm) {
      this.sectionsForm.reset();
      this.sections.clear();
    }
    this.documentCategories.forEach((section) => {
      this.addSectionGroup(section);
    });

    const promises = [];
    for (const category of this.documentCategories) {
      promises.push(
        this.projectDocumentService
          .fetchListForCategory(category)
          .toPromise()
          .then((items) => {
            this.documents[category.id] = items;
          }),
      );
    }

    await Promise.all(promises);

    this.loading = false;
    this.isInitialized = true;
  }

  public startUploading() {
    this.uploading = true;
  }

  public stopUploading() {
    this.uploading = false;
  }

  async downloadDocument(file: ProjectDocument) {
    if (this.isYoutube(file)) {
      window.open('https://youtu.be/' + file.videoId, '_blank');
      return;
    }

    const fileUrl = file?.filePathThumbnails?.full;
    if (!fileUrl) {
      throw new Error('Resource couldnt be loaded');
    }

    fetch(fileUrl, {
      method: 'GET',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Resource couldnt be loaded');
        }
        return response.blob().then((blob) => blob);
      })
      .then((blob) => {
        const link = document.createElement('a');
        const objectUrl = window.URL.createObjectURL(blob);
        link.href = objectUrl;

        const fileExtension = this.getFileExtension(file.mimeType);
        const id = file.id.toString(16);
        link.download = `document.${id}.${fileExtension}`;

        link.click();
        window.URL.revokeObjectURL(objectUrl);
      })
      .catch((error) => {
        console.error('There was an error downloading the file:', error);
      });
  }

  public isYoutube(document: ProjectDocument) {
    return document.mimeType.match('.*youtube');
  }

  private getFileExtension(mimeType: string): string {
    switch (mimeType) {
      case 'application/pdf':
        return 'pdf';
      case 'image/jpeg':
      case 'image/jpg':
        return 'jpg';
      case 'image/webp':
        return 'webp';
      case 'image/png':
        return 'png';
      case 'video/mp4':
        return 'mp4';
      case 'video/quicktime':
        return 'mov';
      default:
        return 'bin';
    }
  }

  private atLeastOneRequiredValidator(controls: string[]) {
    return (group: AbstractControl) => {
      const oneValid = controls.some((c) => !Validators.required(group.get(c)));
      if (!oneValid) {
        let existingError: ValidationErrors = { required: true };
        controls.forEach((c) => {
          if (group.get(c).errors !== null) {
            existingError = group.get(c).errors;
            return;
          }
          group.get(c).setErrors({ required: true });
        });
        return existingError;
      }
      controls.forEach((c) => group.get(c).setErrors(null));
      return null;
    };
  }
}
