<form
  [formGroup]="contactForm"
  (submit)="saveContact()"
  [project]="project"
  appFormChangeDetector
  appAccess
  role="manager"
  property="readonly"
  #formRef="ngForm"
  ngForm
>
  <div class="bouwapp-box__subheader">
    <p>{{ 'project.detail.contact.description' | translate }}</p>
  </div>
  <div class="project-contact form-group">
    <div class="project-contact__image">
      <app-form-group [form]="contactForm" errorPath="chatPlaceholderAvatar">
        <label>
          {{ 'projects.detail.general.conversations.image.title' | translate }}
        </label>
        <div class="project-contact__image__wrapper">
          <div
            class="project-contact__image__self"
            [class.hasImage]="getCurrentPlaceholderAvatar()"
            [style.background-image]="getCurrentPlaceholderAvatar()"
          >
            <app-input-file
              #chatPlaceholderAvatarInput
              (previewUpdated)="updatePlaceholderAvatarThumbnail($event)"
              [withDragDrop]="false"
              type="project-chat-placeholder-avatar"
              [uploadImages]="true"
              formControlName="chatPlaceholderAvatar"
              [large]="false"
              (startEditing)="startEditing($event)"
              (stopEditing)="stopEditing()"
              (startUploading)="inputFileStartUploading()"
              (stopUploading)="inputFileStopUploading($event)"
              [withEditor]="true"
            >
              <span inlineSVG="/assets/img/icons/photo--black.svg"></span>
            </app-input-file>
          </div>
          <div
            class="project-contact__image__buttons"
            *ngIf="contactForm.get('chatPlaceholderAvatar').value !== null"
          >
            <a (click)="edit()" class="primary" *ngIf="imageChangedEvent">
              <span
                class="icon"
                [inlineSVG]="'/assets/img/icons/pencil.svg'"
              ></span>
              <span>{{
                'projects.detail.general.conversations.image.crop' | translate
              }}</span>
            </a>
            <a (click)="deleteImage()" class="primary">
              <span
                class="icon"
                [inlineSVG]="'/assets/img/icons/trash.svg'"
              ></span>
              <span>{{
                'projects.detail.general.conversations.image.delete' | translate
              }}</span>
            </a>
          </div>
        </div>
      </app-form-group>
    </div>
    <div class="project-contact__first">
      <app-form-group [form]="contactForm" errorPath="chatPlaceholderName">
        <label for="chatName">{{
          'projects.detail.general.conversations.name.title' | translate
        }}</label>
        <input
          id="chatName"
          class="input input--m"
          [placeholder]="'projects.detail.general.tickets.name' | translate"
          formControlName="chatPlaceholderName"
          appAccess
          role="manager"
          [project]="project"
          property="disabled"
        />
      </app-form-group>
      <div class="bouwapp-group__item">
        <app-form-group
          [form]="contactForm"
          errorPath="chatPlaceholderPhoneNumber"
        >
          <label for="chatPlaceholderPhoneNumber">{{
            'smart_reporting.notice_create.phoneNumber' | translate
          }}</label>
          <angular-intl-phone
            #phoneComponent
            formControlName="chatPlaceholderPhoneNumber"
            [config]="phoneConfig"
          ></angular-intl-phone>
        </app-form-group>
      </div>
    </div>
    <div class="project-contact__second">
      <app-form-group [form]="contactForm" errorPath="chatPlaceholderFunction">
        <label for="chatRole">{{
          'projects.detail.general.conversations.role.title' | translate
        }}</label>
        <input
          id="chatRole"
          class="input input--m"
          [placeholder]="'projects.detail.general.tickets.function' | translate"
          formControlName="chatPlaceholderFunction"
          appAccess
          role="manager"
          [project]="project"
          property="disabled"
        />
      </app-form-group>
      <app-form-group [form]="contactForm" errorPath="chatEnabled">
        <label for="chatEnabled">{{
          'projects.detail.general.tickets.enabled' | translate
        }}</label>
        <select
          id="chatEnabled"
          [attr.title]="'projects.detail.general.status.title' | translate"
          class="select select--m status"
          formControlName="chatEnabled"
        >
          <option [ngValue]="true">
            {{ 'projects.detail.contact.chat.enabled' | translate }}
          </option>
          <option [ngValue]="false">
            {{ 'projects.detail.contact.chat.disabled' | translate }}
          </option>
        </select>
      </app-form-group>
    </div>
    <div class="project-contact__description">
      <app-form-group [form]="contactForm" errorPath="chatDescription">
        <label>{{
          'projects.detail.general.tickets.description.label' | translate
        }}</label>
        <app-quill
          [formGroup]="contactForm"
          [project]="!canEdit ? undefined : project"
          controlName="chatDescription"
          [language]="project.language ?? platform.language"
          [readOnly]="!canEdit"
          placeholder="{{
            'projects.detail.general.tickets.description.placeholder'
              | translate
          }}"
        ></app-quill>
      </app-form-group>
    </div>
  </div>
</form>

<app-image-cropbox
  [hidden]="!showEditor"
  [imageFormElement]="contactForm.get('chatPlaceholderAvatar')"
  [imageInput]="chatPlaceholderAvatarInput"
  [imageChangedEvent]="imageChangedEvent"
  [aspectRatio]="1"
  [editing]="editImage"
  [startUploadingEvent]="startUploading"
  [stopUploadingEvent]="stopUploading"
  (editorClosed)="stopEditing()"
></app-image-cropbox>
