<app-loader *ngIf="!customer"></app-loader>

<app-success-message [hidden]="!formSaved" [onScreen]="true">
  {{ 'profile.company.success' | translate }}
</app-success-message>

<app-sticky-submit [title]="'profile.company.title'">
  <button
    class="button button--m margin primary"
    (click)="save()"
    [appLoading]="loading || uploading"
  >
    {{ 'profile.company.save' | translate }}
  </button>
</app-sticky-submit>

<div class="container">
  <div class="bouwapp-header">
    <div class="bouwapp-header__text">
      <div class="bouwapp-header__text__title">
        <h1>{{ 'profile.company.title' | translate }}</h1>
        <app-customer-picker
          (selectedCustomer)="changeCustomer($event)"
        ></app-customer-picker>
      </div>
    </div>
    <div class="bouwapp-header__buttons">
      <button
        (click)="save()"
        type="submit"
        class="primary"
        [appLoading]="loading || uploading"
      >
        {{ 'profile.company.save' | translate }}
      </button>
    </div>
  </div>

  <form appFormChangeDetector #formRef="ngForm" ngForm [formGroup]="form">
    <div class="bouwapp-group">
      <div class="bouwapp-group__header">
        <h2>{{ 'profile.company.general.title' | translate }}</h2>
      </div>

      <div class="bouwapp-group__row two">
        <div class="bouwapp-group__item">
          <app-form-group [form]="form" errorPath="logo">
            <label>{{ 'profile.company.general.logo' | translate }}</label>
            <div
              class="bouwapp-group__item__media"
              [style.backgroundImage]="
                logoPreview ? 'url(' + logoPreview + ')' : null
              "
            >
              <app-input-file-v2
                type="customer-logo"
                [selected]="logoPreview"
                [standalone]="true"
                [uploadImages]="true"
                (uploadStart)="addLogo($event)"
                (uploadFinished)="updateLogo($event)"
                (uploadFailed)="uploadError($event)"
                (fileRemoved)="removeLogo($event)"
              ></app-input-file-v2>
            </div>
            <div class="error" *ngIf="errorMessage">
              <span>{{ errorMessage }}</span>
            </div>
          </app-form-group>
        </div>
      </div>

      <div class="bouwapp-group__row three">
        <div class="bouwapp-group__item">
          <app-form-group [form]="form" errorPath="name">
            <label for="name">{{
              'profile.company.general.name' | translate
            }}</label>
            <input type="text" id="name" formControlName="name" />
          </app-form-group>
        </div>
        <div class="bouwapp-group__item">
          <app-form-group [form]="form" errorPath="email">
            <label for="email">{{
              'profile.company.contact.email' | translate
            }}</label>
            <input type="email" id="email" formControlName="email" />
          </app-form-group>
        </div>
        <div class="bouwapp-group__item">
          <app-form-group [form]="form" errorPath="phoneNumber">
            <label for="phoneNumber">{{
              'profile.company.contact.phone' | translate
            }}</label>
            <input type="text" id="phoneNumber" formControlName="phoneNumber" />
          </app-form-group>
        </div>
      </div>

      <div class="bouwapp-group__row three">
        <div class="bouwapp-group__item flex">
          <app-form-group [form]="form" errorPath="zipcode" cssClass="zipcode">
            <label for="zipcode">{{
              'profile.company.contact.zip_code' | translate
            }}</label>
            <input type="text" id="zipcode" formControlName="zipcode" />
          </app-form-group>
          <app-form-group
            [form]="form"
            errorPath="streetNumber"
            cssClass="street-number"
          >
            <label for="streetNumber">{{
              'profile.company.contact.number' | translate
            }}</label>
            <input
              type="text"
              id="streetNumber"
              formControlName="streetNumber"
            />
          </app-form-group>
        </div>
        <div class="bouwapp-group__item">
          <app-form-group [form]="form" errorPath="street">
            <label for="street">{{
              'profile.company.contact.street' | translate
            }}</label>
            <input type="text" id="street" formControlName="street" />
          </app-form-group>
        </div>
        <div class="bouwapp-group__item">
          <app-form-group [form]="form" errorPath="city">
            <label for="city">{{
              'profile.company.contact.city' | translate
            }}</label>
            <input type="text" id="city" formControlName="city" />
          </app-form-group>
        </div>
      </div>

      <div class="bouwapp-group__row">
        <div class="bouwapp-group__item">
          <app-form-group [form]="form" errorPath="description">
            <label for="description">{{
              'profile.company.general.description' | translate
            }}</label>
            <app-quill
              [formGroup]="form"
              [language]="language"
              controlName="description"
            ></app-quill>
          </app-form-group>
        </div>
      </div>
    </div>
  </form>
</div>
