import { Component, EventEmitter, Output, input, output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ErrorService } from 'src/app/services/error.service';
import { FormGroupComponent } from '../form-group.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingDirective } from 'src/app/directives/loading.directive';
import { ProjectUser } from 'src/app/interfaces/project-user';

@Component({
  selector: 'app-two-factor-method-switcher',
  templateUrl: './two-factor-method-switcher.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormGroupComponent,
    NgSelectModule,
    TranslateModule,
    LoadingDirective,
  ],
})
export class TwoFactorMethodSwitcher {
  readonly projectUser = input<ProjectUser>();
  readonly standalone = input<boolean>(true);
  readonly methodChange = output<EventEmitter<string>>();
  readonly formSubmitted = output<EventEmitter<any>>();

  public methods: any = [
    { value: 'sms', name: 'sms' },
    { value: 'email', name: 'email' },
    { value: null, name: 'ask_me' },
  ];
  public form: FormGroup;
  public loading: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
  ) {}

  ngOnInit() {
    const validators = this.standalone() ? [Validators.required] : [];
    this.form = this.formBuilder.group({
      method: [null, validators],
      save: [false, []],
    });

    const initialValue = this.projectUser()?.twoFactorMethod;
    if (initialValue) {
      this.form.get('method')?.setValue(initialValue);
    }

    this.form.get('method')?.valueChanges.subscribe((value) => {
      this.methodChange.emit(value);
    });

    this.loading = false;
  }

  public async savePreferedMethod() {
    this.loading = true;
    this.errorService.markFormGroupTouchedAndDirty(this.form);

    if (this.form.valid) {
      this.formSubmitted.emit(this.form.value);
    }
    this.loading = false;
  }
}
