import { Component, input, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { DatePickerComponent } from '../../../../components/date-picker.component';
import { FormGroupComponent } from '../../../../components/form-group.component';
import { DatePickerV2Component } from 'src/app/components/date-picker-v2.component';

@Component({
  selector: 'app-detail-general-planning',
  templateUrl: './detail-general-planning.component.html',
  styles: [],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormGroupComponent,
    DatePickerComponent,
    NgIf,
    DatePickerV2Component,
    TranslateModule,
  ],
})
export class DetailGeneralPlanningComponent {
  readonly dateRangeValid = input<boolean>();
  readonly generalForm = input<FormGroup>();
  readonly withoutText = input<boolean>(false);
  public showPeriod: boolean = false;
}
