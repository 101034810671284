<div
  class="avatar"
  [ngClass]="
    projectUser().avatarThumbnails
      ? ''
      : 'avatar__background__' + projectUser().firstName[0].toLowerCase()
  "
>
  @if (projectUser().avatarThumbnails) {
    <img [src]="projectUser().avatarThumbnails.small" />
  } @else {
    {{
      projectUser().firstName[0] + projectUser().lastName.split(' ').pop()[0]
    }}
  }
</div>
