import { Injectable } from '@angular/core';
import { Ticket } from '../interfaces/ticket';
import { firstValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { TicketMessage } from '../interfaces/ticket-message';
import { LocalStorageService } from 'ngx-webstorage';
import * as moment from 'moment';
import { Project } from '../interfaces/project';
import { ProjectService } from './project.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CollectionResponse } from '../interfaces/collection-response';
import { environment } from '../../environments/environment';
import { tick } from '@angular/core/testing';

@Injectable()
export class TicketService {
  private static CONVERSATION_KEY = 'conversations';
  private static OPEN_TICKETS_KEY = 'openTickets';
  private currentRequest: Subscription = null;
  private countRequest: Subscription = null;

  static base = '/tickets';
  static baseMessages = '/ticket-messages';

  private openedTicket: Subject<Ticket> = new Subject<Ticket>();
  public ticketOpened = this.openedTicket.asObservable();

  private closedTicket: Subject<Ticket> = new Subject<Ticket>();
  public ticketClosed = this.closedTicket.asObservable();

  private ticketStatus: Subject<void> = new Subject<void>();
  public ticketStatusChanged = this.ticketStatus.asObservable();

  private ticketTypeUpdateRequest: Subscription;

  constructor(
    private http: HttpClient,
    private storage: LocalStorageService,
  ) {}

  /**
   * @param {Project} project
   * @param {string} sort
   * @param {string[]} status
   * @param {string|null} search
   * @returns {Promise<Ticket[]>}
   */
  public list(
    project: Project = null,
    filterForm: any,
    statuses: string[],
    page: number,
    limit: number = 100,
    projectIds?: number[],
  ): Promise<CollectionResponse<Ticket>> {
    return new Promise((resolve, reject) => {
      let params: HttpParams = new HttpParams();

      if (filterForm?.sort?.field) {
        params = params.set(
          'order[' + filterForm.sort.field + ']',
          filterForm.sort.direction,
        );
      }

      if (projectIds != null && projectIds.length > 0) {
        projectIds.forEach(
          (s, i) => (params = params.set('project[' + i + ']', s)),
        );
      }

      params = params.set('page', String(page));
      params = params.set('perPage', String(limit));
      statuses.forEach((s, i) => {
        params = params.set('status[' + i + ']', s);
      });

      if (filterForm.search !== '') {
        params = params.set('name', filterForm.search);
      }
      if (filterForm.startDate !== '') {
        params = params.set('startDate', filterForm.startDate);
      }

      if (filterForm.endDate !== '') {
        params = params.set('endDate', filterForm.endDate);
      }

      if (this.currentRequest != null) {
        this.currentRequest.unsubscribe();
      }

      const observable =
        project != null
          ? this.http.get<CollectionResponse<Ticket>>(
              environment.apiUrl +
                ProjectService.base +
                '/' +
                project.slug +
                '/tickets',
              { params },
            )
          : this.http.get<CollectionResponse<Ticket>>(
              environment.apiUrl + TicketService.base,
              { params },
            );

      this.currentRequest = observable.subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  public downloadCsv() {
    return this.http
      .get(environment.apiUrl + TicketService.base + '/export', {
        responseType: 'text',
      })
      .toPromise();
  }

  /**
   * @param {Ticket} ticket
   * @param {string} type
   * @param {Date} date
   * @param {number} limit
   * @param {string} sort
   * @returns {Promise<TicketMessage[]>}
   */
  public messages(
    ticket: Ticket,
    type: 'all' | 'before' | 'after',
    date: Date = null,
    limit: number = 5,
    sort: string = 'desc',
  ): Promise<CollectionResponse<TicketMessage>> {
    let params: HttpParams = new HttpParams();

    let customParams = ''; // there is a bug with + signs used in URLSearchParams so that backends will read it as a space, and its urlencoded equivalent will be encoded twice

    params = params.set('order[createdAt]', sort);
    params = params.set('page', String(1));

    switch (type) {
      case 'all':
        params = params.set('perPage', String(limit));
        break;
      case 'before':
        customParams =
          '?createdAt[strictly_before]=' +
          moment(date).format().replace(/\+/g, '%2B');
        params = params.set('perPage', String(limit));
        break;
      case 'after':
        customParams =
          '?createdAt[strictly_after]=' +
          moment(date).format().replace(/\+/g, '%2B');
        break;
    }

    return this.http
      .get<
        CollectionResponse<TicketMessage>
      >(environment.apiUrl + TicketService.base + '/' + ticket.id + '/messages' + customParams, { params })
      .toPromise();
  }

  public getStatusCount(filterForm: any, projectIds?: number[]): Promise<any> {
    return new Promise((resolve, reject) => {
      let params: HttpParams = new HttpParams();

      if (projectIds != null && projectIds.length > 0) {
        projectIds.forEach(
          (s, i) => (params = params.set('project[' + i + ']', s)),
        );
      }

      if (filterForm.search !== '') {
        params = params.set('name', filterForm.search);
      }

      if (filterForm.startDate !== '') {
        params = params.set('startDate', filterForm.startDate);
      }

      if (filterForm.endDate !== '') {
        params = params.set('endDate', filterForm.endDate);
      }

      if (this.countRequest != null) {
        this.countRequest.unsubscribe();
      }

      const observable = this.http.get(
        environment.apiUrl + TicketService.base + '/counts',
        {
          params,
        },
      );

      this.countRequest = observable.subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  /**
   * @param ticket
   * @param message
   * @param status
   * @param statusOnly
   */
  public async reply(
    ticket: Ticket,
    message: TicketMessage,
    status: string,
    statusOnly = false,
  ) {
    if (!statusOnly) {
      const data: any = await this.http
        .post(environment.apiUrl + TicketService.baseMessages, {
          ticket: '/api' + TicketService.base + '/' + ticket.id,
          attachment: message.attachment,
          content: message.content,
        })
        .toPromise();

      message.id = data.id;
    }

    ticket.status = <any>status;
    await this.http
      .put(
        environment.apiUrl + TicketService.base + '/' + ticket.id + '/status',
        {
          status,
        },
      )
      .toPromise();

    this.ticketStatus.next();
  }

  /**
   * @param ticket
   * @param type
   */
  public updateType(ticket: Ticket, type: string) {
    if (this.ticketTypeUpdateRequest != null) {
      this.ticketTypeUpdateRequest.unsubscribe();
    }

    return new Promise(async (resolve, reject) => {
      this.ticketTypeUpdateRequest = this.http
        .put(
          environment.apiUrl + TicketService.base + '/' + ticket.id + '/type',
          { type },
        )
        .subscribe(
          (value) => {
            resolve(value);
          },
          (error) => reject(error),
        );
    });
  }

  public async retryDialogSync(ticket: Ticket) {
    return await this.http
      .put(
        environment.apiUrl +
          TicketService.base +
          '/' +
          ticket.id +
          '/dialog/requeue',
        {},
      )
      .toPromise();
  }

  /**
   * @param ticket
   */
  public requestFeedback(ticket: Ticket) {
    return this.http
      .put(
        environment.apiUrl +
          TicketService.base +
          '/' +
          ticket.id +
          '/request-feedback',
        {
          requestedFeedback: true,
        },
      )
      .toPromise();
  }

  /**
   * Count open tickets, and first return the cached amount
   * @returns {Observable<number>}
   */
  public countOpenTickets() {
    return Observable.create(async (observer) => {
      const openTickets: number = await this.storage.retrieve(
        TicketService.OPEN_TICKETS_KEY,
      );

      if (openTickets != null) {
        observer.next(openTickets);
      }

      let count: number = (<any>(
        await this.http
          .get(environment.apiUrl + TicketService.base + '/open/count')
          .toPromise()
      )).count;
      count = count == null ? 0 : count;

      await this.storage.store(TicketService.OPEN_TICKETS_KEY, count);
      observer.next(count);
    });
  }

  /**
   * Returns status and feedback property
   * @returns {Promise<Ticket>}
   */
  public async status(ticket: Ticket) {
    return <any>(
      await this.http
        .get(
          environment.apiUrl + TicketService.base + '/' + ticket.id + '/status',
        )
        .toPromise()
    );
  }

  /**
   * Opens a ticket
   * @param ticket
   */
  public async openTicket(ticket: Ticket) {
    const tickets = await this.getOpenTickets();

    if (tickets.find((item) => item.id === ticket.id) == null) {
      tickets.push(ticket);
      await this.storeOpenTickets(tickets);

      this.openedTicket.next(ticket);
    }
  }

  /**
   * Close a ticket
   * @param ticket
   */
  public async closeTicket(ticket: Ticket) {
    const tickets = (await this.getOpenTickets()).filter(
      (item) => item.id !== ticket.id,
    );
    await this.storeOpenTickets(tickets);

    this.closedTicket.next(ticket);
  }

  /**
   * Create a ticket
   * @param data
   */
  public async createTicket(data: any): Promise<Ticket> {
    return await this.http
      .post<Ticket>(environment.apiUrl + TicketService.base + '/me', data)
      .toPromise();
  }

  /**
   * Update the name for a ticket message
   *
   * @param ticket
   * @param ticketMessage
   */
  public async updateTicketMessageName(
    ticket: Ticket,
    ticketMessage: TicketMessage,
  ): Promise<TicketMessage> {
    return await this.http
      .put<TicketMessage>(
        environment.apiUrl +
          TicketService.base +
          '/' +
          ticket.id +
          '/message/' +
          ticketMessage.id +
          '/name',
        ticketMessage,
      )
      .toPromise();
  }

  /**
   * Update a ticket
   * @param ticket
   */
  public async updateTicket(ticket: Ticket) {
    const tickets = await this.getOpenTickets();

    if (ticket.messages) {
      // disable animation for all saved tickets, and don't add loading tickets
      for (const message of ticket.messages) {
        message.animate = false;
      }
    } else {
      ticket.messages = [];
    }

    tickets[tickets.findIndex((item) => item.id === ticket.id)] = ticket;

    await this.storeOpenTickets(tickets);
  }

  /**
   * Returns all tickets currently open
   * @returns {Promise<Ticket[]>}
   */
  public async getOpenTickets(): Promise<Ticket[]> {
    let tickets: Ticket[] = await this.storage.retrieve(
      TicketService.CONVERSATION_KEY,
    );

    if (tickets == null) {
      tickets = [];
    }

    return tickets;
  }

  public async detail(id: number): Promise<Ticket> {
    return await this.http
      .get<Ticket>(environment.apiUrl + TicketService.base + '/' + id)
      .toPromise();
  }

  /**
   * Returns all tickets currently open
   * @returns {Promise<any>}
   */
  private async storeOpenTickets(tickets: Ticket[]) {
    await this.storage.store(TicketService.CONVERSATION_KEY, tickets);
  }
}
