import { Component, input } from '@angular/core';
import { Ticket } from '../interfaces/ticket';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf, DatePipe } from '@angular/common';

@Component({
  selector: '[app-ticket]',
  templateUrl: 'ticket.component.html',
  standalone: true,
  imports: [NgIf, InlineSVGModule, DatePipe, TranslateModule],
})
export class TicketComponent {
  public readonly ticket = input<Ticket>();
  public readonly withProject = input<boolean>();
}
