export const environment = {
  production: true,
  environment: 'test',
  apiUrl: 'https://api.test.debouwapp.nl/api/v2',
  apiUrlV3: 'https://api.test.debouwapp.nl/api/v3',
  apiUrlV4: 'https://api.test.debouwapp.nl/api/v4',
  appBaseUrl: 'https://test.debouw.app',
  appUrl: 'https://test.debouw.app',
  productFruits: 'IoyVDhxznYkVoynJ',
  mapboxToken:
    'pk.eyJ1IjoiZnJhbmtlbmh1aXplbiIsImEiOiJja2Fuc3VpMDMwejR5MnNtdjdkYm4zejU5In0.EhW8O30-M2J4I2LVlW0hvw',
  sentry: {
    enabled: true,
    sentryDsn:
      'https://557a27bb6a8bceea3285a09199fd79f8@o4505843960315904.ingest.sentry.io/4505844904558592',
  },
  platforms: [
    {
      id: 'ba',
      title: 'BouwApp',
      host: 'beheer.test.debouwapp.nl',
      baseUrl: 'https://login.test.debouwapp.nl/',
      color: '#F5B049',
      logo: 'assets/img/logo_bouwapp.png',
      language: 'nl',
      favicon: 'assets/img/favicon_bouwapp.ico',
      clientId: '4ff7c9077fadd91113b58ae6791973fb',
    },
    {
      id: 'sp',
      title: 'SitePodium',
      host: 'portal.test.sitepodium.com',
      baseUrl: 'https://login.test.sitepodium.com/',
      color: '#F8A40D',
      logo: 'assets/img/logo_sitepodium.png',
      language: 'en',
      favicon: 'assets/img/favicon_sitepodium.ico',
      clientId: '3d54df7de68f34c8b54fae2688ec178e',
    },
  ],
};
