<div class="satisfaction-distribution">
  <div
    *ngFor="let score of [1, 2, 3, 4, 5]"
    class="satisfaction-distribution__score satisfaction-distribution__score--{{
      score
    }}"
    [style.width]="'20%'"
  >
    <div class="satisfaction-distribution__bar">{{ internalData[score] }}</div>
    <div
      [inlineSVG]="'/assets/img/icons/smiley' + score + '.svg'"
      class="satisfaction-distribution__score__image inline-svg__item"
    ></div>
  </div>
</div>
