import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Article } from 'src/app/interfaces/article';
import { BlogCategory } from 'src/app/interfaces/blog-category';
import { Sort } from 'src/app/interfaces/sort';
import { NewsService } from 'src/app/services/news.service';
import { LoaderComponent } from '../../../components/loader.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgIf, NgFor, NgStyle, NgClass, DatePipe } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CustomFilterItem } from 'src/app/interfaces/custom-filter-item';
import { CustomFilter } from 'src/app/interfaces/custom-filter';
import { CustomFilterType } from 'src/app/enums/custom-filter-type';
import { DataFilterComponent } from 'src/app/components/data-filter.component';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    InfiniteScrollDirective,
    NgStyle,
    DataFilterComponent,
    NgClass,
    InlineSVGModule,
    LoaderComponent,
    DatePipe,
    TranslateModule,
  ],
})
export class NewsComponent implements OnInit {
  articles: Article[];
  private categories: BlogCategory[] = [];
  categorySlugs: string[] | null;
  savedState: any;
  loading: boolean = false;

  public totalItems: number;
  private perPage = 8;
  private page = 0;
  private ready = false;

  sortItems: Sort[] = [
    {
      field: 'publishedAt',
      direction: 'desc',
      label: 'news.list.sort.direction.published.desc',
    },
    {
      field: 'publishedAt',
      direction: 'asc',
      label: 'news.list.sort.direction.published.asc',
    },
    {
      field: 'title',
      direction: 'desc',
      label: 'news.list.sort.direction.title.desc',
    },
    {
      field: 'title',
      direction: 'asc',
      label: 'news.list.sort.direction.title.asc',
    },
    {
      field: 'totalReads',
      direction: 'desc',
      label: 'news.list.sort.direction.totalReads.desc',
    },
    {
      field: 'totalReads',
      direction: 'asc',
      label: 'news.list.sort.direction.totalReads.asc',
    },
  ];

  public sort: Sort = this.sortItems[0];
  public search = null;

  constructor(
    private newsService: NewsService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private translateService: TranslateService,
    deviceDetectorService: DeviceDetectorService,
  ) {
    this.savedState = this.newsService.getSavedState();
    if (this.savedState) {
      this.categories = this.savedState.categories;
      this.page = this.savedState.page;
      this.sort = this.savedState.sort;
      this.search = this.savedState.search;
      this.articles = this.savedState.articles;
      this.totalItems = this.savedState.totalItems;
      this.ready = true;
    }
    if (deviceDetectorService.isDesktop()) {
      this.perPage *= 2;
    }
  }

  transformToFilters(): CustomFilter[] {
    const idFilters: CustomFilterItem[] = this.categories.map((category) => ({
      label: category.title,
      value: false,
      key: category.slug,
    }));

    return idFilters.length > 0
      ? [
          {
            title: 'news.list.filter.category.title',
            filters: idFilters,
            type: CustomFilterType.CHECKBOX,
          },
        ]
      : [];
  }

  onFilterChanged(event: any) {
    if (event.customFilters.length > 0) {
      this.categorySlugs = event.customFilters[0].filters
        .map((item, index) => (item.value ? this.categories[index].slug : null))
        .filter((slug) => !!slug);
    }

    if (event.sort) {
      this.sort = event.sort;
    }
    if (event.search) {
      this.search = event.search;
    }

    if (!this.ready) {
      return;
    }
    this.reset();
    this.loadArticles();
  }

  async ngOnInit(): Promise<void> {
    if (!this.savedState) this.loadCategories();
  }

  private loadCategories() {
    this.newsService.listCategoriesByType().then((categories: any) => {
      this.categories = categories;
      this.categories.push({
        title: this.translateService.instant('news.list.filter.favorite'),
        slug: 'favorite',
      });

      this.ready = true;
      this.loadArticles();
    });
  }

  async toggleFavorite(article: Article, event) {
    article.isFavorited = !article.isFavorited;
    event.preventDefault();
    event.stopPropagation();
    const result = await this.newsService.toggleFavorite(article.slug);
    if (result.action == 'ADDED') article.isFavorited = true;
    else if (result.action == 'REMOVED') article.isFavorited = false;
  }

  private reset() {
    this.page = 0;
    this.totalItems = undefined;
    this.articles = undefined;
  }

  async loadArticles() {
    this.loading = true;
    this.page += 1; // do before a request, so you won't get duplicates

    if (!this.articles || this.articles.length < this.totalItems) {
      const currentParams = this.currentParamHash;
      const response: any = await this.newsService.listByType(
        this.page,
        this.perPage,
        this.sort,
        this.search,
        this.categorySlugs,
      );
      const result: Article[] = response['hydra:member'];

      if (result != null && currentParams === this.currentParamHash) {
        if (!this.articles) {
          this.articles = [];
          this.totalItems = response['hydra:totalItems'];
        }
        this.articles = this.articles.concat(result);
      }
    }
    this.loading = false;
  }

  openArticle(article: Article) {
    const saveState = {
      article: article,
      articles: this.articles,
      currentParams: this.currentParamHash,
      page: this.page,
      perPage: this.perPage,
      sort: this.sort,
      search: this.search,
      categories: this.categories,
      categorySlugs: this.categorySlugs,
      totalItems: this.totalItems,
    };
    this.newsService.setSavedState(saveState);
    this.router.navigate(['/articles', article.slug]);
  }

  get currentParamHash() {
    return JSON.stringify([this.sort, this.search, this.categories]);
  }

  getImageByArticle(article: Article): SafeStyle | string {
    if (article.image == null) {
      return '';
    } else {
      return this.sanitizer.bypassSecurityTrustStyle(
        'url(' + article.imageThumbnails?.medium + ')',
      );
    }
  }
}
