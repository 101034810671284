import { Component } from '@angular/core';
import { SmartReportingNotice } from '../../../../../../interfaces/smart-reporting-notice';
import { Subscription } from 'rxjs';
import { SmartReportingNoticeStatus } from '../../../../../../enums/smart-reporting-notice-status';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { SmartReportingService } from '../../../../../../services/smart-reporting.service';
import { CsvService } from '../../../../../../services/csv.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment';
import { Project } from '../../../../../../interfaces/project';
import { LoaderComponent } from '../../../../../../components/loader.component';
import { AccessDirective } from '../../../../../../directives/access.directive';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { Sort } from 'src/app/interfaces/sort';
import { HttpParams } from '@angular/common/http';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { ProjectStatus } from 'src/app/enums/project-status';
import { CustomFilter } from 'src/app/interfaces/custom-filter';
import { CustomFilterItem } from 'src/app/interfaces/custom-filter-item';
import { CustomFilterType } from 'src/app/enums/custom-filter-type';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormGroupComponent } from 'src/app/components/form-group.component';
import { DataFilterComponent } from 'src/app/components/data-filter.component';

@Component({
  selector: 'app-smart-reporting-notices-table',
  templateUrl: './table.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    AccessDirective,
    FormGroupComponent,
    RouterLink,
    LoaderComponent,
    NgFor,
    LoaderComponent,
    DatePipe,
    TranslateModule,
    InfiniteScrollDirective,
    DataFilterComponent,
  ],
})
export class SmartReportingNoticesTableComponent {
  public notices: SmartReportingNotice[] = [];
  public SmartReportingNoticeStatus = SmartReportingNoticeStatus;
  private noticesSubscription: Subscription;
  private countsSubscription: Subscription;
  public projectSlug: string;
  public project: Project;
  public page: number = 1;
  public perPage: number = 25;
  public totalItems: number = 0;
  public statusCounts: number[];
  private formResult: any = {};
  public form: FormGroup;

  public loading: boolean = true;
  public onProjectPage: boolean = false;
  public projects: Project[];
  public sortItems: Sort[] = [
    {
      field: 'createdAt',
      direction: 'desc',
      label: 'smart_reporting.sort.direction.updated.descending',
    },
    {
      field: 'createdAt',
      direction: 'asc',
      label: 'smart_reporting.sort.direction.updated.ascending',
    },
    {
      field: 'status',
      direction: 'desc',
      label: 'smart_reporting.sort.direction.status.descending',
    },
    {
      field: 'status',
      direction: 'asc',
      label: 'smart_reporting.sort.direction.status.ascending',
    },
    {
      field: 'theme',
      direction: 'desc',
      label: 'smart_reporting.sort.direction.theme.descending',
    },
    {
      field: 'theme',
      direction: 'asc',
      label: 'smart_reporting.sort.direction.theme.ascending',
    },
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private smartReportingService: SmartReportingService,
    private csvService: CsvService,
    private translateService: TranslateService,
    private projectDataBusService: ProjectDataBusService,
    deviceDetectorService: DeviceDetectorService,
  ) {
    if (deviceDetectorService.isDesktop()) {
      this.perPage *= 2;
    }
    this.loadNotices();
    this.getStatusCounts();

    this.activatedRoute.parent.params.subscribe((params) => {
      if (!params['slug']) {
        this.onProjectPage = false;
      } else {
        this.onProjectPage = true;
      }

      this.projectDataBusService.shortListObservable.subscribe((shortList) => {
        if (this.onProjectPage) {
          return;
        }

        this.projects = shortList?.filter(
          (project) => project.status === ProjectStatus.PUBLISHED,
        );
      });

      this.projectDataBusService.projectObservable.subscribe((project) => {
        if (null === project || !this.onProjectPage) {
          return;
        }

        this.project = project;
        this.projects = [this.project];
      });
    });
  }

  public transformToFilters(): CustomFilter[] {
    const filters: CustomFilter[] = [];

    if (this.getStatuses().length > 0) {
      const statusFilters: CustomFilterItem[] = this.getStatuses().map(
        (status, index) => ({
          label: `smart_reporting.notices.status.${status}`,
          value: status !== SmartReportingNoticeStatus.DELETED,
          key: status,
          count: this.statusCounts ? this.statusCounts[status] : undefined,
        }),
      );

      filters.push({
        title: 'project.list.filter.status.title',
        filters: statusFilters,
        type: CustomFilterType.CHECKBOX,
      });
    }

    filters.push({
      title: 'project.list.filter.date.title',
      type: CustomFilterType.DATE,
      filters: [
        {
          label: 'project.list.filter.date.startdate',
          value: null,
          key: 'startDate',
        },
        {
          label: 'project.list.filter.date.enddate',
          value: null,
          key: 'endDate',
        },
      ],
    });

    if (this.projects.length > 1) {
      const projectFilters: CustomFilterItem[] = this.projects.map(
        (project) => ({
          label: project.name,
          value: false,
          key: project.id.toString(),
        }),
      );

      filters.push({
        title: 'smart_reporting.settings.form.projects',
        type: CustomFilterType.CHECKBOX,
        filters: projectFilters,
      });
    }

    return filters;
  }

  protected async loadNotices() {
    if (this.noticesSubscription) {
      this.noticesSubscription.unsubscribe();
    }

    this.loading = true;

    this.noticesSubscription = this.smartReportingService
      .listNotices(this.setUrlParams())
      .subscribe((response) => {
        response['hydra:member'].forEach((v) => {
          v.lastResponseDate =
            v.responses.length > 0
              ? v.responses[v.responses.length - 1].createdAt
              : null;
        });

        this.notices = this.notices.concat(response['hydra:member']);
        this.totalItems = response['hydra:totalItems'];
        this.page += 1;

        this.loading = false;
      });
  }

  public exportCsv() {
    return this.csvService
      .create(this.createCsv(), null, false)
      .download(`notices-export.csv`);
  }

  private createCsv(): any[] {
    const headerList = [
      'id',
      'createdAt',
      'status',
      'theme',
      'description',
      'attachment',
      'subscribers',
      'name',
      'phoneNumber',
      'email',
      'feedbackSolution',
      'feedbackProcess',
      'locationLat',
      'locationLong',
      'dialogue',
    ];

    const headers: any = {};

    headerList.forEach((item) => {
      headers[item] = this.translateService.instant(
        `smart_reporting.notices.export.${item}`,
      );
    });

    return this.notices.map((item) => {
      const object: any = {};

      object[headers['id']] = item.id;
      object[headers['createdAt']] = moment(item.createdAt).format(
        'DD-MM-YYYY HH:mm',
      );
      object[headers['status']] = item.status;
      object[headers['theme']] = item.theme ? item.theme.title : '';
      object[headers['description']] = '"' + item.description + '"';
      object[headers['attachment']] = item.attachment ? item.attachment : '';
      object[headers['subscribers']] = item.amountOfSubscribers;
      object[headers['name']] = item.name;
      object[headers['phoneNumber']] = item.phoneNumber;
      object[headers['email']] = item.email;
      object[headers['feedbackSolution']] = item.feedbackSolution;
      object[headers['feedbackProcess']] = item.feedbackProcess;
      object[headers['locationLat']] = item.locationLat;
      object[headers['locationLong']] = item.locationLong;
      object[headers['dialogue']] = this.translateService.instant(
        item.responses.length >= 2
          ? 'smart_reporting.notices.export.dialogue.yes'
          : 'smart_reporting.notices.export.dialogue.no',
      );

      return object;
    });
  }

  getStatuses() {
    return Object.values(SmartReportingNoticeStatus);
  }

  public async getStatusCounts() {
    if (this.countsSubscription) {
      this.countsSubscription.unsubscribe();
    }

    this.countsSubscription = this.smartReportingService
      .getStatusCounts(this.setUrlParams())
      .subscribe((response) => (this.statusCounts = response));
  }

  get activeStatuses(): SmartReportingNoticeStatus[] {
    const result: SmartReportingNoticeStatus[] = [];
    const value = this.form.value;

    if (value.open) {
      result.push(SmartReportingNoticeStatus.OPEN);
    }

    if (value.closed) {
      result.push(SmartReportingNoticeStatus.CLOSED);
    }

    if (value.deleted) {
      result.push(SmartReportingNoticeStatus.DELETED);
    }

    if (value.inProgress) {
      result.push(SmartReportingNoticeStatus.IN_PROGRESS);
    }

    return result;
  }

  private setUrlParams(): HttpParams {
    let params = new HttpParams();

    if (this.page && this.perPage) {
      params = params.set('page', this.page).set('perPage', this.perPage);
    } else {
      params = params.set('pagination', false);
    }

    let projectIds;
    projectIds = this.project ? [this.project.id] : this.formResult?.projects;

    if (projectIds && projectIds.length > 0) {
      projectIds.forEach((id) => {
        params = params.append('project[]', id);
      });
    }

    if (this.formResult?.statuses && this.formResult?.statuses.length > 0) {
      this.formResult?.statuses.forEach((status) => {
        params = params.append('status[]', status);
      });
    }

    if (this.formResult?.search) {
      params = params.set('search', this.formResult?.search);
    }

    if (this.formResult?.startDate) {
      params = params.set(
        'createdAt[strictly_after]',
        moment(this.formResult.startDate).format(),
      );
    }

    if (this.formResult?.endDate) {
      params = params.set(
        'createdAt[strictly_before]',
        moment(this.formResult.endDate).format(),
      );
    }

    params = params.set(
      `order[${this.formResult?.sort?.field}]`,
      this.formResult?.sort?.direction,
    );
    return params;
  }

  updateFilter(data: any) {
    this.page = 1;
    this.notices = [];
    const formResult: any = {};
    formResult.search = data.search;
    formResult.sort = data.sort;
    formResult.statuses =
      data.customFilters
        .find(
          (f) =>
            f.title === 'project.list.filter.status.title' &&
            f.type === CustomFilterType.CHECKBOX,
        )
        ?.filters.filter((filter) => filter.value)
        .map((filter) => filter.key) || [];

    const dateFilters = data.customFilters.find(
      (f) =>
        f.title === 'project.list.filter.date.title' &&
        f.type === CustomFilterType.DATE,
    );

    formResult.startDate =
      dateFilters?.filters.find((f) => f.key === 'startDate')?.value || '';
    formResult.endDate =
      dateFilters?.filters.find((f) => f.key === 'endDate')?.value || '';

    formResult.projects =
      data.customFilters
        .find(
          (f) =>
            f.title === 'smart_reporting.settings.form.projects' &&
            f.type === CustomFilterType.CHECKBOX,
        )
        ?.filters.filter((filter) => filter.value)
        .map((filter) => parseInt(filter.key)) || [];
    this.formResult = formResult;

    if (this.projects || this.project) {
      this.getStatusCounts();
      this.loadNotices();
    }
  }
}
