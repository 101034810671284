import { Component, ViewChild } from '@angular/core';
import { Project } from '../../../../../interfaces/project';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { RouterLink, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from '../../../../../components/loader.component';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { SmartReportingNoticesTableComponent } from './table/table.component';
import { AccessDirective } from 'src/app/directives/access.directive';
import { InlineSVGModule } from 'ng-inline-svg-2';

@Component({
  selector: 'app-smart-reporting-notices',
  templateUrl: './notices.component.html',
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    NgFor,
    NgClass,
    RouterLink,
    RouterOutlet,
    TranslateModule,
    SmartReportingNoticesTableComponent,
    AccessDirective,
    InlineSVGModule,
  ],
})
export class SmartReportingNoticesComponent {
  public static readonly LAST_PROJECT_KEY = 'lastProject';
  public projectList: Project[];
  public activeProjectSlug: string;

  @ViewChild(SmartReportingNoticesTableComponent) public noticesTable;

  constructor(private sanitizer: DomSanitizer) {}

  public getCoverImageByProject(project: Project): SafeStyle | string {
    if (project.coverImage == null) {
      return '';
    } else {
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        project.coverImageThumbnails?.medium,
      );
    }
  }

  public exportCsv() {
    this.noticesTable.exportCsv();
  }
}
