import { Component, Input, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Customer } from 'src/app/interfaces/customer';
import { Project } from 'src/app/interfaces/project';
import { ErrorService } from 'src/app/services/error.service';
import { ModalComponent } from '../modal.component';
import { ModuleService } from 'src/app/services/module.service';
import { Module } from 'src/app/enums/module';
import { TranslateModule } from '@ngx-translate/core';
import { FormGroupComponent } from '../form-group.component';
import { NgForOf, NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-update-picker',
  templateUrl: 'update-picker.component.html',
  standalone: true,
  imports: [
    ModalComponent,
    NgIf,
    NgForOf,
    TranslateModule,
    ReactiveFormsModule,
    FormGroupComponent,
    NgSelectModule,
  ],
})
export class UpdatePickerComponent {
  @Input() projects: Project[];
  @Input() project: Project;
  @Input() customers: Customer[];

  @ViewChild('newUpdate', { static: true }) private newUpdate: ModalComponent;

  public newUpdateForm: FormGroup;
  public radioOptions: Array<Object> = [
    {
      name: 'project',
      id: 'project-option',
      checked: true,
    },
    {
      name: 'organisation',
      id: 'organisation-option',
      checked: false,
    },
  ];

  constructor(
    private errorService: ErrorService,
    private router: Router,
    private formBuilder: FormBuilder,
    private moduleService: ModuleService,
  ) {}

  ngOnInit() {
    this.createNewUpdateForm();
  }

  private createNewUpdateForm() {
    this.newUpdateForm = this.formBuilder.group({
      entity: [null, Validators.required],
      optionType: ['project-option', Validators.required],
    });
  }

  createUpdate() {
    this.errorService.markFormGroupTouchedAndDirty(this.newUpdateForm);

    const pathPart =
      this.newUpdateForm.value.optionType === 'project-option'
        ? 'projects'
        : 'customers';
    if (this.newUpdateForm.valid) {
      this.router.navigate([
        '/' + pathPart,
        this.newUpdateForm.value.entity,
        'updates',
        'new',
      ]);
    }
  }

  pick() {
    this.customers = this.customers.filter((customer) =>
      this.moduleService.hasModule(customer, Module.STANDALONE_UPDATES),
    );
    this.newUpdate.open();
  }
}
