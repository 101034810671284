<div class="poll">
  <div class="poll__title">
    <span class="poll__title__text">
      <span inlineSVG="/assets/img/icons/poll.svg"></span>
      <span>{{ 'poll.title' | translate }}</span>
      <span> : {{ poll.title }} </span>
      <span>
        ({{
          (totalVotes === 1 ? 'poll.votes.singular' : 'poll.votes.plural')
            | translate: { votes: totalVotes }
        }})
      </span>
    </span>
  </div>
  <div class="poll__choices">
    <div
      class="poll__choice"
      *ngFor="let choice of poll.choices; let index = index"
    >
      <img
        [src]="choice.filePathThumbnails?.medium"
        *ngIf="choice.filePath != null"
      />
      <div *ngIf="!choice.filePath" class="placeholder"></div>
      <div class="poll__choice__result">
        <span class="poll__choice__result__percentage">
          {{ choice.percentage }}%
          {{ 'poll.votes.percentage' | translate }}
        </span>
        <span class="poll__choice__result__label">
          {{ choice.text }}
        </span>
      </div>
    </div>
  </div>
</div>
