import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { ProjectUser } from 'src/app/interfaces/project-user';

@Component({
  selector: 'app-avatar',
  templateUrl: 'avatar.component.html',
  standalone: true,
  imports: [NgClass],
})
export class AvatarComponent {
  public projectUser = input<ProjectUser>();
}
