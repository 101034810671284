<div class="bouwapp-header">
  <div class="bouwapp-header__text">
    <h1>{{ 'projects.detail.feedback.list.title' | translate }}</h1>
    <p *ngIf="surveys?.length">
      {{ 'projects.detail.feedback.list.description' | translate }}
    </p>
    <p *ngIf="!surveys?.length">
      {{ 'projects.detail.feedback.list.empty' | translate }}
    </p>
  </div>
  <div class="bouwapp-header__buttons">
    <a
      routerLink="new"
      class="secondary"
      appAccess
      role="manager"
      [project]="project"
    >
      <span class="icon" [inlineSVG]="'/assets/icons/add--white.svg'"></span>
      <span>{{ 'projects.detail.feedback.list.create' | translate }}</span></a
    >
  </div>
</div>
<app-data-filter
  (filterChanged)="onFilterChanged($event)"
  [sortItems]="sortItems"
  [customFilters]="transformToFilters()"
  [searchLabel]="'projects.detail.feedback.list.search.placeholder'"
  [sortLabel]="'news.list.sort.label'"
>
  <div class="feedback" *ngIf="surveys">
    <p *ngIf="surveys && surveys.length" class="feedback__count">
      {{
        'projects.detail.feedback.list.count'
          | translate: { tickets: surveys.length }
      }}
    </p>
    <div class="feedback__list" *ngIf="surveys.length > 0">
      <table>
        <thead>
          <tr>
            <th
              [attr.data-th]="'project.detail.feedback.list.status' | translate"
            >
              {{ 'project.detail.feedback.list.status' | translate }}
            </th>
            <th
              [attr.data-th]="'project.detail.feedback.list.name' | translate"
            >
              {{ 'project.detail.feedback.list.name' | translate }}
            </th>
            <th
              [attr.data-th]="
                'project.detail.feedback.list.submissions' | translate
              "
            >
              {{ 'project.detail.feedback.list.submissions' | translate }}
            </th>
            <th
              [attr.data-th]="'project.detail.feedback.list.push' | translate"
            >
              {{ 'project.detail.feedback.list.push' | translate }}
            </th>
            <th
              [attr.data-th]="
                'project.detail.feedback.list.created_on' | translate
              "
            >
              {{ 'project.detail.feedback.list.created_on' | translate }}
            </th>
            <th
              [attr.data-th]="
                'project.detail.feedback.list.created_by' | translate
              "
            >
              {{ 'project.detail.feedback.list.created_by' | translate }}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let survey of filteredSurveys
                | orderBy: filterForm.get('order').value
                | filterBy: ['status'] : filterForm.get('filter').value : true
                | filterBy: ['title'] : filterForm.get('search').value
            "
          >
            <td
              [ngClass]="[getStatusClass(survey)]"
              [attr.data-th]="'project.detail.feedback.list.status' | translate"
            >
              <span
                class="label label-info ng-star-inserted"
                [ngClass]="[getStatusClass(survey)]"
                >{{ survey.status | translate }}</span
              >
            </td>
            <td
              [attr.data-th]="'project.detail.feedback.list.name' | translate"
            >
              <span
                routerLink="/projects/{{ project.slug }}/feedback/{{
                  survey.id
                }}/edit"
                >{{ survey.title }}</span
              >
              <div class="feedback__list__hidden">
                <span *ngIf="survey.submittedCount === 1">{{
                  'project.detail.feedback.list.submissions.singular'
                    | translate: { submissions: survey.submittedCount }
                }}</span>
                <span *ngIf="survey.submittedCount !== 1">{{
                  'project.detail.feedback.list.submissions.plural'
                    | translate: { submissions: survey.submittedCount }
                }}</span>
              </div>
            </td>
            <td
              [attr.data-th]="
                'project.detail.feedback.list.submissions' | translate
              "
            >
              {{ survey.submittedCount }}
            </td>
            <td
              [attr.data-th]="'project.detail.feedback.list.push' | translate"
            >
              <span *ngIf="!survey.pushedAt">-</span>
              <ng-container *ngIf="survey.pushedAt">
                <span class="date">{{
                  survey.pushedAt | date: 'mediumDate'
                }}</span>
                <span class="time">{{
                  survey.pushedAt | date: 'shortTime'
                }}</span>
              </ng-container>
            </td>
            <td
              [attr.data-th]="
                'project.detail.feedback.list.created_on' | translate
              "
            >
              <span class="date">{{
                survey.createdAt | date: 'mediumDate'
              }}</span>
              <span class="time">{{
                survey.createdAt | date: 'shortTime'
              }}</span>
            </td>
            <td
              [attr.data-th]="
                'project.detail.feedback.list.created_by' | translate
              "
            >
              @if (survey.createdBy) {
                {{ survey.createdBy.fullName }}
              } @else {
                {{ project.customer.name }}
              }
            </td>
            <td
              [attr.data-th]="
                'project.detail.feedback.list.actions' | translate
              "
            >
              <div class="tile-actions">
                <a
                  class="tile-action tile-delete inline-svg__item"
                  (click)="
                    confirmDeleteSurvey.confirmDelete(survey);
                    $event.stopPropagation();
                    (false)
                  "
                  appAccess
                  role="manager"
                  [project]="project"
                >
                  {{ 'smart_reporting.delete' | translate }}
                </a>
                <a
                  routerLink="/projects/{{ project.slug }}/feedback/{{
                    survey.id
                  }}/edit"
                  class="tile-action tile-edit inline-svg__item"
                  appAccess
                  role="manager"
                  [project]="project"
                >
                  {{ 'smart_reporting.edit' | translate }}
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</app-data-filter>

<app-confirm-delete
  #confirmDeleteSurvey
  (confirmed)="deleteSurvey($event)"
></app-confirm-delete>
