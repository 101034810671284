<div class="audit-log container">
  <div class="bouwapp-header">
    <div class="bouwapp-header__text">
      <div class="bouwapp-header__text__title">
        <h1>{{ 'organisation.log.title' | translate }}</h1>
        <app-customer-picker
          (selectedCustomer)="changeCustomer($event)"
        ></app-customer-picker>
      </div>
    </div>
  </div>

  <app-data-filter
    *ngIf="ready"
    (filterChanged)="updateFilter($event)"
    [customFilters]="customFilters"
    [sortItems]="sortItems"
    [hasShowMore]="true"
    [sortLabel]="'project.list.sort'"
  >
    <div class="audit-log__content">
      <div
        class="audit-log__wrapper"
        infiniteScroll
        (scrolled)="loadLogEntries()"
        [infiniteScrollContainer]="'div.app__content'"
        [infiniteScrollDisabled]="logEntries?.length == totalLogEntries"
        [fromRoot]="true"
      >
        <p *ngIf="logEntries && logEntries.length">
          {{
            'organisation.log.results.title'
              | translate: { total: totalLogEntries }
          }}
        </p>
        <div class="audit-log__list">
          <div
            [hidden]="logEntries.length === 0"
            class="audit-log__wrapper__content"
          >
            <ul class="head">
              <li>
                <span>
                  {{ 'organisation.log.table.date' | translate }}
                </span>
                <span>
                  {{ 'organisation.log.table.user' | translate }}
                </span>
                <span>
                  {{ 'organisation.log.table.category' | translate }}
                </span>
                <span>
                  {{ 'organisation.log.table.event' | translate }}
                </span>
                <span>
                  {{ 'organisation.log.table.project' | translate }}
                </span>
                <span>
                  {{ 'organisation.log.table.target' | translate }}
                </span>
              </li>
            </ul>

            <ul class="body">
              <li *ngFor="let logEntry of logEntries">
                <span>
                  {{ logEntry.executedAt | date: 'medium' }}
                </span>
                <span>
                  {{
                    (logEntry.projectUser?.firstName || '') +
                      ' ' +
                      (logEntry.projectUser?.lastName || '')
                  }}
                </span>
                <span>
                  {{ logEntry.entityType }}
                </span>
                <span>
                  {{ logEntry.event }}
                </span>
                <span>
                  {{
                    (logEntry.project?.id || '') +
                      ' - ' +
                      (logEntry.project?.name || '')
                  }}
                </span>
                <span>
                  {{ logEntry.target }}
                </span>
              </li>
            </ul>
          </div>
          <app-loader *ngIf="loading"></app-loader>
        </div>
      </div>
    </div>
  </app-data-filter>
</div>
