import { Injectable } from '@angular/core';
import { Target } from '../interfaces/target';
import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { ProjectService } from './project.service';
import { Project } from '../interfaces/project';
import { CollectionResponse } from '../interfaces/collection-response';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class TargetService {
  static base = '/targets';
  static storeKey = 'targets';

  public updated$ = new BehaviorSubject<void>(null);

  constructor(
    private http: HttpClient,
    private storageService: LocalStorageService
  ) {}

  public list(project: Project): Observable<Target[]> {
    return Observable.create((observer: Observer<Target[]>) => {
      const stored = this.storageService.retrieve(this.createStorageKey());
      if (stored != null) {
        observer.next(stored);
      }

      this.fetchGlobal(project)
        .then((data: Target[]) => {
          observer.next(data);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  public listProject(project: Project): Observable<Target[]> {
    return Observable.create((observer: Observer<Target[]>) => {
      this.fetchProject(project)
        .then((data: Target[]) => {
          observer.next(data);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  public listAll(project: Project): Observable<Target[]> {
    return Observable.create((observer: Observer<Target[]>) => {
      this.fetchAll(project)
        .then((data: Target[]) => {
          observer.next(data);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  public async fetchNotificationCount(
    lat: number,
    long: number
  ): Promise<number> {
    const params = new HttpParams()
      .set('latitude', lat.toString())
      .set('longitude', long.toString());

    const response: { count: number } = await this.http
      .get<{ count: number }>(
        environment.apiUrlV3 + `/notifications/location/count`,
        {
          params,
        }
      )
      .toPromise();

    return response.count;
  }

  public async fetchGlobalTargets(): Promise<Target[]> {
    const response: Target[] = await this.http
      .get<Target[]>(environment.apiUrl + '/targets')
      .toPromise();

    const data = response['hydra:member'];

    return data;
  }

  public async fetchAll(project: Project): Promise<Target[]> {
    if (project.slug === '') {
      return this.fetchGlobalTargets();
    }
    const response: Target[] = await this.http
      .get<Target[]>(
        environment.apiUrl +
          `${ProjectService.base}/${project.slug}/targets/all`
      )
      .toPromise();

    this.storageService.store(this.createStorageKey(project), response);

    return response;
  }

  public async fetchGlobal(project: Project): Promise<Target[]> {
    const response: CollectionResponse<Target> = await this.http
      .get<CollectionResponse<Target>>(
        environment.apiUrl + `${TargetService.base}/global/${project.slug}`
      )
      .toPromise();

    const data = response['hydra:member'];
    this.storageService.store(this.createStorageKey(), data);

    return data;
  }

  public async fetchProject(project: Project): Promise<Target[]> {
    const response: CollectionResponse<Target> = await this.http
      .get<CollectionResponse<Target>>(
        environment.apiUrl + `${ProjectService.base}/${project.slug}/targets`
      )
      .toPromise();

    const data = response['hydra:member'];
    this.storageService.store(this.createStorageKey(project), data);

    return data;
  }

  /**
   * @param {string} slug
   * @param {Target} data
   * @returns {Promise<Target>}
   */
  public update(slug: string, data: Target): Promise<Target> {
    return this.http
      .put<Target>(environment.apiUrl + TargetService.base + '/' + slug, data)
      .toPromise()
      .then((response) => {
        this.updated$.next(null);
        return response;
      });
  }

  /**
   * @param {Target} target
   * @returns {Promise<Target>}
   */
  public create(target: Target): Promise<Target> {
    return this.http
      .post<Target>(environment.apiUrl + TargetService.base, {
        ...target,
        project: '/api' + ProjectService.base + '/' + target.project.slug,
      })
      .toPromise()
      .then((response) => {
        this.updated$.next(null);
        return response;
      });
  }

  /**
   * @param {Target} target
   * @returns {Promise<Target>}
   */
  public delete(target: Target): Promise<Target> {
    return this.http
      .delete<Target>(
        environment.apiUrl + `${TargetService.base}/${target.slug}`
      )
      .toPromise()
      .then((response) => {
        this.updated$.next(null);
        return response;
      });
  }

  /**
   * @param {Target} target
   * @param project
   * @returns {Promise<Target>}
   */
  public deleteGlobal(target: Target, project: Project): Promise<Target> {
    return this.http
      .delete<Target>(
        environment.apiUrl +
          `${TargetService.base}/global/${project.slug}/${target.slug}`
      )
      .toPromise()
      .then((response) => {
        this.updated$.next(null);
        return response;
      });
  }

  private createStorageKey(project: Project | null = null): string {
    return `${TargetService.storeKey}-${
      project != null ? project.slug : 'global'
    }`;
  }
}
