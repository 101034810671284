import { Injectable } from '@angular/core';
import { ProjectUser } from '../interfaces/project-user';
import { Customer } from '../interfaces/customer';
import { Project } from '../interfaces/project';
import { ProjectUserPermissions } from '../interfaces/project-user-permissions';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../interfaces/user';
import { Sort } from '../interfaces/sort';
import { CollectionResponse } from '../interfaces/collection-response';
import { Subscription } from 'rxjs';

@Injectable()
export class ProjectUserService {
  static base = '/project-users';
  private currentRequest: Subscription = null;

  constructor(private http: HttpClient) {}

  /**
   * @param {number} id
   * @returns {Promise<ProjectUser>}
   */
  public fetch(id: number): Promise<ProjectUser> {
    return this.http
      .get<ProjectUser>(environment.apiUrl + ProjectUserService.base + '/' + id)
      .toPromise();
  }

  /**
   * @param projectUser
   * @returns {Promise<ProjectUser>}
   */
  public update(projectUser: ProjectUser): Promise<ProjectUser> {
    return this.http
      .put<ProjectUser>(
        environment.apiUrl + ProjectUserService.base + '/' + projectUser.id,
        projectUser
      )
      .toPromise();
  }

  public fetchListForCustomer(
    customer: Customer,
    sort: Sort = null,
    search?: string,
    roles?: string[]
  ): Promise<ProjectUser[]> {
    return new Promise((resolve, reject) => {
      let params: HttpParams = new HttpParams();
      params.set('admins', true);

      if (this.currentRequest != null) {
        this.currentRequest.unsubscribe();
      }

      if (sort !== null) {
        params = params.set('order[' + sort.field + ']', sort.direction);
      }

      let urlEnd = '?';

      if (search != null && search !== '') {
        params = params.set('name', search);
      }

      if (roles != null && roles.length > 0) {
        for (const role of roles) {
          urlEnd += `role[]=${role}&`;
        }
      }

      this.currentRequest = this.http
        .get<ProjectUser[]>(
          environment.apiUrlV3 +
            '/customers/' +
            customer.id +
            ProjectUserService.base +
            urlEnd.slice(0, -1),
          { params }
        )
        .subscribe({
          next: (response) => resolve(response),
          error: (error) => reject(error),
        });
    });
  }

  public fetchListForProject(
    project: Project,
    sort: Sort = null,
    search?: string,
    roles?: string[]
  ): Promise<ProjectUser[]> {
    return new Promise((resolve, reject) => {
      let params: HttpParams = new HttpParams();

      if (this.currentRequest != null) {
        this.currentRequest.unsubscribe();
      }

      if (sort !== null) {
        params = params.set('order[' + sort.field + ']', sort.direction);
      }

      let urlEnd = '?pagination=false&';

      if (search != null && search !== '') {
        params = params.set('name', search);
      }

      if (roles != null && roles.length > 0) {
        for (const role of roles) {
          urlEnd += `role[]=${role}&`;
        }
      }

      this.currentRequest = this.http
        .get<ProjectUser[]>(
          environment.apiUrl +
            `/projects/${project.slug}${ProjectUserService.base}${urlEnd.slice(
              0,
              -1
            )}`,
          { params }
        )
        .subscribe({
          next: (response) => resolve(response),
          error: (error) => reject(error),
        });
    });
  }

  public fetchListForProjectUser(
    projectUser: ProjectUser
  ): Promise<ProjectUserPermissions[]> {
    return this.http
      .get<ProjectUserPermissions[]>(
        environment.apiUrl +
          `${ProjectUserService.base}/${projectUser.id}/projects?pagination=false`
      )
      .toPromise();
  }

  public check(email: string): Promise<User> {
    return this.http
      .post<User>(environment.apiUrl + ProjectUserService.base + '/check', {
        email,
      })
      .toPromise();
  }

  public create(projectUser: ProjectUser): Promise<ProjectUser> {
    return this.http
      .post<ProjectUser>(
        environment.apiUrl + ProjectUserService.base,
        projectUser
      )
      .toPromise();
  }
}
