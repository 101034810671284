import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Project } from '../interfaces/project';
import { environment } from 'src/environments/environment';
import { ProjectService } from './project.service';
import { firstValueFrom } from 'rxjs';
import { GeneralProjectInsights } from '../interfaces/general-project-insights';
import { Update } from '../interfaces/update';

@Injectable()
export class ProjectInsightService {
  static base = '/insights';

  constructor(private http: HttpClient) {}

  public async getGeneralInsights(
    project: Project,
  ): Promise<GeneralProjectInsights> {
    return firstValueFrom(
      this.http.get<GeneralProjectInsights>(
        environment.apiUrlV4 +
          ProjectService.base +
          '/' +
          project.slug +
          ProjectInsightService.base +
          '/general',
      ),
    );
  }

  public async getMapStatistics(project: Project, amountOfDays: number) {
    let params: HttpParams = new HttpParams();
    if (amountOfDays) {
      params = params.set('days', amountOfDays);
    }

    return firstValueFrom(
      this.http.get<any>(
        environment.apiUrlV4 +
          ProjectService.base +
          '/' +
          project.slug +
          ProjectInsightService.base +
          '/statistics-map',
        {
          params,
        },
      ),
    );
  }

  public async getMostEngagedUpdates(
    project: Project,
    amountOfDays: number,
  ): Promise<Update[]> {
    let params: HttpParams = new HttpParams();
    if (amountOfDays) {
      params = params.set('days', amountOfDays);
    }

    return firstValueFrom(
      this.http.get<Update[]>(
        environment.apiUrlV4 +
          ProjectService.base +
          '/' +
          project.slug +
          ProjectInsightService.base +
          '/statistics-updates',
        {
          params,
        },
      ),
    );
  }

  public async getUserStatistics(
    project: Project,
    amountOfDays: number,
  ): Promise<any> {
    let params: HttpParams = new HttpParams();
    if (amountOfDays) {
      params = params.set('days', amountOfDays);
    }

    return firstValueFrom(
      this.http.get<any>(
        environment.apiUrlV4 +
          ProjectService.base +
          '/' +
          project.slug +
          ProjectInsightService.base +
          '/statistics-new',
        {
          params,
        },
      ),
    );
  }
}