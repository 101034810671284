import { CommonModule } from '@angular/common';
import { Component, OnInit, viewChild, viewChildren } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ChartData, ChartDataset, ChartType } from 'chart.js';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ModalComponent } from 'src/app/components/modal.component';
import { StatisticsType } from 'src/app/enums/statistics-type';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { PeriodComponent } from 'src/app/components/period.component';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { StatisticComponent } from 'src/app/components/statistic.component';
import { UpdatesTileComponent } from '../updates/tile.component';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { Project } from 'src/app/interfaces/project';
import { RouterModule } from '@angular/router';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { Map } from 'mapbox-gl';
import * as mapboxgl from 'mapbox-gl';
import { Target } from 'src/app/interfaces/target';
import { ColorHelper } from 'src/app/shared/color-helper';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { Update } from 'src/app/interfaces/update';
import { LoaderComponent } from 'src/app/components/loader.component';
import { ProjectInsightService } from 'src/app/services/project-insight-service';
import { GeneralProjectInsights } from 'src/app/interfaces/general-project-insights';
import { CsvService } from 'src/app/services/csv.service';

@Component({
  selector: 'app-statistics',
  standalone: true,
  templateUrl: './statistics.component.html',
  imports: [
    TranslateModule,
    ModalComponent,
    InlineSVGModule,
    RouterModule,
    NgxMapboxGLModule,
    PeriodComponent,
    UpdatesTileComponent,
    StatisticComponent,
    CommonModule,
    ReactiveFormsModule,
    InlineSVGModule,
    BaseChartDirective,
    CarouselModule,
    LoaderComponent,
  ],
})
export class StatisticsComponent implements OnInit {
  public project: Project;
  public readonly modal = viewChild<ModalComponent>(ModalComponent);
  public readonly charts = viewChildren<BaseChartDirective>(BaseChartDirective);
  public modalTitle: string;
  public modalDesc: string;
  public updatesLoading: boolean = false;
  public activeUsersChart: any;
  public followersChart: any;
  public followersData: any;
  public reactionsChart: any;
  public engagementChart: any;
  public ratingChart: any;
  public interactionsChart: any;
  public periodControl: FormControl;
  public reactionsChartData: ChartData<'doughnut'>;
  public reactionLabels: string[];
  public interactionsChartData: ChartData<'bar'>;
  public targets: Target[];
  public updates: Update[];
  public labels: {};
  public StatisticsType = StatisticsType;
  public tip: number;
  public mapFilter: any[];
  public map: Map;
  public userLocations: any[];
  public generalInsights: GeneralProjectInsights;
  public defaultColors = ['#f5b049', '#3373ea', '#fc5447', '#50c878'];
  public reactionColors = [
    '#3373ea',
    '#f5b049',
    '#49da16',
    '#a800f2',
    '#fc5447',
  ];
  public interactionColors = {
    update_open: '#3373ea',
    update_view: '#f5b049',
    notification_clicked: '#50c878',
    poll_react: '#a800f2',
    update_react: '#fc5447',
  };

  public chartData;
  public showFollowerTargets: boolean = false;

  constructor(
    private translateService: TranslateService,
    private projectDataBusService: ProjectDataBusService,
    private formBuilder: FormBuilder,
    private csvService: CsvService,
    private projectInsightService: ProjectInsightService,
  ) {
    this.projectDataBusService.projectObservable.subscribe((project) => {
      this.project = project;
    });
  }

  ngOnInit() {
    this.tip = Math.ceil(Math.random() * 3);
    this.loadGeneralInsights();
    this.createControls();
  }

  private loadGeneralInsights() {
    this.projectInsightService
      .getGeneralInsights(this.project)
      .then((result) => (this.generalInsights = result));
  }

  toggleFollowerTargets() {
    this.showFollowerTargets = !this.showFollowerTargets;

    this.charts().forEach((baseChart: BaseChartDirective) => {
      const title = baseChart.chart.config.options.plugins?.title?.text;
      if (title === StatisticsType.FOLLOWERS) {
        if (this.showFollowerTargets) {
          baseChart.chart.data.datasets = [
            ...this.followersData.targets,
            this.followersData.totalFollowers,
          ];
        } else {
          baseChart.chart.data.datasets = [
            this.followersData.followersGained,
            this.followersData.totalFollowers,
          ];
        }
        baseChart.chart.update();
      }
    });
  }

  generateChartConfig(
    type: ChartType,
    data: ChartData,
    title: string,
    showLegend: boolean,
    stepSize: number = 5,
    min: number = 0,
    max?: number,
    stacked?: boolean,
  ): any {
    return {
      config: {
        options: {
          responsive: true,
          maintainAspectRatio: type === 'doughnut',
          plugins: {
            tooltip: {
              backgroundColor: '#ffffff',
              titleColor: '#212121',
              bodyColor: '#212121',
              bodyFont: { weight: 'normal', family: 'Mukta' },
              titleFont: { weight: 'bold', family: 'Mukta' },
              cornerRadius: 5,
              borderColor: '#e9e9e9',
              borderWidth: 1,
              padding: 8,
              boxPadding: 8,
            },
            legend: {
              display: showLegend,
              position: 'bottom',
              align: 'start',
              labels: {
                generateLabels: (chart) => {
                  const data = new Set(
                    chart.data.datasets.map((ds) =>
                      ds.stack ===
                        this.translateService.instant(
                          'project.detail.statistics.new.label.followersGained',
                        ) && this.showFollowerTargets
                        ? this.translateService.instant(
                            'project.detail.statistics.new.label.followersGained',
                          )
                        : ds.label,
                    ),
                  );

                  return [...data].map((groupName, index) => {
                    const firstMatchingDataset = chart.data.datasets.find(
                      (ds) => ds.label === groupName || ds.stack === groupName,
                    );

                    return {
                      text: groupName,
                      fillStyle: chart.data.datasets[index]
                        .backgroundColor as string,
                      hidden: firstMatchingDataset
                        ? !chart.isDatasetVisible(
                            chart.data.datasets.indexOf(firstMatchingDataset),
                          )
                        : false,
                      datasetIndex: index,
                    };
                  });
                },
                font: {
                  family: 'Mukta',
                  size: 14,
                  weight: 600,
                },
                color: '#212121',
                boxWidth: 20,
                boxHeight: 20,
                padding: 20,
                borderRadius: 5,
                usePointStyle: true,
                pointStyleWidth: 20,
              },
              onClick: (e, legendItem, legend) => {
                const chart = legend.chart;
                const groupName = legendItem.text;
                chart.data.datasets.forEach((dataset, i) => {
                  if (
                    dataset.stack === groupName ||
                    dataset.label === groupName
                  ) {
                    const isVisible = chart.isDatasetVisible(i);
                    chart.setDatasetVisibility(i, !isVisible);
                  }
                });

                chart.update();
              },
              onHover: (event) => {
                const target = event.native.target as HTMLElement;
                if (target) target.style.cursor = 'pointer';
              },
              onLeave: (event) => {
                const target = event.native.target as HTMLElement;
                if (target) target.style.cursor = 'auto';
              },
            },
            title: {
              display: false,
              text: title,
            },
          },
          scales: {
            x: {
              display: type === 'doughnut' ? false : true,
              stacked: stacked ? true : false,
              ticks: {
                autoSkip: true,
                autoSkipPadding: type === 'line' ? 10 : 0,
              },
              border: {
                width: 0,
              },
              grid: {
                display: false,
              },
            },
            y: {
              afterDataLimits: (axis) => {
                if (min === 0) {
                  return;
                }
                //fix for not cutting off line and points on min and max
                let value = 2.5;
                if (stepSize === 1) value = 0.25;
                axis.max += value;
                axis.min -= value;
              },
              display: type === 'doughnut' ? false : true,
              stacked: stacked ? true : false,
              beginAtZero: true,
              ticks: {
                stepSize: stepSize,
              },
              max: max,
              min: min,
              border: {
                width: 0,
              },
              grid: {
                display: false,
              },
            },
            y1: {
              beginAtZero: true,
              display: title === StatisticsType.FOLLOWERS ? true : false,
              ticks: {
                stepSize: 5,
              },
              position: 'right',
              min: 0,
              afterDataLimits: (axis) => {
                axis.max += 4;
                axis.min -= 4;
              },
              border: {
                width: 0,
              },
              grid: {
                display: false,
              },
            },
          },
        },
        type: type,
        data: data,
      },
    };
  }

  createControls() {
    this.periodControl = this.formBuilder.control({
      type: 'week',
      start: moment().subtract(7, 'days').format(),
      end: moment().format(),
    });

    this.periodControl.valueChanges.subscribe(async (value) => {
      if (this.periodControl.valid) {
        let amountOfDays: number;
        if (this.periodControl.value.type === 'week') {
          amountOfDays = 7;
        } else if (this.periodControl.value.type === 'month') {
          amountOfDays = 30;
        }

        this.updatesLoading = true;
        this.projectInsightService
          .getMostEngagedUpdates(this.project, amountOfDays)
          .then((updates) => {
            this.updatesLoading = false;
            this.updates = updates;
          });

        this.chartData = await this.projectInsightService.getUserStatistics(
          this.project,
          amountOfDays,
        );

        this.initCharts();

        this.userLocations = await this.projectInsightService.getMapStatistics(
          this.project,
          amountOfDays,
        );

        this.updateMapLayers();
      }
    });
  }

  mapLoaded(event: any) {
    this.map = event.target;

    const marker = new mapboxgl.Marker({ color: '#F5B049' })
      .setLngLat([this.project.locationLong, this.project.locationLat])
      .addTo(this.map);
  }

  updateMapLayers() {
    const filter = this.mapFilter
      ?.filter((filter) => filter.isChecked)
      .map((item) => item.label);
    const features = this.userLocations
      .filter((item) => filter.includes(item.metric))
      .map((location) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [location.longitude, location.latitude],
        },
        properties: {
          intensity: 1,
        },
      })) as any;

    if (this.map.getLayer('heatmap-layer'))
      this.map.removeLayer('heatmap-layer');
    if (this.map.getSource('users-location'))
      this.map.removeSource('users-location');

    this.map.addSource('users-location', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: features,
      },
    });

    this.map.addLayer({
      id: 'heatmap-layer',
      type: 'heatmap',
      source: 'users-location',
      paint: {
        'heatmap-weight': [
          'interpolate',
          ['linear'],
          ['get', 'intensity'],
          0,
          0.2,
          5,
          2,
        ],
        'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 2, 9, 5],
        'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 10, 9, 20],
        'heatmap-opacity': 0.7,
      },
    });

    if (features.length) {
      const bounds = new mapboxgl.LngLatBounds();
      features.forEach((feature) => {
        bounds.extend(feature.geometry.coordinates);
      });

      this.map.fitBounds(bounds, { padding: 25, animate: false, maxZoom: 10 });
    } else {
      this.map.setCenter([4.9041, 52.3676]).setZoom(3);
    }
  }

  async initCharts() {
    this.activeUsersChart = this.generateChartConfig(
      'bar',
      this.loadActiveUsersData(),
      StatisticsType.ACTIVE_USERS,
      true,
      null,
      null,
      null,
      true,
    );

    this.followersChart = this.generateChartConfig(
      'bar',
      this.loadFollowersData(),
      StatisticsType.FOLLOWERS,
      true,
      1,
      null,
      null,
      true,
    );

    this.reactionsChart = this.generateChartConfig(
      'doughnut',
      this.loadReactionsData(),
      StatisticsType.REACTIONS,
      false,
    );

    const engagementData = this.loadEngagementData();
    this.engagementChart = this.generateChartConfig(
      'line',
      engagementData,
      StatisticsType.ENGAGEMENT,
      true,
      null,
      0,
      null,
    );

    const ratingData = this.loadRatingData();
    this.ratingChart = this.generateChartConfig(
      'line',
      ratingData,
      StatisticsType.RATING,
      true,
      1,
      0,
      5,
    );

    this.interactionsChart = this.generateChartConfig(
      'bar',
      this.loadInteractionsData(),
      StatisticsType.INTERACTIONS,
      true,
      null,
      0,
      null,
      true,
    );
  }

  updateMapFilter(event, index) {
    this.mapFilter[index].isChecked = event.target.checked;
    this.updateMapLayers();
  }

  loadInteractionsData(): ChartData {
    const data = this.chartData['interactions'];
    const labels = [];
    const interactions = {
      interactions: {},
    };

    type Entries<T> = {
      [K in keyof T]: [K, T[K]];
    }[keyof T][];

    let mapLabels = {};

    (Object.entries(data) as Entries<typeof data>).map(([key, value]) => {
      interactions['interactions'][key] = value;
      labels.push(key);

      if (Object.keys(mapLabels).length === 0) {
        mapLabels = value;
      }
    });

    this.mapFilter = Object.keys(mapLabels).map((label) => ({
      label: label,
      isChecked: true,
    }));

    return this.generateChartData(labels, interactions, ['bar']);
  }

  loadReactionsData(): ChartData {
    const data = { reactions: {} };
    const labels = [];

    for (const key in this.chartData['reactions']) {
      data['reactions'][key] = Number(this.chartData['reactions'][key]);
      labels.push(key);
    }

    this.reactionLabels = labels;

    return this.generateChartData(
      labels,
      data,
      ['doughnut'],
      this.reactionColors,
    );
  }

  loadRatingData(): ChartData {
    const data = {
      rating: {},
      average: {},
      top: {},
    };
    data['rating'] = this.chartData['ratings']['rating'];

    let labels = Object.keys(data['rating']);

    labels.forEach((label) => {
      data['average'][label] = this.chartData['ratings']['average'];
      data['top'][label] = this.chartData['ratings']['top'];
    });

    return this.generateChartData(labels, data, ['line', 'line', 'line']);
  }

  loadEngagementData(): ChartData {
    const data = {
      engagement: {},
      average: {},
      top: {},
    };
    data['engagement'] = this.chartData['engagement']['engagement'];

    let labels = Object.keys(data['engagement']);

    labels.forEach((label) => {
      data['average'][label] = this.chartData['engagement']['average'];
      data['top'][label] = this.chartData['engagement']['top'];
    });

    return this.generateChartData(labels, data, ['line', 'line', 'line']);
  }

  loadFollowersData(): ChartData {
    const data = this.chartData['followers'];
    const followers = {};
    const gained = {};
    const labels = [];

    type Entries<T> = {
      [K in keyof T]: [K, T[K]];
    }[keyof T][];

    (Object.entries(data) as Entries<typeof data>).map(([key, value]) => {
      followers[key] = value.total;
      gained[key] = value.gained;
      labels.push(key);
    });

    const targetsChartData = this.generateChartData(
      labels,
      {
        targets: this.chartData['targets'],
      },
      ['bar'],
    );

    const chartData = this.generateChartData(
      labels,
      {
        followersGained: gained,
        totalFollowers: followers,
      },
      ['bar', 'line'],
    );

    this.followersData = {
      followersGained: chartData.datasets[0],
      totalFollowers: chartData.datasets[1],
      targets: targetsChartData.datasets,
    };

    if (this.showFollowerTargets) {
      return {
        labels: chartData.labels,
        datasets: [
          ...this.followersData.targets,
          this.followersData.totalFollowers,
        ],
      };
    } else {
      return {
        labels: chartData.labels,
        datasets: [
          this.followersData.followersGained,
          this.followersData.totalFollowers,
        ],
      };
    }
  }

  loadActiveUsersData(): ChartData {
    const data = this.chartData['uniqueUsers'];
    const followersData = {};
    const notFollowersData = {};
    const unknownData = {};
    const labels = [];

    type Entries<T> = {
      [K in keyof T]: [K, T[K]];
    }[keyof T][];

    (Object.entries(data) as Entries<typeof data>).map(([key, value]) => {
      followersData[key] = value.starredUsers;
      notFollowersData[key] = value.nonStarredUsers;
      unknownData[key] = value.unknownUsers;
      labels.push(key);
    });

    return this.generateChartData(
      labels,
      {
        followers: followersData,
        notFollowers: notFollowersData,
        unknown: unknownData,
      },
      ['bar', 'bar', 'bar'],
    );
  }

  generateChartData(
    labels: string[],
    data: any,
    types: ChartType[],
    colors?: string[],
  ): ChartData {
    let dataSets: ChartDataset[] = [];

    if (!colors) {
      colors = ColorHelper.generateColorStream(
        this.defaultColors,
        6,
        Object.keys(data).length,
      );
    }

    let index = 0;
    for (const key in data) {
      if (
        key === StatisticsType.INTERACTIONS ||
        key === StatisticsType.TARGETS
      ) {
        const _data = data[key];
        dataSets = Object.keys(_data[Object.keys(_data)[0]]).map(
          (_key, index) => {
            let _colors;
            if (key === 'interactions') {
              _colors = this.interactionColors;
            } else {
              _colors = ColorHelper.generateColorStream(
                this.defaultColors,
                6,
                Object.keys(_data[Object.keys(_data)[0]]).length,
              );
            }
            return {
              label:
                key === StatisticsType.TARGETS
                  ? _key
                  : this.translateService.instant(
                      'project.detail.statistics.new.label.' + _key,
                    ),
              stack:
                key === StatisticsType.TARGETS
                  ? this.translateService.instant(
                      'project.detail.statistics.new.label.followersGained',
                    )
                  : null,
              data: Object.keys(_data).map((date) => +_data[date][_key]),
              minBarLength: 3,
              backgroundColor:
                key === StatisticsType.TARGETS
                  ? colors[index]
                  : this.interactionColors[_key],
            };
          },
        );
      } else {
        dataSets.push({
          type: types[index],
          data: Object.values(data[key]),
          label: this.translateService.instant(
            'project.detail.statistics.new.label.' + key,
          ),
          fill: false,
          borderDash: key === 'top' || key === 'average' ? [5, 5] : [],
          borderWidth: types[index] === 'bar' ? 0 : 3,
          backgroundColor:
            types[index] != 'doughnut'
              ? colors[index]
              : (context) => {
                  return colors[context.dataIndex];
                },
          borderColor:
            types[index] != 'doughnut'
              ? colors[index]
              : (context) => {
                  return colors[context.dataIndex];
                },
          tension: 0.2,
          pointRadius: key === 'top' || key === 'average' ? 0 : 2,
          order: types[index] === 'line' ? 0 : 1,
          minBarLength: 3,
          yAxisID: key === 'totalFollowers' ? 'y1' : 'y',
        });
      }
      if (types[index] === 'line') {
        dataSets[index].stack = 'Stack ' + index;
      } else if (types[index] === 'doughnut') {
        labels = labels.map(
          (label) =>
            (label = this.translateService.instant(
              'project.detail.statistics.new.label.' + label,
            )),
        );
      }
      index++;
    }

    return {
      labels: labels,
      datasets: dataSets,
    };
  }

  openModal(key: string) {
    this.modalTitle = this.translateService.instant(
      `project.detail.statistics.new.stats.${key}`,
    );
    this.modalDesc = this.translateService.instant(
      `project.detail.statistics.new.stats.${key}.desc`,
    );
    this.modal().open();
  }

  isEmpty(data) {
    return data.datasets.every((ds) =>
      ds.data?.every((value) => Number(value) === 0),
    );
  }

  handleUpdateClick(event) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  export() {
    window.open('/assets/pdf/DEMO-Project-Insights-2.pdf', '_blank');
  }

  /**
   * Export the given chart to a CSV file.
   *
   * @param chartName
   * @param filename
   */
  exportCsv(chartName: string, filename: string) {
    if (!this[chartName]) {
      return;
    }

    const chartData = this[chartName].config.data;
    const labels = chartData.labels;
    const datasets = chartData.datasets;
    const chartType = this[chartName].config.type;

    if (chartType === 'doughnut') {
      const csvData = [];

      datasets[0].data.forEach((value, index) => {
        csvData.push({
          label: this.translateService.instant(
            `project.detail.statistics.new.label.${this.reactionLabels[index]}`,
          ),
          value: value,
        });
      });

      this.csvService.create(csvData, null, true).download(`${filename}.csv`);
      return;
    }

    const csvData = [];

    datasets.forEach((dataset) => {
      const row = { label: dataset.label };

      dataset.data.forEach((value, index) => {
        row[labels[index]] = value;
      });

      csvData.push(row);
    });

    this.csvService.create(csvData, null, true).download(`${filename}.csv`);
  }
}
