import { Component, ViewChild } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { Module } from '../../../../../enums/module';
import { AccessControlList } from '../../../../../interfaces/access-control-list';
import { AccessService } from '../../../../../services/access.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-smart-reporting-default',
  templateUrl: './default.component.html',
  standalone: true,
  imports: [NgIf, RouterLink, RouterOutlet, TranslateModule],
})
export class SmartReportingDefaultComponent {
  public currentTab = 'notices';
  public Module = Module;
  public hasThemeOption = false;
  public hasSettingsOption = false;
  public accessList: AccessControlList;
  public projectList: AccessControlList;

  constructor(
    private router: Router,
    private accessService: AccessService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const matches = event.url.match(/^\/smart-reporting\/([a-z0-9-]+)/i);

        if (matches) {
          this.currentTab = matches[1];
        }
      }
    });

    this.accessService.accessControlList.subscribe((list) => {
      this.accessList = list;

      this.checkPermissions();
    });
  }

  private checkPermissions() {
    if (this.accessList) {
      let customers = this.accessList.customers.map((item) => {
        return { customer: item.customer, permission: item.permissionType };
      });
      this.hasThemeOption = customers.some(
        (customer) =>
          customer.permission !== 'view' &&
          customer.customer.enabledModules &&
          customer.customer.enabledModules.includes(Module.SMART_REPORTING),
      );
      this.hasSettingsOption = this.hasThemeOption;
    }
  }
}
