<div class="bouwapp-header">
  <div class="bouwapp-header__text">
    <h1>{{ 'smart_reporting.notice_detail.title' | translate }}</h1>
  </div>
</div>
<ng-container>
  <app-loader *ngIf="!notice || !actualProject"></app-loader>

  <div class="smart-reporting__detail" *ngIf="notice && actualProject">
    <div class="bouwapp-box smart-reporting__box">
      <div class="bouwapp-box__title">
        <h2>{{ 'smart_reporting.notice_detail.info' | translate }}</h2>
      </div>
      <div class="bouwapp-box__self">
        <span>
          <div>
            {{ 'smart_reporting.notice_detail.info.status' | translate }}
          </div>

          <div
            appAccess
            role="manager"
            [project]="actualProject"
            property="readonly"
          >
            <select [formControl]="statusControl">
              <option [value]="SmartReportingNoticeStatus.OPEN">
                {{ 'smart_reporting.notices.table.status.open' | translate }}
              </option>
              <option [value]="SmartReportingNoticeStatus.IN_PROGRESS">
                {{
                  'smart_reporting.notices.table.status.in_progress' | translate
                }}
              </option>
              <option [value]="SmartReportingNoticeStatus.CLOSED">
                {{ 'smart_reporting.notices.table.status.closed' | translate }}
              </option>
              <option [value]="SmartReportingNoticeStatus.DELETED">
                {{ 'smart_reporting.notices.table.status.deleted' | translate }}
              </option>
            </select>
          </div>
        </span>
        <div *ngIf="statusError" class="alert alert-danger">
          {{ 'smart_reporting.notice_detail.status.error' | translate }}
        </div>

        <span>
          <div>
            {{ 'smart_reporting.notice_detail.info.project' | translate }}
          </div>
          <a routerLink="/projects/{{ project.slug }}">
            {{ project?.name }}
          </a>
        </span>

        <span>
          <div>
            {{ 'smart_reporting.notice_detail.info.description' | translate }}
          </div>
          <div [innerHtml]="notice.description"></div>
        </span>

        <span *ngIf="notice.status === SmartReportingNoticeStatus.DELETED">
          <div>
            {{
              'smart_reporting.notice_detail.info.removal_reason' | translate
            }}
          </div>
          <div>
            {{ notice.removalReason }}
          </div>
        </span>

        <span *ngIf="notice.status !== SmartReportingNoticeStatus.DELETED">
        </span>

        <span>
          <div>
            {{ 'smart_reporting.notice_detail.info.created_at' | translate }}
          </div>
          <div>
            {{ notice.createdAt | date: 'd MMM y' }}
          </div>
        </span>

        <span>
          <div>
            {{ 'smart_reporting.notice_detail.info.theme' | translate }}
          </div>
          <div>
            {{ notice.theme.title }}
          </div>
        </span>

        <span>
          <div>
            {{ 'smart_reporting.notice_detail.info.response_time' | translate }}
          </div>
          <div>
            {{
              'smart_reporting.notice_detail.info.response_time.day' +
                (notice.theme.responseTimeDays !== 1 ? 's' : '')
                | translate: { days: notice.theme.responseTimeDays }
            }}
          </div>
        </span>

        <span>
          <div>
            {{ 'smart_reporting.notice_detail.info.name' | translate }}
          </div>
          <div>
            {{ notice.name }}
          </div>
        </span>

        <span>
          <div>
            {{ 'smart_reporting.notice_detail.info.phone_number' | translate }}
          </div>
          <div>
            {{ notice.phoneNumber }}
          </div>
        </span>

        <span>
          <div>
            {{ 'smart_reporting.notice_detail.info.subscriptions' | translate }}
          </div>
          <div>
            {{ notice.amountOfSubscribers }}
          </div>
        </span>

        <span>
          <div>
            {{ 'smart_reporting.notice_detail.info.email' | translate }}
          </div>
          <div>
            <a [attr.href]="'mailto:' + notice.email">{{ notice.email }}</a>
          </div>
        </span>

        <span *ngIf="notice.attachment">
          <div>
            {{ 'smart_reporting.notice_detail.info.attachment' | translate }}
          </div>
          <div (click)="viewPhotoModal.open(getImage(notice))">
            <a>{{ notice.attachment }}</a>
          </div>
        </span>

        <span> </span>

        <!-- TODO: add -->
        <!-- <div
                  *ngIf="notice.feedbackSolution > 0"
                  class="smart-reporting__notice-detail__property smart-reporting__notice-detail__property__separator"
                >
                  <div class="smart-reporting__notice-detail__label">
                    {{
                      'smart_reporting.notice_detail.info.feedbackSolution' | translate
                    }}
                  </div>
                  <div
                    [inlineSVG]="
                      '/assets/img/icons/smiley' + notice.feedbackSolution + '.svg'
                    "
                    class="inline-svg__item smart-reporting__notice-detail__feedback"
                  ></div>
                </div>

                <div
                  *ngIf="notice.feedbackProcess > 0"
                  class="smart-reporting__notice-detail__property"
                >
                  <div class="smart-reporting__notice-detail__label">
                    {{
                      'smart_reporting.notice_detail.info.feedbackProcess' | translate
                    }}
                  </div>
                  <div
                    [inlineSVG]="
                      '/assets/img/icons/smiley' + notice.feedbackProcess + '.svg'
                    "
                    class="inline-svg__item smart-reporting__notice-detail__feedback"
                  ></div>
                </div>
         -->
        <div class="smart-reporting__map">
          <mgl-map
            #map
            *ngIf="mapReady"
            class="map"
            [style]="'mapbox://styles/mapbox/streets-v11'"
            [zoom]="14"
            [maxZoom]="20"
            [center]="[notice.locationLong, notice.locationLat]"
            (mapLoad)="initMap()"
          >
            <mgl-control mglNavigation [showCompass]="false"></mgl-control>
          </mgl-map>
        </div>
      </div>
    </div>

    <div class="bouwapp-box smart-reporting__box">
      <div class="bouwapp-box__title">
        <h2>{{ 'smart_reporting.notice_detail.responses' | translate }}</h2>
      </div>

      <div class="responses">
        <div class="messages">
          <div
            *ngFor="let response of responses"
            class="message"
            [class.support]="response.projectUser != null"
            [class.device]="response.projectUser == null"
            [class.animate]="true"
          >
            <div class="message__head">
              <span class="name" *ngIf="!response.projectUser">
                {{ notice.name }}
              </span>
              <span class="name" *ngIf="response.projectUser">
                {{ response.projectUser?.firstName }}
                {{ response.projectUser?.lastName }}
              </span>
            </div>

            <div class="message__content" [innerHTML]="response.message"></div>

            <div class="message__date">
              {{ response.createdAt | date: 'd MMMM yyyy - HH:mm' }}
            </div>
          </div>
          <div class="closed" [hidden]="notice.status != 'closed'">
            {{ 'smart_reporting.notice_detail.responses.closed' | translate }}
            <div class="feedback">
              <span
                class="awaiting-feedback"
                [hidden]="
                  notice.feedbackSolution !== 0 && notice.feedbackProcess !== 0
                "
                >{{ 'conversation.requested_feedback' | translate }}</span
              >

              <div class="given-feedback" *ngIf="notice.feedbackSolution > 0">
                <span class="given-feedback-label">{{
                  'smart_reporting.notice_detail.info.feedbackSolution'
                    | translate
                }}</span>
                <div
                  class="given-feedback-result"
                  [inlineSVG]="
                    '/assets/img/icons/smiley' +
                    notice.feedbackSolution +
                    '.svg'
                  "
                ></div>
              </div>
              <div class="given-feedback" *ngIf="notice.feedbackProcess > 0">
                <span class="given-feedback-label">{{
                  'smart_reporting.notice_detail.info.feedbackProcess'
                    | translate
                }}</span>
                <div
                  class="given-feedback-result"
                  [inlineSVG]="
                    '/assets/img/icons/smiley' + notice.feedbackProcess + '.svg'
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div class="bottom" *ngIf="!isClosed">
          <form
            appFormChangeDetector
            class="form"
            [formGroup]="form"
            (ngSubmit)="submit()"
            appAccess
            role="manager"
            [project]="project"
          >
            <app-form-group [form]="form" errorPath="message" [asText]="false">
              <div class="bottom__toolbar">
                <div id="quill-toolbar" class="bottom__toolbar__markup">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                  <button class="ql-link"></button>
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                </div>
              </div>

              <div class="bottom__compose">
                <span
                  *ngIf="notice.status == 'closed'"
                  [innerHTML]="'conversation.closed' | translate"
                ></span>
                <div>
                  <quill-editor
                    [placeholder]="'conversation.type_message' | translate"
                    formControlName="message"
                    [modules]="{
                      clipboard: { matchVisual: false },
                      toolbar: { container: '#quill-toolbar' },
                    }"
                  ></quill-editor>
                </div>

                <button class="send">Send</button>
              </div>
            </app-form-group>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<app-modal
  #removalReasonModal
  [title]="'smart_reporting.notice_detail.removal_reason.title' | translate"
  (onClose)="closeRemoval()"
  class="smart-reporting__themes__modal"
>
  <p>
    {{ 'smart_reporting.notice_detail.removal_reason.description' | translate }}
  </p>

  <form
    appFormChangeDetector
    [formGroup]="removalForm"
    (ngSubmit)="submitRemoval()"
  >
    <app-form-group errorPath="removalReason" [form]="removalForm">
      <label>{{
        'smart_reporting.notice_detail.removal_reason.reason' | translate
      }}</label>
      <input type="text" formControlName="removalReason" />
    </app-form-group>

    <div class="form-group buttons">
      <button type="submit" class="btn btn-primary" (click)="setSubmitted()">
        {{ 'smart_reporting.notice_detail.removal_reason.submit' | translate }}
      </button>
    </div>
  </form>
</app-modal>

<app-modal #viewPhotoModal [image]="true"></app-modal>
