<app-success-message [onScreen]="true" [hidden]="!formSaved">
  {{ 'profile.personal.success' | translate }}
</app-success-message>

<div class="container">
  <div class="bouwapp-header">
    <div class="bouwapp-header__text">
      <h1>{{ 'profile.personal.edit' | translate }}</h1>
    </div>
    <div class="bouwapp-header__buttons">
      <button
        form="personalForm"
        type="submit"
        class="primary"
        [appLoading]="loading || uploading"
      >
        {{ 'profile.personal.save' | translate }}
      </button>
    </div>
  </div>

  <app-loader *ngIf="!projectUser"></app-loader>

  <form
    *ngIf="projectUser"
    id="personalForm"
    appFormChangeDetector
    [formGroup]="form"
    class="bouwapp-group"
    (submit)="saveGeneral()"
  >
    <div class="bouwapp-group__header">
      <h2>{{ 'profile.personal.information' | translate }}</h2>
    </div>
    <div class="bouwapp-group__row two">
      <div class="bouwapp-group__item">
        <app-form-group [form]="form" errorPath="avatar">
          <label>{{ 'profile.personal.avatar' | translate }}</label>
          <div
            class="bouwapp-group__item__media"
            [style.backgroundImage]="
              avatarPreview ? 'url(' + avatarPreview + ')' : null
            "
          >
            <app-input-file-v2
              type="project-user-avatar"
              [selected]="avatarPreview"
              [standalone]="true"
              [uploadImages]="true"
              (uploadStart)="addAvatar($event)"
              (uploadFinished)="updateAvatar($event)"
              (uploadFailed)="uploadError($event)"
              (fileRemoved)="removeAvatar($event)"
            ></app-input-file-v2>
          </div>
          <div class="error" *ngIf="errorMessage">
            <span>{{ errorMessage }}</span>
          </div>
        </app-form-group>
      </div>
    </div>

    <div class="bouwapp-group__row three">
      <div class="bouwapp-group__item">
        <app-form-group [form]="form" errorPath="firstName">
          <label for="firstName">{{
            'profile.personal.first_name' | translate
          }}</label>
          <input type="text" id="firstName" formControlName="firstName" />
        </app-form-group>
      </div>
      <div class="bouwapp-group__item">
        <app-form-group [form]="form" errorPath="lastName">
          <label for="lastName">{{
            'profile.personal.last_name' | translate
          }}</label>
          <input type="text" id="lastName" formControlName="lastName" />
        </app-form-group>
      </div>
      <div class="bouwapp-group__item">
        <app-form-group [form]="form" errorPath="phoneNumber">
          <label for="phoneNumber">
            {{ 'profile.personal.phone' | translate }}
            <a (click)="redirectToTwoFactor()">{{
              'profile.personal.phone.change' | translate
            }}</a>
          </label>
          <angular-intl-phone
            formControlName="phoneNumber"
            [config]="phoneConfig"
          ></angular-intl-phone>
        </app-form-group>
      </div>
    </div>

    <div class="bouwapp-group__row three">
      <div class="bouwapp-group__item">
        <app-form-group>
          <label for="email">{{ 'profile.personal.email' | translate }}</label>
          <input type="text" id="email" formControlName="email" />
        </app-form-group>
      </div>
      <div class="bouwapp-group__item">
        <app-two-factor-method-switcher
          [standalone]="false"
          [projectUser]="projectUser"
          (methodChange)="updateMethodValue($event)"
        ></app-two-factor-method-switcher>
      </div>
      <div class="bouwapp-group__item"></div>
    </div>
  </form>
</div>
