import { Injectable } from '@angular/core';
import { Project } from '../interfaces/project';
import { CollectionResponse } from '../interfaces/collection-response';
import { DocumentCategory } from '../interfaces/document-category';
import { ProjectService } from './project.service';
import { LocalStorageService } from 'ngx-webstorage';
import {
  Observable,
  Observer,
  BehaviorSubject,
  lastValueFrom,
  Subscription,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ProjectDocument } from '../interfaces/project-document';

@Injectable()
export class DocumentCategoryService {
  static base = '/document-categories';
  static storeKey = 'document-categories';
  public updated$ = new BehaviorSubject<void>(null);
  private documentCategoryRequest: Subscription;

  constructor(
    private http: HttpClient,
    private storageService: LocalStorageService,
  ) {}

  public fetch(id: number): Promise<DocumentCategory> {
    return this.http
      .get<DocumentCategory>(
        environment.apiUrl + DocumentCategoryService.base + '/' + id,
      )
      .toPromise();
  }

  public fetchListForProject(project: Project): Observable<DocumentCategory[]> {
    return Observable.create((observer: Observer<DocumentCategory[]>) => {
      const stored = this.storageService.retrieve(
        this.createStorageKey(project),
      );
      if (stored != null) {
        observer.next(stored);
      }
      this.fetchForProject(project)
        .then((data: DocumentCategory[]) => {
          observer.next(data);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  public delete(documentCategory: DocumentCategory): Promise<DocumentCategory> {
    return this.http
      .delete(
        environment.apiUrl +
          `${DocumentCategoryService.base}/${documentCategory.id}`,
      )
      .toPromise()
      .then((response) => {
        return response as Promise<DocumentCategory>;
      });
  }

  public update(data: DocumentCategory): Promise<DocumentCategory> {
    return this.http
      .put(
        environment.apiUrl + DocumentCategoryService.base + '/' + data.id,
        data,
      )
      .toPromise()
      .then((response) => {
        this.updated$.next(null);
        return response as Promise<DocumentCategory>;
      });
  }

  public updateBatch(data: DocumentCategory[]): Promise<DocumentCategory[]> {
    return new Promise((resolve, reject) => {
      this.documentCategoryRequest?.unsubscribe();

      this.documentCategoryRequest = this.http
        .put<
          DocumentCategory[]
        >(environment.apiUrl + DocumentCategoryService.base + '/batch', data)
        .subscribe({
          next: (response) => resolve(response),
          error: (error) => reject(error),
        });
    });
  }

  public deleteBatch(data: DocumentCategory[]): Promise<void> {
    return lastValueFrom(
      this.http.delete<void>(
        environment.apiUrl + DocumentCategoryService.base + '/batch',
        { body: data },
      ),
    );
  }

  public createBatch(data: DocumentCategory[]): Promise<DocumentCategory[]> {
    return lastValueFrom(
      this.http.post<DocumentCategory[]>(
        environment.apiUrl + DocumentCategoryService.base + '/batch',
        data,
      ),
    );
  }

  public updateProjectDocumentSortOrder(
    documentCategory: DocumentCategory,
    data,
  ): Promise<ProjectDocument[]> {
    return this.http
      .post(
        environment.apiUrl +
          DocumentCategoryService.base +
          '/' +
          documentCategory.id +
          '/documents/sort',
        data,
      )
      .toPromise()
      .then((response) => {
        return response as Promise<ProjectDocument[]>;
      });
  }

  public create(
    project: Project,
    data: DocumentCategory,
  ): Promise<DocumentCategory> {
    return this.http
      .post(environment.apiUrl + DocumentCategoryService.base, {
        ...data,
        project: '/api' + ProjectService.base + '/' + project.slug,
      })
      .toPromise()
      .then((response) => {
        return response as Promise<DocumentCategory>;
      });
  }

  private async fetchForProject(project: Project) {
    const response: CollectionResponse<DocumentCategory> = await this.http
      .get<
        CollectionResponse<DocumentCategory>
      >(environment.apiUrl + `/projects/${project.slug}/document-categories`)
      .toPromise();

    const data = response['hydra:member'];
    this.storageService.store(this.createStorageKey(project), data);

    return data;
  }

  private createStorageKey(project: Project): string {
    return `${DocumentCategoryService.storeKey}-${project.slug}`;
  }
}
