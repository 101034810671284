<div class="bouwapp-header">
  <div class="bouwapp-header__text">
    <h1>{{ 'projects.detail.dashboard.title' | translate }}</h1>
  </div>
  <div *ngIf="project" class="bouwapp-header__buttons">
    <button
      class="tertiary"
      appAccess
      role="viewer"
      (click)="exportModal.open()"
    >
      {{ 'projects.detail.dashboard.export' | translate }}
    </button>
    <button
      [routerLink]="['updates', 'new']"
      class="secondary"
      appAccess
      role="manager"
      [project]="project"
    >
      <span class="icon" [inlineSVG]="'/assets/icons/add--white.svg'"> </span>
      <span>{{ 'projects.detail.update.list.create' | translate }}</span>
    </button>
    <a
      *ngIf="
        project &&
        (project.status == ProjectStatus.PUBLISHED ||
          project.status === ProjectStatus.PRIVATE)
      "
      (click)="qrCodeModal.open()"
      class="primary"
      appAccess
      role="manager"
      [project]="project"
    >
      <span>{{ 'profile.export.download' | translate }} QR code</span>
    </a>
  </div>
</div>

<section class="charts">
  <app-loader [text]="false" *ngIf="loading"></app-loader>
  <ng-container *ngIf="!loading">
    <div class="chart" *ngFor="let chart of charts; let i = index">
      <h3>
        {{ chart.config.data.datasets[0].label }}
        <span
          (click)="openModal(i)"
          inlineSVG="/assets/img/icons/info.svg"
        ></span>
      </h3>
      <div class="chart__inner">
        <canvas
          baseChart
          [type]="chart.config.type"
          [options]="chart.config.options"
          [data]="chart.config.data"
        ></canvas>
      </div>
    </div>
  </ng-container>
</section>

<app-loader [text]="false" *ngIf="!loading && sectionsLoaded < 3"></app-loader>
<div class="split-sections" *ngIf="sectionsLoaded >= 3">
  <section class="updates" *ngIf="updates">
    <h3>
      {{ 'projects.detail.dashboard.updates.title' | translate }}
      <a [routerLink]="['updates']">{{
        'projects.detail.dashboard.show-all' | translate
      }}</a>
    </h3>
    <div class="updates__list">
      <div class="update__box empty" *ngIf="!updates.length">
        {{ 'projects.detail.dashboard.updates.empty' | translate }}
      </div>
      <app-default-updates-tile
        [isCustomer]="false"
        *ngFor="let update of updates | slice: 0 : 3"
        [update]="update"
      >
      </app-default-updates-tile>
    </div>
  </section>
  <section class="communication">
    <div *ngIf="tickets">
      <h3>
        {{ 'projects.detail.dashboard.tickets.title' | translate }}
        <a [routerLink]="['tickets']">{{
          'projects.detail.dashboard.show-all' | translate
        }}</a>
      </h3>
      <div class="ticket empty" *ngIf="!tickets.length">
        {{ 'projects.detail.dashboard.tickets.empty' | translate }}
      </div>
      <div class="ticket" *ngFor="let ticket of tickets">
        <div>
          <span>{{ ticket.name }}</span>
          <span
            >{{ ticket.lastInteractionDate | date: 'mediumDate' }}
            <span>{{ ticket.lastInteractionDate | date: 'shortTime' }}</span>
          </span>
        </div>
        <div>
          <div
            [innerHTML]="
              (ticket.firstMessage.attachment ? '<span>📷 </span>' : '') +
              ticket.firstMessage.content.replaceAll('href=', '')
            "
          ></div>
          <a
            [routerLink]="['tickets']"
            [queryParams]="{ tab: 'overview' }"
            [state]="{ ticket: ticket }"
          >
            <span>{{ 'projects.detail.dashboard.view' | translate }}</span>
            <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
          </a>
        </div>
      </div>
    </div>
    <div *ngIf="surveyResponses">
      <h3>
        {{ 'projects.detail.dashboard.surveys.title' | translate }}
        <a *ngIf="surveysEnabled" [routerLink]="['feedback']">{{
          'projects.detail.dashboard.show-all' | translate
        }}</a>
      </h3>
      <div
        class="survey empty"
        *ngIf="project.activeSurvey && !surveyResponses.length"
      >
        {{ 'projects.detail.dashboard.surveys.empty' | translate }}
      </div>
      <div
        class="survey empty"
        *ngIf="!project.activeSurvey && !surveyResponses.length"
      >
        <ng-container *ngIf="surveysEnabled">
          {{ 'projects.detail.dashboard.surveys.inactive' | translate }}
          (<a [routerLink]="['feedback', 'new']">{{
            'projects.detail.dashboard.surveys.create' | translate
          }}</a
          >).
        </ng-container>
        <ng-container *ngIf="!surveysEnabled">
          {{ 'projects.detail.dashboard.surveys.disabled' | translate }}
        </ng-container>
      </div>
      <div class="survey" *ngFor="let response of surveyResponses">
        <div>
          <span>{{ response.survey.title }}</span>
          <span
            >{{ response.createdAt | date: 'mediumDate' }}
            <span>{{ response.createdAt | date: 'shortTime' }}</span>
          </span>
        </div>
        <div>
          <div [innerHTML]="response.survey.description"></div>
          <a
            [routerLink]="['feedback', response.survey.id, 'edit']"
            [queryParams]="{ tab: 'submissions' }"
          >
            <span>{{ 'projects.detail.dashboard.view' | translate }}</span>
            <span inlineSVG="/assets/img/icons/chevron-right.svg"></span>
          </a>
        </div>
      </div>
    </div>
  </section>
</div>

<app-modal
  #exportModal
  [title]="'projects.detail.dashboard.export' | translate"
  class="export-data"
>
  <div
    class="modal-description"
    [innerHTML]="'projects.detail.dashboard.export.description' | translate"
  ></div>
  <form [formGroup]="form" *ngIf="project">
    <app-form-group [form]="form" errorPath="from">
      <label>{{ 'profile.export.period.from' | translate }}</label>
      <app-date-picker-v2
        formControlName="from"
        [dateOnly]="true"
      ></app-date-picker-v2>
    </app-form-group>
    <app-form-group [form]="form" errorPath="till">
      <label>{{ 'profile.export.period.till' | translate }}</label>
      <app-date-picker-v2
        formControlName="till"
        [dateOnly]="true"
      ></app-date-picker-v2>
    </app-form-group>
    <button
      (click)="downloadExport()"
      [disabled]="form.invalid"
      class="button button--m button--flat bg--orange"
    >
      {{ 'projects.detail.dashboard.export.download' | translate }}
    </button>
  </form>
</app-modal>
<app-modal #chartModal [title]="currentChartTitle">
  <div class="modal-description" [innerHTML]="currentChartDescription"></div>
</app-modal>

<app-qr-modal
  #qrCodeModal
  [link]="getProjectLink()"
  [title]="'projects.detail.dashboard.qr-code.title'"
>
</app-qr-modal>
