import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Event } from 'src/app/interfaces/event';
import { map } from 'rxjs/operators';

@Injectable()
export class AiTextService {
  static base = '/ai';

  constructor(private http: HttpClient) {}

  public async improveText(text: string, language: string) {
    const params = new HttpParams().set('lang', language);
    return this.http
      .post<string>(
        environment.apiUrlV3 + AiTextService.base + '/improve-text',
        { text },
        { params },
      )
      .toPromise();
  }

  public async addEventFromText(
    text: string,
    language: string,
  ): Promise<Event[]> {
    const params = new HttpParams().set('lang', language);
    return this.http
      .post<{ events: any[] }>(
        environment.apiUrlV3 + AiTextService.base + '/generate-event',
        { text },
        { params },
      )
      .pipe(
        map((response) => {
          return response.events.map((event) => ({
            ...event,
          }));
        }),
      )
      .toPromise();
  }

  public async improveGrammar(text: string, language: string) {
    const params = new HttpParams().set('lang', language);
    return this.http
      .post<string>(
        environment.apiUrlV3 + AiTextService.base + '/improve-grammar',
        { text },
        { params },
      )
      .toPromise();
  }

  public async rewriteLanguageLevel(
    text: string,
    languageLevel: string,
    language: string,
  ) {
    const params = new HttpParams()
      .set('level', languageLevel)
      .set('lang', language);
    return this.http
      .post<string>(
        environment.apiUrlV3 + AiTextService.base + '/rewrite-language-level',
        { text },
        { params },
      )
      .toPromise();
  }

  public async generateTitle(text: string, language: string) {
    const params = new HttpParams().set('lang', language);
    return this.http
      .post<string>(
        environment.apiUrlV3 + AiTextService.base + '/generate-title',
        { text },
        { params },
      )
      .toPromise();
  }
}
