import { Component, OnInit, viewChild, ViewChild } from '@angular/core';
import { UserDataService } from '../../../services/user-data.service';
import { User } from '../../../interfaces/user';
import { ProjectService } from '../../../services/project.service';
import { Project } from '../../../interfaces/project';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Router, RouterLink } from '@angular/router';
import { ProjectDataBusService } from '../../../services/project-data-bus.service';
import { CustomerPickerComponent } from '../../../components/customer-picker.component';
import { Customer } from '../../../interfaces/customer';
import { SecurityVoter } from '../../../security/security-voter';
import { AccessService } from '../../../services/access.service';
import { ProjectStatus } from '../../../enums/project-status';
import { Sort } from '../../../interfaces/sort';
import { UpdatePickerComponent } from 'src/app/components/update-helpers/update-picker.component';
import { DateTypePipe } from '../../../pipes/date-type.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { FormGroupComponent } from '../../../components/form-group.component';
import { ModalComponent } from '../../../components/modal.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { LoaderComponent } from '../../../components/loader.component';
import { AccessDirective } from '../../../directives/access.directive';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter, take } from 'rxjs';
import { CustomFilterItem } from 'src/app/interfaces/custom-filter-item';
import { CustomFilter } from 'src/app/interfaces/custom-filter';
import { CustomFilterType } from 'src/app/enums/custom-filter-type';
import { DataFilterComponent } from 'src/app/components/data-filter.component';

@Component({
  selector: 'app-default-projects-list',
  templateUrl: 'list.component.html',
  standalone: true,
  imports: [
    NgIf,
    AccessDirective,
    UpdatePickerComponent,
    NgFor,
    LoaderComponent,
    InfiniteScrollDirective,
    NgClass,
    InlineSVGModule,
    DataFilterComponent,
    ModalComponent,
    FormGroupComponent,
    CustomerPickerComponent,
    DatePipe,
    TranslateModule,
    DateTypePipe,
    RouterLink,
  ],
})
export class ListComponent implements OnInit {
  public readonly updatePicker = viewChild<UpdatePickerComponent>(
    UpdatePickerComponent,
  );
  public readonly successModal = viewChild<ModalComponent>('successModal');
  public user: User;
  public projectStatus = ProjectStatus;
  public project: Project;

  totalItems: number;

  private perPage = 10;

  private page = 1;
  private ready = false;

  public sortItems: Sort[] = [
    {
      field: 'updatedAt',
      direction: 'desc',
      label: 'project.list.sort.direction.updated.descending',
    },
    {
      field: 'updatedAt',
      direction: 'asc',
      label: 'project.list.sort.direction.updated.ascending',
    },
    {
      field: 'name',
      direction: 'asc',
      label: 'project.list.sort.direction.ascending',
    },
    {
      field: 'name',
      direction: 'desc',
      label: 'project.list.sort.direction.descending',
    },
    {
      field: 'popular',
      direction: 'desc',
      label: 'project.list.sort.direction.popular.descending',
    },
    {
      field: 'popular',
      direction: 'asc',
      label: 'project.list.sort.direction.popular.ascending',
    },
  ];

  private sort: Sort = this.sortItems[0];

  private readonly defaultStatuses = [
    ProjectStatus.PUBLISHED,
    ProjectStatus.PRIVATE,
    ProjectStatus.UNPUBLISHED,
  ];

  private search = null;
  private statuses = null;
  public statusCount;
  public loading: boolean = true;

  public projects: Project[];
  public customers: Customer[] = [];
  public customerIds: number[] | null;

  /**
   * Full array of all projects with minimal data, no pagination
   */
  public projectsShortList: Project[];
  public projectShortListForUpdates: Project[];

  @ViewChild(CustomerPickerComponent, { static: true })
  private customerPicker: CustomerPickerComponent;

  transformToFilters(): CustomFilter[] {
    const filters: CustomFilter[] = [];

    if (this.allStatuses.length > 0) {
      const statusFilters: CustomFilterItem[] = this.allStatuses.map(
        (status, index) => ({
          label: `project.list.filter.status.${status}`,
          value: this.defaultStatuses.includes(status),
          key: status,
          count: this.statusCount ? this.statusCount[status] : undefined,
        }),
      );

      filters.push({
        title: 'project.list.filter.status.title',
        filters: statusFilters,
        type: CustomFilterType.CHECKBOX,
      });
    }

    if (this.customers.length > 0) {
      const customerFilters: CustomFilterItem[] = this.customers.map(
        (customer, index) => ({
          label: customer.name,
          value: true,
          key: customer.id.toString(),
        }),
      );

      filters.push({
        title: 'project.list.filter.customer.title',
        filters: customerFilters,
        type: CustomFilterType.CHECKBOX,
      });
    }

    return filters;
  }

  constructor(
    deviceDetectorService: DeviceDetectorService,
    private accessService: AccessService,
    private userDataService: UserDataService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private projectService: ProjectService,
    private projectDataBusService: ProjectDataBusService,
  ) {
    if (deviceDetectorService.isDesktop()) {
      this.perPage *= 2;
    }
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.project =
        this.router.getCurrentNavigation()?.extras.state['project'];
    }
  }

  async ngOnInit(): Promise<void> {
    if (this.project) {
      this.successModal().open();
    }
    this.user = await this.userDataService.retrieveUser();
    this.projectDataBusService.shortListObservable.subscribe((shortList) => {
      this.projectsShortList = shortList;
    });

    this.projectDataBusService.shortListObservable
      .pipe(
        filter((n) => n !== null),
        take(1),
      )
      .subscribe(() => this.subscribeFilterUpdateProjectList());
  }

  public async getStatusCount() {
    this.statusCount = await this.projectService.getStatusCount(
      this.search,
      this.customerIds,
    );
  }

  private async subscribeFilterUpdateProjectList(): Promise<void> {
    this.accessService.accessControlList.subscribe((list) => {
      if (list == null) {
        return;
      }

      this.customers = this.accessService.getCustomersFromACL(list);
      this.ready = true;
      this.loadProjects();
      this.getStatusCount();

      if (this.projectsShortList !== null) {
        this.projectShortListForUpdates = this.projectsShortList.filter(
          (item) =>
            SecurityVoter.canEditProject(list, item) === true &&
            item.status !== ProjectStatus.ARCHIVED,
        );
      }
    });
  }

  openModal() {
    this.updatePicker().pick();
  }

  updateFilter(data: any) {
    this.search = data.search;
    if (data.sort) {
      this.sort = data.sort;
    }

    this.statuses =
      data.customFilters
        .find(
          (f) =>
            f.title === 'project.list.filter.status.title' &&
            f.type === CustomFilterType.CHECKBOX,
        )
        ?.filters.filter((filter) => filter.value)
        .map((filter) => filter.key) || [];

    this.customerIds =
      data.customFilters
        .find(
          (f) =>
            f.title === 'project.list.filter.customer.title' &&
            f.type === CustomFilterType.CHECKBOX,
        )
        ?.filters.filter((filter) => filter.value)
        .map((filter) => parseInt(filter.key)) || [];

    if (!this.ready) {
      return;
    }

    this.reset();
    this.loadProjects();
    this.getStatusCount();
  }

  getCoverImageByProject(project: Project): SafeStyle | string {
    if (project.coverImage == null) {
      return '';
    } else {
      return this.sanitizer.bypassSecurityTrustStyle(
        'url(' + project.coverImageThumbnails?.medium + ')',
      );
    }
  }

  openProject(project: Project) {
    this.router.navigate(['/projects', project.slug]);
  }

  async createNewProject(): Promise<void> {
    const customer: Customer = await this.customerPicker.pick();
    if (undefined === customer) {
      return;
    }
    this.router.navigateByUrl('projects?customerId=' + customer.id);
  }

  getStatusClass(project: Project): string {
    switch (project.status) {
      case ProjectStatus.PUBLISHED:
        return 'green';
      case ProjectStatus.UNPUBLISHED:
        return 'dark';
      case ProjectStatus.ARCHIVED:
        return 'grey';
      case ProjectStatus.PRIVATE:
        return 'blue';
    }

    return null;
  }

  async loadProjects(more: boolean = false): Promise<void> {
    this.loading = true;
    if (!this.projects || this.projects.length < this.totalItems) {
      const currentParams = this.currentParamHash;
      const response: any = await this.projectService.list(
        this.page,
        this.perPage,
        this.sort,
        this.search,
        this.statuses,
        this.customerIds,
      );
      const result: Project[] = response['hydra:member'];
      if (result != null && currentParams === this.currentParamHash) {
        this.page += 1;

        if (!this.projects) {
          this.projects = [];
          this.totalItems = response['hydra:totalItems'];
        }

        this.projects = this.projects.concat(result);
      }
    }

    this.loading = false;
  }

  private reset() {
    this.page = 1;
    this.totalItems = undefined;
    this.projects = undefined;
  }

  get allStatuses(): ProjectStatus[] {
    return Object.values(ProjectStatus);
  }

  get currentParamHash() {
    return JSON.stringify([
      this.sort,
      this.search,
      this.statuses,
      this.customerIds,
    ]);
  }
}
