import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
  NgForm,
  AbstractControl,
} from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { InputFileComponent } from 'src/app/components/input-file.component';
import { ProjectStatus } from 'src/app/enums/project-status';
import { Project } from 'src/app/interfaces/project';
import { SecurityVoter } from 'src/app/security/security-voter';
import { AccessService } from 'src/app/services/access.service';
import { ErrorService } from 'src/app/services/error.service';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { ProjectService } from 'src/app/services/project.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ImageCropboxComponent } from '../../../../components/image-cropbox.component';
import { LoadingDirective } from '../../../../directives/loading.directive';
import { NgIf } from '@angular/common';
import { QuillComponent } from '../../../../components/quill.component';
import { ToggleComponent } from '../../../../components/toggle.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { InputFileComponent as InputFileComponent_1 } from '../../../../components/input-file.component';
import { FormGroupComponent } from '../../../../components/form-group.component';
import { AccessDirective } from '../../../../directives/access.directive';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';
import {
  AngularIntlPhoneComponent,
  AngularIntlPhoneConfig,
  AngularIntlPhoneModule,
} from 'angular-intl-phone';
import { platform } from 'src/app/services/platform.service';
import { LocaleService } from 'src/app/services/locale.service';

@Component({
  selector: 'app-detail-general-contact',
  templateUrl: './detail-general-contact.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AccessDirective,
    FormGroupComponent,
    InputFileComponent_1,
    InlineSVGModule,
    ToggleComponent,
    QuillComponent,
    NgIf,
    LoadingDirective,
    FormChangeDetectorDirective,
    ImageCropboxComponent,
    TranslateModule,
    AngularIntlPhoneModule,
  ],
})
export class DetailGeneralContactComponent implements OnInit {
  @Output() formSaved = new EventEmitter();
  @Input() withSave: boolean;
  @Input() language: string;
  @ViewChild(InputFileComponent) chatPlaceholderAvatarInput: InputFileComponent;
  public contactLoading = false;
  public uploading = false;
  public ProjectStatus = ProjectStatus;
  public contactForm: FormGroup;
  public showEditor: boolean = false;
  public canEdit: boolean = false;
  public imageChangedEvent: any;
  public project: Project;
  public editImage: boolean = false;

  startUploading = new EventEmitter();
  stopUploading = new EventEmitter();
  private placeholderAvatar: string;
  private placeholderAvatarThumbnail: string;
  public platform = platform;

  @ViewChild(AngularIntlPhoneComponent, { static: false })
  phoneComponent: AngularIntlPhoneComponent;
  @ViewChild('formRef') formRef: NgForm;

  private oldCountryCode: string;

  phoneConfig: AngularIntlPhoneConfig = {
    id: 'phoneNumber',
    name: 'phoneNumber',
    options: {
      separateDialCode: true,
      localizedCountries: {},
      onlyCountries: ['au', 'cz', 'dk', 'de', 'gb', 'nl', 'sk', 'be'],
      preferredCountries: [],
    },
  };

  constructor(
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private projectService: ProjectService,
    private projectDataBusService: ProjectDataBusService,
    private accessService: AccessService,
    private localeService: LocaleService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.projectDataBusService.projectObservable.subscribe((project) => {
      if (false === this.contactForm.touched) {
        this.contactForm.patchValue(project);
        if (project?.chatPlaceholderPhoneNumber) {
          const phoneNumber = '+' + project.chatPlaceholderPhoneNumber;
          this.contactForm
            .get('chatPlaceholderPhoneNumber')
            .setValue(phoneNumber);
        }
      }
      this.project = project;

      if (this.project && this.project?.create !== true) {
        this.accessService.accessControlList.subscribe(
          (acl) =>
            (this.canEdit = SecurityVoter.canEditProject(acl, this.project)),
        );
      } else {
        this.accessService.accessControlList.subscribe(
          (acl) =>
            (this.canEdit =
              SecurityVoter.hasCreateRole(acl) ||
              SecurityVoter.hasCustomers(acl)),
        );
      }
    });

    if (this.localeService.localizedCountryNames) {
      this.phoneConfig.options.localizedCountries =
        this.localeService.localizedCountryNames;
    }

    this.translateService
      .get('two.factor.phone.placeholder')
      .subscribe((result) => {
        this.phoneConfig.placeholder = result;
      });
    this.phoneConfig.options.initialCountry =
      platform.language == 'nl' ? 'nl' : 'gb';
  }

  deleteImage() {
    this.placeholderAvatar = null;
    this.placeholderAvatarThumbnail = null;
    this.editImage = false;

    this.contactForm.get('chatPlaceholderAvatar').patchValue(null);
  }

  createForm() {
    this.contactForm = this.formBuilder.group({
      chatPlaceholderName: ['', this.withSave && Validators.required],
      chatPlaceholderFunction: [''],
      chatPlaceholderPhoneNumber: [''],
      chatPlaceholderAvatar: [null],
      chatDescription: [''],
      chatEnabled: [true as boolean],
    });

    this.contactForm
      .get('chatPlaceholderAvatar')
      .valueChanges.subscribe((value) => {
        this.placeholderAvatar = value;
      });

    this.contactForm
      .get('chatPlaceholderPhoneNumber')
      .valueChanges.subscribe((value: any) => {
        if (value === null) {
          return;
        }
        if (value.dialCodeWithPlus !== undefined) {
          if (value.dialCodeWithPlus !== this.oldCountryCode) {
            if (value.e164Number.startsWith(this.oldCountryCode)) {
              const cleanNumber = value.e164Number
                .substring(this.oldCountryCode.length)
                .trim();
              this.phoneComponent.writeValue(cleanNumber);
            }
          }
          this.oldCountryCode = value.dialCodeWithPlus;
        }
      });
  }

  public async saveContact() {
    this.formRef.ngSubmit.emit();
    this.errorService.markFormGroupTouchedAndDirty(this.contactForm);

    if (!this.contactForm.valid) {
      return;
    }

    this.contactLoading = true;
    this.projectDataBusService.emitIsLoading(true);
    this.project.chatEnabled = this.contactForm.get('chatEnabled').value;

    if (this.contactForm.value.chatPlaceholderPhoneNumber) {
      this.contactForm.value.chatPlaceholderPhoneNumber =
        this.contactForm.value.chatPlaceholderPhoneNumber.e164Number.replace(
          /\+/g,
          '',
        );
    }

    try {
      await this.projectService.updateTicketSettings(
        this.project,
        this.contactForm.value,
      );
      this.formSaved.emit();
    } catch (error) {
      this.errorService.parseErrorsToForm(this.contactForm, error.error);
    } finally {
      this.contactLoading = false;
      this.projectDataBusService.emitIsLoading(false);
    }
  }

  inputFileStartUploading() {
    this.startUploading.emit();
  }

  inputFileStopUploading(event) {
    this.stopUploading.emit();
  }

  updatePlaceholderAvatarThumbnail(thumbnail: string): void {
    this.placeholderAvatarThumbnail = thumbnail;
  }

  getCurrentPlaceholderAvatar(): SafeStyle | string {
    if (this.placeholderAvatar == null) {
      return '';
    } else {
      let url: string;

      if (this.placeholderAvatarThumbnail != null) {
        url = this.placeholderAvatarThumbnail;
      } else {
        url = this.project.chatPlaceholderAvatarThumbnails?.small;
      }
      return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
    }
  }

  edit() {
    this.editImage = true;
    this.startEditing();
  }

  startEditing(event?: Event) {
    this.showEditor = true;
    if (event) {
      this.editImage = false;
      this.imageChangedEvent = event;
    }
  }

  stopEditing() {
    this.showEditor = false;
  }
}
