import { Injectable } from '@angular/core';
import { Project } from '../interfaces/project';
import { RatingTheme } from '../interfaces/rating-theme';
import { ProjectService } from './project.service';
import { RatingThemeService } from './rating-theme.service';
import { Subscription } from 'rxjs';
import { Target } from '../interfaces/target';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class SatisfactionMonitorService {
  private resultRequest: Subscription;

  constructor(private http: HttpClient) {}

  public configure(project: Project, themes: RatingTheme[]): Promise<Project> {
    const all = [];

    for (const theme of themes) {
      all.push('/api' + RatingThemeService.base + '/' + theme.id);
    }

    return this.http
      .put<Project>(
        environment.apiUrl +
          ProjectService.base +
          '/' +
          project.slug +
          '/configure-monitor',
        { ratingThemes: all },
      )
      .toPromise();
  }

  public results(project: Project, period, filter = null): Promise<any> {
    if (this.resultRequest != null) {
      this.resultRequest.unsubscribe();
    }

    return new Promise(async (resolve, reject) => {
      let params = SatisfactionMonitorService.getParamsForPeriod(period); // we're using a string here because URLSearchParams doesn't encode + properly

      if (filter?.target) {
        for (const target of filter.target) {
          params += '&target[]=' + target;
        }
      }

      if (filter?.theme) {
        params += '&theme=' + filter.theme;
      }

      this.resultRequest = this.http
        .get<any>(
          environment.apiUrl +
            ProjectService.base +
            '/' +
            project.slug +
            '/rating-monitor' +
            params,
        )
        .subscribe(
          (value) => {
            resolve(value);
          },
          (error) => reject(error),
        );
    });
  }

  public comments(project: Project, themeId: number): Promise<any> {
    return this.http
      .get(
        environment.apiUrl +
          ProjectService.base +
          '/' +
          project.slug +
          '/rating-monitor/' +
          themeId +
          '/comments',
      )
      .toPromise();
  }

  public demography(project: Project, period): Promise<any> {
    const params = SatisfactionMonitorService.getParamsForPeriod(period); // we're using a string here because URLSearchParams doesn't encode + properly

    return this.http
      .get(
        environment.apiUrl +
          ProjectService.base +
          '/' +
          project.slug +
          '/rating-monitor/demography' +
          params,
      )
      .toPromise();
  }

  public sendPushNotification(
    project: Project,
    targets: Target[],
  ): Promise<Project> {
    const targetQueryString =
      '?' + targets.map((item) => 'target[]=' + item.id).join('&');

    return this.http
      .post<Project>(
        environment.apiUrl +
          ProjectService.base +
          '/' +
          project.slug +
          '/rating-monitor/push' +
          targetQueryString,
        {},
      )
      .toPromise();
  }

  public static getParamsForPeriod(period): string {
    let params = '?type=' + period.type; // we're using a string here because URLSearchParams doesn't encode + properly

    if (period.type !== 'total') {
      params += '&from=' + period.start.replace(/\+/g, '%2B');
      params += '&till=' + period.end.replace(/\+/g, '%2B');
    }

    return params;
  }
}
