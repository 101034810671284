<form [formGroup]="form" appFormChangeDetector (ngSubmit)="save()">
  <div class="bouwapp-header">
    <div class="bouwapp-header__text">
      <h1 *ngIf="edit">{{ 'project.detail.phases.edit' | translate }}</h1>
      <h1 *ngIf="!edit">{{ 'project.detail.phases.new' | translate }}</h1>
    </div>
    <div class="bouwapp-header__buttons">
      <button
        type="submit"
        class="primary"
        [appLoading]="loading || uploading"
        [disabled]="loading || uploading"
        appAccess
        role="manager"
      >
        <span
          *ngIf="!edit"
          class="icon"
          [inlineSVG]="'/assets/icons/add--white.svg'"
        ></span>
        <span>{{
          (edit ? 'project.detail.phases.save' : 'project.detail.phases.create')
            | translate
        }}</span>
      </button>
    </div>
  </div>

  <app-loader *ngIf="edit && !phase"></app-loader>

  <!-- New/Edit Phase -->
  <section class="new-phase projects-detail" [hidden]="edit && !phase">
    <div class="bouwapp-box full">
      <div class="bouwapp-box__title">
        <h2>{{ 'project.detail.phases.info' | translate }}</h2>
      </div>

      <div class="bouwapp-box__self">
        <app-form-group [form]="form" errorPath="name">
          <label for="name"
            >{{ 'project.detail.phases.edit.name' | translate
            }}<span class="required">*</span></label
          >
          <input
            type="text"
            class="input input--m"
            formControlName="name"
            id="name"
            maxlength="42"
          />
        </app-form-group>

        <app-form-group [form]="form" errorPath="text">
          <label
            >{{ 'project.detail.phases.edit.text' | translate
            }}<span class="required">*</span></label
          >
          <app-quill
            [formGroup]="form"
            [project]="project"
            [language]="language"
            controlName="text"
          ></app-quill>
        </app-form-group>

        <div class="form-split">
          <div>
            <div class="form-date">
              <p>{{ 'project.detail.phases.edit.start' | translate }}</p>
              <app-form-group [form]="form" errorPath="startDate">
                <app-date-picker-v2
                  formControlName="startDate"
                  [typeControl]="form.get('startType')"
                ></app-date-picker-v2>
              </app-form-group>
            </div>
          </div>
          <div>
            <div class="form-date">
              <p>{{ 'project.detail.phases.edit.end' | translate }}</p>
              <app-form-group [form]="form" errorPath="endDate">
                <app-date-picker-v2
                  formControlName="endDate"
                  [typeControl]="form.get('endType')"
                ></app-date-picker-v2>
              </app-form-group>
            </div>
          </div>
        </div>

        <div *ngIf="!dateRangeValid" class="form-date__notice combi">
          {{ 'project.detail.phases.edit.date.invalid' | translate }}
        </div>

        <div class="form-group multi-attachment">
          <label>{{ 'project.detail.phases.edit.document' | translate }}</label>
          <app-multi-attachment-control
            type="project-phase-document"
            formControlName="attachments"
            [id]="phase?.id"
            previewBasePath="/project-phase/document"
            [withVideo]="false"
            [withDescription]="false"
            (startUploading)="startUploading()"
            (stopUploading)="stopUploading()"
          ></app-multi-attachment-control>
        </div>
      </div>
    </div>
  </section>
</form>
